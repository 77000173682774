import { css } from '@emotion/react'
import styled from '@emotion/styled'
import Image from 'next/image'
import React, { memo } from 'react'

import ReactMD from '@livechat/react-md'

import { SparkleIcon } from '../../../icons'
import { extractTime } from 'lib/formatDate'
import { skeletonShimmer } from 'styles/animations'
import { Message } from 'types/assistants'

const Messages = styled.div`
	display: flex;
	flex-direction: column;
	gap: 1rem;
	padding-bottom: ${({ theme }) => theme.spaces.space6};
`

const MessageWrapper = styled.span`
	display: flex;
`

const MessageMeta = styled.span`
	${({ theme }) => theme.typography.captionSmall}
	color: ${({ theme }) => theme.colors.light.secondaryTextColor};
	align-self: center;
	padding-inline: ${({ theme }) => theme.spaces.space3};
	opacity: 0;
	transition: opacity 500ms ${({ theme }) => theme.transitions.easings.css.smooth};
`

const MessageRow = styled.div<{ type: 'user' | 'assistant' | 'summary' }>`
	display: flex;
	flex-direction: column;
	gap: ${({ theme }) => theme.spaces.space3};
	padding: ${({ theme }) => theme.spaces.space3};
	${({ type }) => {
		switch (type) {
			case 'user':
				return css`
					align-items: flex-end;
					${MessageWrapper} {
						flex-direction: row-reverse;
					}
				`
		}
	}}

	:hover {
		${MessageMeta} {
			opacity: 1;
		}
	}
`

const MessageAuthor = styled.span`
	${({ theme }) => theme.typography.captionSmall}
	color: ${({ theme }) => theme.colors.light.secondaryTextColor};
	display: flex;
	align-items: center;
	gap: ${({ theme }) => theme.spaces.space2};
`

const MessageContent = styled.div<{ type: 'user' | 'assistant' | 'summary' }>`
	${({ theme }) => theme.typography.caption}
	max-width: min(100%, 500px);
	width: fit-content;

	${({ theme }) => css`
		border: 1px solid ${theme.colors.light.borderColor};
		border-radius: ${theme.borderRadiuses.xl};
		padding: ${theme.spaces.space6};
		box-shadow: ${theme.boxShadows.sm};
	`}

	${({ type, theme }) => {
		switch (type) {
			case 'user':
				return css`
					background-color: ${theme.colors.light.surfaceVariant};
				`
			case 'assistant':
				return css`
					background-color: ${theme.colors.light.surface};
				`
			case 'summary':
				return css`
					background: linear-gradient(
						90deg,
						${theme.colors.light.subtleFeedback} 0%,
						${theme.colors.light.surfaceDecorative} 100%
					);
				`
		}
	}}
	pre {
		white-space: pre-wrap;
		word-wrap: break-word;
		word-break: break-word;
	}
`

const MessagesSkeleton = styled(Messages)`
	position: relative;
	overflow: hidden;
	${MessageContent} {
		::before {
			content: '';
			position: absolute;
			top: 0;
			width: 100%;
			height: 100%;
			z-index: 10;
			background: linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.5), transparent);
			animation: ${skeletonShimmer} 2000ms infinite;
		}
	}
`

const MessageContentSkeleton = styled.div`
	height: 32px;
	width: 350px;
`

function removeAnnotations(message: string): string {
	const annotationPattern = /【.+†.+】/g

	return message.replaceAll(annotationPattern, '')
}

type Props = {
	messages: Message[]
	summary: string
	botName: string
	botAvatar: string
}

export function MessagesListSkeleton() {
	return (
		<MessagesSkeleton>
			<MessageRow type="summary">
				<MessageAuthor>
					<SparkleIcon size={14} /> AI Summary
				</MessageAuthor>
				<MessageContent type="summary">
					<MessageContentSkeleton />
				</MessageContent>
			</MessageRow>
		</MessagesSkeleton>
	)
}

function MessagesList({ messages, summary, botAvatar, botName }: Props) {
	function maybeGetMessageTextContent(content: Message['content']): string | null {
		const textContent = content.find((item) => item.type === 'text')
		const textValue = textContent?.text.value
		if (!textValue) {
			return null
		}

		return removeAnnotations(textValue)
	}

	return (
		<Messages>
			<MessageRow data-testid="openai-history-thread-summary" type="summary">
				<MessageAuthor>
					<SparkleIcon size={14} /> AI Summary
				</MessageAuthor>
				<MessageContent type="summary">{summary}</MessageContent>
			</MessageRow>
			{messages.map((message) => {
				const text = maybeGetMessageTextContent(message.content)
				if (!text) {
					return null
				}
				const isUserMessage = message.role === 'user'
				const type = isUserMessage ? 'user' : 'assistant'

				return (
					<MessageRow key={message.id} data-testid="openai-history-message-item" type={type}>
						<MessageAuthor>
							{isUserMessage ? (
								'User'
							) : (
								<>
									<Image src={botAvatar} alt="" width={16} height={16} /> {botName}
								</>
							)}
						</MessageAuthor>
						<MessageWrapper>
							<MessageContent type={type}>
								<ReactMD template={text} />
							</MessageContent>
							<MessageMeta>{extractTime(message.createdAt * 1000)}</MessageMeta>
						</MessageWrapper>
					</MessageRow>
				)
			})}
		</Messages>
	)
}

export default memo(MessagesList)
