import createSvgIcon from './createSvgIcon'

const CaretLeft = createSvgIcon(
	<path d="M12.8538643,15.7750637 C13.2206159,16.1418153 13.2206159,16.7364371 12.8538643,17.1031887 C12.4871127,17.4699403 11.8924909,17.4699403 11.5257393,17.1031887 L5.27573928,10.8531887 C5.09921942,10.6772807 5,10.4383307 5,10.1891262 C5,9.93992166 5.09921942,9.70097168 5.27573928,9.52506367 L11.5257393,3.27506367 C11.8924909,2.9083121 12.4871127,2.90831211 12.8538643,3.27506369 C13.2206158,3.64181528 13.2206159,4.23643708 12.8538643,4.60318867 L7.26870803,10.1883449 L12.8538643,15.7750637 Z" />,
	{
		size: 20,
		viewBox: '0 0 20 20',
	},
)

export default CaretLeft
