import AccountsSDK from '@livechat/accounts-sdk'

import { fetcher } from './fetcher'
import { storage } from './storage'
import { config } from 'lib/config'

export const accountsSDK = new AccountsSDK({
	client_id: config.clientId,
	server_url: config.accountsURL,
})

export const getToken = () => storage.getItem('token') as string

export const removeToken = () => storage.removeItem('token')

export const setToken = (token: string) => storage.setItem('token', token)

export async function logout() {
	return fetcher(`${config.accountsURL}/v2/sessions`, {
		method: 'DELETE',
	}).then(() => {
		removeToken()
		window.location.replace(`${config.accountsURL}/logout`)
	})
}

export async function refreshTokenWithIframe() {
	const { access_token } = await accountsSDK.iframe().authorize()
	setToken(access_token)
}

let refreshTokenPromise: Promise<void> | null = null

export async function authFailover() {
	if (refreshTokenPromise === null) {
		refreshTokenPromise = refreshTokenWithIframe().catch(() => {
			removeToken()
			return accountsSDK.redirect().authorize()
		})
	}

	await refreshTokenPromise

	refreshTokenPromise = null
}
