import createSvgIcon from './createSvgIcon'

const Eye = createSvgIcon(
	<path d="M19.2990128,10.1820312 C19.2701065,10.11875 18.5857315,8.59921875 17.0740128,7.0875 C15.0513565,5.06796875 12.5021378,4 9.68963777,4 C6.87713777,4 4.32791902,5.06796875 2.30760652,7.0875 C0.795887768,8.59921875 0.111512768,10.11875 0.0802627685,10.1820312 C-0.0267542562,10.4246611 -0.0267542562,10.7011202 0.0802627685,10.94375 C0.109169018,11.0078125 0.793544018,12.5265625 2.30604402,14.0382812 C4.32791902,16.0578125 6.87713777,17.125 9.68963777,17.125 C12.5021378,17.125 15.0513565,16.0578125 17.0708878,14.0382813 C18.5833878,12.5265625 19.2677628,11.0078125 19.296669,10.94375 C19.4044331,10.7014496 19.4052838,10.4249902 19.2990128,10.1820312 Z M15.7005753,12.7601562 C14.0232315,14.4117188 12.0013565,15.25 9.68963777,15.25 C7.37791902,15.25 5.35604402,14.4117188 3.68104402,12.759375 C3.02193862,12.107209 2.45498182,11.3680887 1.99588777,10.5625 C2.4551144,9.75723499 3.02206155,9.0183901 3.68104402,8.36640625 C5.35682527,6.71328125 7.37791902,5.875 9.68963777,5.875 C12.0013565,5.875 14.0224503,6.71328125 15.6982315,8.36640625 C16.3572799,9.01833194 16.9242344,9.75718633 17.3833878,10.5625 C16.9242204,11.36804 16.3572709,12.1071508 15.6982315,12.759375 L15.7005753,12.7601562 Z M9.68963777,7.125 C7.79115894,7.125 6.25213777,8.66402117 6.25213777,10.5625 C6.25213777,12.4609788 7.79115894,14 9.68963777,14 C11.5881166,14 13.1271378,12.4609788 13.1271378,10.5625 C13.1249851,8.66491356 11.5872242,7.12715268 9.68963777,7.125 Z M9.68963777,12.125 C8.82669285,12.125 8.12713777,11.4254449 8.12713777,10.5625 C8.12713777,9.69955508 8.82669285,9 9.68963777,9 C10.5525827,9 11.2521378,9.69955508 11.2521378,10.5625 C11.2521378,11.4254449 10.5525827,12.125 9.68963777,12.125 Z" />,
	{
		size: 20,
		viewBox: '0 0 20 20',
	},
)

export default Eye
