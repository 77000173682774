import styled from '@emotion/styled'

const Form = styled.form`
	display: flex;
	flex-direction: column;
	gap: ${({ theme }) => theme.spaces.space6};
	width: 100%;
	padding-left: ${({ theme }) => theme.spaces.space1};
	padding-bottom: ${({ theme }) => theme.spaces.space7};
	overflow-x: hidden;
`

export default Form
