import createSvgIcon from './createSvgIcon'

const NavHelp = createSvgIcon(
	<g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
		<circle stroke="#FFFFFF" strokeWidth="2" cx="16" cy="16" r="9" />
		<line
			x1="16"
			y1="20"
			x2="16"
			y2="20.01"
			stroke="#FFFFFF"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M16,17.0000091 C16.925836,17.0027827 17.7325583,16.3697589 17.9500461,15.4698261 C18.1675338,14.5698932 17.7389278,13.6383263 16.914,13.218 C16.0948727,12.798393 15.0946183,12.9985268 14.5,13.701"
			stroke="#FFFFFF"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</g>,
	{
		size: 32,
		viewBox: '0 0 32 32',
	},
)

export default NavHelp
