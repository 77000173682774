import createSvgIcon from './createSvgIcon'

const ChatView = createSvgIcon(
	<>
		<defs>
			<linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="linearGradient-1">
				<stop stopColor="#999999" offset="0%"></stop>
				<stop stopColor="#F3F3F3" offset="100%"></stop>
			</linearGradient>
			<linearGradient x1="78.8111166%" y1="5.97088752%" x2="50%" y2="100%" id="linearGradient-2">
				<stop stopColor="#E6E6E6" offset="0%"></stop>
				<stop stopColor="#C3C3C3" offset="100%"></stop>
			</linearGradient>
			<linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="linearGradient-3">
				<stop stopColor="#FFFFFF" offset="0%"></stop>
				<stop stopColor="#C6C6C6" offset="100%"></stop>
			</linearGradient>
			<linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="linearGradient-4">
				<stop stopColor="#EEEEEE" offset="0%"></stop>
				<stop stopColor="#FFFFFF" offset="100%"></stop>
			</linearGradient>
			<linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="linearGradient-5">
				<stop stopColor="#F6F6F6" offset="0%"></stop>
				<stop stopColor="#FFFFFF" offset="100%"></stop>
			</linearGradient>
			<linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="linearGradient-6">
				<stop stopColor="#C1C1C1" offset="0%"></stop>
				<stop stopColor="#959595" offset="100%"></stop>
			</linearGradient>
		</defs>
		<g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
			<g>
				<rect fill="url(#linearGradient-1)" x="0" y="0" width="180" height="120"></rect>
				<path
					d="M56,16 L124,16 C132.836556,16 140,23.163444 140,32 L140,120 L140,120 L40,120 L40,32 C40,23.163444 47.163444,16 56,16 Z"
					fill="url(#linearGradient-2)"
				></path>
				<path
					d="M40,120 L40,32 C40,23.163444 47.163444,16 56,16 L124,16 C132.836556,16 140,23.163444 140,32 L140,120"
					stroke="url(#linearGradient-3)"
				></path>
				<path
					d="M55.5,16 L124.5,16 C133.336556,16 140.5,23.163444 140.5,32 L140.5,42 L140.5,42 L39.5,42 L39.5,32 C39.5,23.163444 46.663444,16 55.5,16 Z"
					fill="url(#linearGradient-4)"
				></path>
				<rect fill="url(#linearGradient-5)" x="54.5" y="51" width="56" height="19" rx="8"></rect>
				<rect fill="url(#linearGradient-6)" x="88.5" y="81" width="45" height="20" rx="8"></rect>
				<circle fill="#D6D3D3" cx="79.5" cy="28.5" r="7.5"></circle>
				<circle fill="#BCBCBC" cx="47.5" cy="55.5" r="3.5"></circle>
				<rect fill="#D6D3D3" x="90" y="26" width="21" height="6" rx="3"></rect>
			</g>
		</g>
	</>,
	{
		viewBox: '0 0 180 120',
	},
)

export default ChatView
