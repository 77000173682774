import createSvgIcon from './createSvgIcon'

const Tick = createSvgIcon(
	<path d="m6.586 9.828 4.95-4.95a1 1 0 0 1 1.414 1.415L7.293 11.95a1 1 0 0 1-1.414 0L3.05 9.12a1 1 0 0 1 1.414-1.414l2.122 2.121Z" />,
	{
		size: 16,
		viewBox: '0 0 16 16',
	},
)

export default Tick
