import styled from '@emotion/styled'

const FieldError = styled.span`
	position: absolute;
	bottom: -6px;
	color: ${({ theme }) => theme.colorPalette.red[500]};
	${({ theme }) => theme.typography.smallcaps};
`

export default FieldError
