import useSWR from 'swr'
import type { SWRConfiguration } from 'swr'

import { config } from 'lib/config'
import { store } from 'lib/store'
import type { OrganizationEntities } from 'types/organization'

export function usePublicOrganizationEntities(
	organizationId?: string,
	templateId?: string,
	options?: SWRConfiguration,
) {
	const { data, isLoading, error } = useSWR<OrganizationEntities>(
		organizationId
			? `${config.apiURL}/v1.0/organization/${organizationId}/public${
					organizationId !== templateId ? `?tid=${templateId}` : ''
			  }`
			: null,
		options,
	)

	if (error) {
		store.dispatch({
			type: 'toast/set',
			payload: { kind: 'error', timeout: 6000, message: 'Something went wrong' },
		})
	}

	return {
		organizationEntities: data,
		areOrganizationEntitiesLoading: isLoading,
	}
}
