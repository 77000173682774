import createSvgIcon from './createSvgIcon'

const PaperPlane = createSvgIcon(
	<path d="M3.71850967,2.30992656 C4.24529019,1.93682804 4.94249791,1.89403257 5.49634435,2.20445556 L21.1320471,10.5137673 C21.6689152,10.8148791 22,11.384143 22,12 C22,12.615857 21.6689152,13.1851209 21.1121356,13.4971054 L5.51569438,21.7849865 C4.89634404,22.131836 4.12809334,22.0535865 3.5905467,21.5899308 C3.05859884,21.1311042 2.86363027,20.390927 3.09769844,19.728451 L3.13887023,19.6292405 L6.798,12.002 L3.13887023,4.37075948 L3.09769844,4.27154898 C2.86363027,3.60907296 3.05859884,2.8688958 3.5905467,2.41006925 Z M5.372,4.403 L8.53296782,10.999859 L13,11 C13.5522847,11 14,11.4477153 14,12 C14,12.5522847 13.5522847,13 13,13 L8.53496782,12.999859 L5.372,19.596 L19.666,12 L5.372,4.403 Z" />,
	{
		size: 24,
		viewBox: '0 0 24 24',
	},
)

export default PaperPlane
