import createSvgIcon from './createSvgIcon'

const BackgroundTypeGradientPreview = createSvgIcon(
	<>
		<defs>
			<linearGradient id="gradientRightUnder:r1:" x1="246.352962%" y1="64.362556%" x2="9.31709157%" y2="64.362556%">
				<stop stopColor="rgb(255, 93, 0)" offset="0%" />
				<stop stopColor="var(--gradient-background-color, rgb(246, 246, 247))" stopOpacity="0" offset="100%" />
			</linearGradient>
			<linearGradient id="gradientRightBottom:r1:" x1="126.721639%" y1="135.04013%" x2="44.6680469%" y2="37.5317504%">
				<stop stopColor="rgb(255, 208, 0)" offset="0%" />
				<stop stopColor="var(--gradient-background-color, rgb(246, 246, 247))" stopOpacity="0" offset="100%" />
			</linearGradient>
			<linearGradient id="gradientRightTop:r1:" x1="96.8463587%" y1="19.5058296%" x2="75.2429971%" y2="39.2669309%">
				<stop stopColor="rgb(183, 0, 255)" offset="0%" />
				<stop stopColor="var(--gradient-background-color, rgb(246, 246, 247))" stopOpacity="0" offset="100%" />
			</linearGradient>
		</defs>
		<g fill="none" fillRule="evenodd">
			<circle fill="url(#gradientRightUnder:r1:)" cx="172.5" cy="172.5" r="172.5" />
			<circle fill="url(#gradientRightBottom:r1:)" cx="172.5" cy="172.5" r="172.5" />
			<circle fill="url(#gradientRightTop:r1:)" cx="172.5" cy="172.5" r="172.5" />
		</g>
	</>,
	{
		size: 360,
		viewBox: '0 0 360 360',
	},
)

export default BackgroundTypeGradientPreview
