import Modal from './Modal'
import { Modals, useAppSelector } from 'lib/store'

type Props = {
	modalName: Modals
	hideModal: () => void
	withoutBackdrop?: boolean
	children: React.ReactNode
}

const ModalManager = ({ modalName, hideModal, withoutBackdrop = false, children, ...restProps }: Props) => {
	const isModalActive = useAppSelector((store) => store.currentModal === modalName)
	const shouldShowModal = isModalActive

	if (!shouldShowModal) {
		return null
	}

	return (
		<Modal hideModal={hideModal} withoutBackdrop={withoutBackdrop} {...restProps}>
			{children}
		</Modal>
	)
}

export default ModalManager
