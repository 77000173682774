import createSvgIcon from './createSvgIcon'

const Swap = createSvgIcon(
	<g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
		<g transform="translate(2, 2)" stroke="#000000" strokeWidth="1.66666667">
			<path d="M0,0.833333333 C0,0.373096042 0.373096042,0 0.833333333,0 L4.16666667,0 C4.62690396,0 5,0.373096042 5,0.833333333 L5,4.16666667 C5,4.62690396 4.62690396,5 4.16666667,5 L0.833333333,5 C0.373096042,5 0,4.62690396 0,4.16666667 L0,0.833333333 Z"></path>
			<path d="M11,10.8333333 C11,10.373096 11.373096,10 11.8333333,10 L15.1666667,10 C15.626904,10 16,10.373096 16,10.8333333 L16,14.1666667 C16,14.626904 15.626904,15 15.1666667,15 L11.8333333,15 C11.373096,15 11,14.626904 11,14.1666667 L11,10.8333333 Z"></path>
			<path d="M14.9666667,7.16666667 L14.9666667,4.66666667 C14.9666667,3.74619208 14.2204746,3 13.3,3 L8.3,3 L10.8,5.5 M10.8,0.5 L8.3,3"></path>
			<path d="M1,8.83333333 L1,11.3333333 C1,12.2538079 1.74619208,13 2.66666667,13 L7.66666667,13 L5.16666667,10.5 M5.16666667,15.5 L7.66666667,13"></path>
		</g>
	</g>,
	{
		size: 20,
		viewBox: '0 0 20 20',
	},
)

export default Swap
