import createSvgIcon from './createSvgIcon'

const ArrowRight = createSvgIcon(
	<path d="M11.9571068,3.66789322 L17.5821068,9.29289322 C17.9726311,9.68341751 17.9726311,10.3165825 17.5821068,10.7071068 L11.9571068,16.3321068 C11.5665825,16.7226311 10.9334175,16.7226311 10.5428932,16.3321068 C10.1523689,15.9415825 10.1523689,15.3084175 10.5428932,14.9178932 L14.46,11 L3,11 C2.44771525,11 2,10.5522847 2,10 C2,9.44771525 2.44771525,9 3,9 L14.46,9 L10.5428932,5.08210678 C10.1824093,4.72162282 10.1546797,4.15439176 10.4597046,3.76210056 L10.5428932,3.66789322 C10.9334175,3.27736893 11.5665825,3.27736893 11.9571068,3.66789322 Z" />,
	{
		size: 20,
		viewBox: '0 0 20 20',
	},
)

export default ArrowRight
