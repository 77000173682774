import createSvgIcon from './createSvgIcon'

const Stack = createSvgIcon(
	<path d="M16.375,6.75 L3.875,6.75 C2.83946609,6.75 2,7.58946609 2,8.625 L2,19.125 C2,20.1605339 2.83946609,21 3.875,21 L16.375,21 C17.4105339,21 18.25,20.1605339 18.25,19.125 L18.25,8.625 C18.25,7.58946609 17.4105339,6.75 16.375,6.75 Z M16,18.75 L4,18.75 L4,9 L16,9 L16,18.75 Z M22,4.875 L22,16.5 C22,17.1213203 21.4963203,17.625 20.875,17.625 C20.2536797,17.625 19.75,17.1213203 19.75,16.5 L19.75,5.25 L5.5,5.25 C4.87867967,5.24999999 4.37500002,4.74632033 4.37500002,4.125 C4.37500002,3.50367967 4.87867967,3 5.5,3 L20.125,3 C20.6222809,3 21.0991945,3.19754408 21.4508252,3.54917479 C21.8024559,3.90080549 22,4.37771908 22,4.875 Z" />,
	{
		size: 24,
		viewBox: '0 0 24 24',
	},
)

export default Stack
