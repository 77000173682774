import createSvgIcon from './createSvgIcon'

const ChatBotLogo = createSvgIcon(
	<path d="M4.46820027,0.192001466 C8.03210171,-0.0639997759 11.9847144,-0.0640007414 15.5485883,0.192000086 C17.8175763,0.354983534 19.6773438,2.12612422 19.8631274,4.43899319 C20.0484973,6.74680009 20.0403598,10.1228001 19.8702995,12.4290897 C19.6835503,14.9623449 17.5260049,16.7553104 15.1167482,16.7553104 L12.517777,16.7535173 L7.49961846,20 L7.49961846,16.7500691 L4.87339344,16.748138 C2.46419187,16.748138 0.301704646,14.9623449 0.114906839,12.4290897 C-0.0551543028,10.1228001 -0.0316658426,6.74680009 0.153723209,4.43899319 C0.339516515,2.12612422 2.19923983,0.354984914 4.46820027,0.192001466 Z M15.3157725,3.43365526 C11.9068977,3.18878629 8.10991844,3.18878629 4.7010298,3.43365526 C3.98745231,3.48491043 3.44677637,4.03132422 3.39312392,4.69924147 C3.22104992,6.84121388 3.19815453,10.0503035 3.35593961,12.1900691 C3.4080611,12.8968415 4.02856746,13.5035311 4.8680282,13.5035311 L7.49961846,13.5035311 L7.49961846,16.7500691 L12.5177356,13.5035311 L15.117162,13.5035311 C15.9567055,13.5035311 16.5772256,12.8968415 16.6293609,12.1900691 C16.787146,10.0502897 16.7956973,6.84121388 16.623706,4.69924147 C16.5700536,4.03132422 16.0293914,3.48491043 15.3157725,3.43365526 Z M13.9809439,7.36511733 C14.3322364,7.21558629 14.7491807,7.47033112 14.7491807,7.85211043 L14.7491807,9.80991733 C14.7491807,10.0816415 14.6021537,10.3316966 14.3562352,10.4472966 L14.2480796,10.4973869 C13.4496702,10.8617503 11.7948934,11.5000001 9.99949128,11.5000001 C8.11858007,11.5000001 6.39203343,10.7995173 5.6427612,10.4472966 C5.39687026,10.3316966 5.24973292,10.0816415 5.24973292,9.80991733 L5.24973292,7.85211043 C5.24973292,7.47033112 5.66678756,7.21558629 6.01803866,7.36511733 L6.16542916,7.42669353 C7.04873348,7.78880511 8.47330535,8.25000009 9.99949128,8.25000009 C11.6137264,8.25000009 13.1142707,7.73405526 13.9809439,7.36511733 Z" />,
	{
		size: 20,
		viewBox: '0 0 20 20',
		color: '#0066FF',
	},
)

export default ChatBotLogo
