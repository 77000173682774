import styled from '@emotion/styled'

const Container = styled.div`
	width: 100%;
	height: 100vh;
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: center;

	h1 {
		${({ theme }) => theme.typography.heading}
	}
`

type Props = {
	children: React.ReactNode
}

function ErrorPagesLayout({ children }: Props) {
	return <Container>{children}</Container>
}

export default ErrorPagesLayout
