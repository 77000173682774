import { css, keyframes, useTheme as useAppTheme } from '@emotion/react'
import styled from '@emotion/styled'
import dynamic from 'next/dynamic'
import Link from 'next/link'
import { useRouter } from 'next/router'
import React, { createContext } from 'react'

import { noop } from '@livechat/data-utils'
import { promiseRetry } from '@livechat/promise-utils'

import AppFrame from './components/AppFrame'
import { Drawer, DrawerNav } from './components/Drawer'
import { Foreground } from './components/Foreground'
import HeadingBreadcrumbs from './components/HeadingBreadcrumbs'
import { Section } from './components/Section'
import { Content, DrawerBottom, MobileContent, Plane } from './components/SharedStyledComponents'
import SuccessStep from './components/SuccessStep'
import { WidgetPlane } from './components/WidgetPlane'
import { useAllowedRoutes } from './routes'
import BetaBadge from 'components/BetaBadge'
import { PortalOutlet, PortalProvider, createPortalContext } from 'components/Portal'
import { UserAvatarIcon } from 'components/icons'
import { useInstallPrompt } from 'hooks/useInstallPrompt'
import { useScrollTopOnce } from 'hooks/useScrollTopOnce'
import useTemplateId from 'hooks/useTemplateId'
import { useTemplates } from 'hooks/useTemplates'
import { useUser } from 'hooks/useUser'
import { LIVECHAT_BETA_BADGE_TOOLTIP_TEXT, OPENAI_BETA_BADGE_TOOLTIP_TEXT } from 'lib/constants'
import { useAppSelector } from 'lib/store'
import { Theme } from 'styles/theme'

const TemplateChooser = dynamic(() => promiseRetry(() => import('components/TemplateChooser')), {
	ssr: false,
})

const mobileNavLinkStyles = (theme: Theme, isActive: boolean) => css`
	width: 48px;
	height: 48px;
	display: flex;
	justify-content: center;
	align-items: center;
	color: ${isActive ? theme.colors.dark.primaryTextColor : 'inherit'};
	text-decoration: inherit;
	padding: ${theme.spaces.space2};
	border-radius: ${theme.borderRadiuses.xl};
	background-color: ${isActive ? theme.colors.dark.border : 'transparent'};
	position: relative;

	svg {
		z-index: ${theme.zIndexes.high};
	}
`

const ActionButons = styled.div`
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: ${({ theme }) => (theme.isMobile ? 'space-between' : 'flex-end')};
	gap: ${({ theme }) => theme.spaces.space3};
	padding-right: ${({ theme }) => theme.spaces.space6};
`

const DrawerTop = styled(DrawerNav)`
	border: none;
	visibility: ${({ expanded }: { expanded?: boolean }) => (expanded ? 'visible' : 'hidden')};
`

const StyledDrawerBottom = styled(DrawerBottom)`
	bottom: 0;
	position: fixed;
	background-color: #050505;
	color: ${({ theme }) => theme.colors.dark.secondaryTextColor};
`

const bubbleScale = keyframes`
	0% {
		scale: 1
	}
	100% {
		scale: 1.4
	}
`

const OuterBubble = styled.div`
	position: absolute;
	width: 30px;
	height: 30px;
	background-color: #eff0fd;
	border-radius: ${({ theme }) => theme.borderRadiuses.round};
	animation: ${bubbleScale} linear 1s infinite alternate 1s;
`

const InnerBubble = styled.div`
	position: absolute;
	width: 30px;
	height: 30px;
	background-color: #d3d5fc;
	border-radius: ${({ theme }) => theme.borderRadiuses.round};
	animation: ${bubbleScale} linear 1s infinite alternate;
`

const BubbleWrapper = styled.div`
	position: absolute;
	display: grid;
	background-color: ${({ theme }) => theme.colors.light.primaryBackgroundColor};
	place-items: center;

	border-radius: ${({ theme }) => theme.borderRadiuses.xl};
	z-index: ${({ theme }) => theme.zIndexes.above};
	${({ theme }) =>
		theme.isMobile
			? css`
					width: calc(100% + 2 * ${theme.spaces.space3});
					height: calc(100% + 2 * ${theme.spaces.space3});
			  `
			: css`
					width: 100%;
					height: calc(100% + 2 * ${theme.spaces.space5});
			  `}
`

const NotificationDot = styled.div`
	position: absolute;
	top: ${({ theme }) => (theme.isMobile ? theme.spaces.space2 : theme.spaces.space1)};
	right: ${({ theme }) => (theme.isMobile ? theme.spaces.space2 : theme.spaces.space1)};
	width: 10px;
	height: 10px;
	border-radius: ${({ theme }) => theme.borderRadiuses.round};
	background-color: ${({ theme }) => theme.colors.light.notification};
`

const Column = styled.div<{ hasTemplateChooser?: boolean; fullWidth?: boolean }>`
	height: 100%;
	display: flex;
	flex-direction: column;
	gap: ${({ theme }) => theme.spaces.space5};
	padding-bottom: ${({ hasTemplateChooser }) => (hasTemplateChooser ? '68px' : 0)};
	${({ fullWidth }) =>
		fullWidth &&
		css`
			width: 100%;
		`};
`

const MobileTemplateChooserWrapper = styled.div`
	padding-right: ${({ theme }) => theme.spaces.space6};
	margin-top: ${({ theme }) => theme.spaces.space3};
`

const BetaBadgeWrapper = styled.div`
	margin-right: auto;
`

const BETA_BADGE_TOOLTIP_TEXTS_PATHNAME_MAP = {
	'/configurator/widget-content/livechat': LIVECHAT_BETA_BADGE_TOOLTIP_TEXT,
	'/configurator/widget-content/openai-assistants': OPENAI_BETA_BADGE_TOOLTIP_TEXT,
}

const USER_ROUTE = '/configurator/user'

export const ActionButtonsPortalContext = createPortalContext()
export const HeadingControlPortalContext = createPortalContext()
export const DrawerContext = createContext<(value: boolean) => void>(noop)

function DefaultLayout({ leftColumn, rightColumn }: { leftColumn: React.ReactNode; rightColumn: React.ReactNode }) {
	const router = useRouter()
	const user = useUser()
	const appTheme = useAppTheme()
	const contentRef = React.useRef<HTMLElement>(null)
	const isSuccessStepActive = useAppSelector((state) => state.currentModal === 'SuccessStepModal')
	const { isVisible: isInstallPromptVisible } = useInstallPrompt()
	const { withTemplateId } = useTemplateId()
	const { templates } = useTemplates()
	const shouldDisplayTemplateChooser = typeof templates !== 'undefined' && templates.length > 1
	const [isDrawerExpanded, setIsDrawerExpanded] = React.useState(isSuccessStepActive ? false : true)
	const betaBadgeTooltipText =
		router.pathname in BETA_BADGE_TOOLTIP_TEXTS_PATHNAME_MAP &&
		BETA_BADGE_TOOLTIP_TEXTS_PATHNAME_MAP[router.pathname as keyof typeof BETA_BADGE_TOOLTIP_TEXTS_PATHNAME_MAP]
	const allowedRoutes = useAllowedRoutes()

	useScrollTopOnce(contentRef)

	if (appTheme.isMobile) {
		const shouldDisplayTemplateChooserOnMobile = shouldDisplayTemplateChooser && !router.route.endsWith('user')
		return (
			<DrawerContext.Provider value={setIsDrawerExpanded}>
				<Foreground onClick={() => setIsDrawerExpanded(false)}>
					<Section>
						<WidgetPlane pushed={isDrawerExpanded}>{rightColumn}</WidgetPlane>
					</Section>
				</Foreground>
				<PortalProvider context={ActionButtonsPortalContext}>
					<PortalProvider context={HeadingControlPortalContext}>
						<Drawer
							expanded={isDrawerExpanded}
							compact={router.route.startsWith(USER_ROUTE)}
							onClick={() => setIsDrawerExpanded(true)}
							css={css({
								backgroundColor: appTheme.colors.light.primaryBackgroundColor,
								paddingTop:
									isDrawerExpanded && !router.route.includes('code-snippet') && !router.route.endsWith('user')
										? '55px'
										: '16px',
							})}
						>
							<DrawerTop expanded={isDrawerExpanded} isElevated={isSuccessStepActive}>
								<PortalOutlet context={ActionButtonsPortalContext} Component={ActionButons} />
							</DrawerTop>
							{!router.route.endsWith('user') && <HeadingBreadcrumbs />}
							{shouldDisplayTemplateChooserOnMobile && (
								<MobileTemplateChooserWrapper>
									<TemplateChooser templates={templates} />
								</MobileTemplateChooserWrapper>
							)}
							{user.role === 'administrator' && (
								<StyledDrawerBottom isElevated={isSuccessStepActive}>
									{allowedRoutes.map((routeConfig) => {
										const { route, icon } = routeConfig
										const isActive = router.route.startsWith(route)
										return (
											<Link
												key={route}
												href={isActive ? router.asPath : withTemplateId(route)}
												css={mobileNavLinkStyles(appTheme, isActive)}
											>
												{isActive ? icon.active : icon.default}
												{isSuccessStepActive && isActive && (
													<BubbleWrapper>
														<OuterBubble />
														<InnerBubble />
													</BubbleWrapper>
												)}
												{'notificationDot' in routeConfig && routeConfig.notificationDot && isInstallPromptVisible && (
													<NotificationDot data-testid="notification-dot" />
												)}
											</Link>
										)
									})}
									<SuccessStep />
									<Link href={USER_ROUTE} css={mobileNavLinkStyles(appTheme, router.route.startsWith(USER_ROUTE))}>
										<UserAvatarIcon />
									</Link>
								</StyledDrawerBottom>
							)}
							<MobileContent
								ref={contentRef}
								compact={router.route.startsWith(USER_ROUTE)}
								shrinkHeight={shouldDisplayTemplateChooserOnMobile}
							>
								{leftColumn}
							</MobileContent>
						</Drawer>
					</PortalProvider>
				</PortalProvider>
			</DrawerContext.Provider>
		)
	}

	return (
		<AppFrame>
			<Column hasTemplateChooser={shouldDisplayTemplateChooser}>
				{shouldDisplayTemplateChooser && <TemplateChooser templates={templates} />}
				<PortalProvider context={ActionButtonsPortalContext}>
					<PortalProvider context={HeadingControlPortalContext}>
						<Plane>
							<HeadingBreadcrumbs>
								<BetaBadgeWrapper>
									{betaBadgeTooltipText && <BetaBadge withIcon tooltipText={betaBadgeTooltipText} />}
								</BetaBadgeWrapper>
								<PortalOutlet context={HeadingControlPortalContext} />
							</HeadingBreadcrumbs>
							<Content ref={contentRef}>{leftColumn}</Content>
							<PortalOutlet context={ActionButtonsPortalContext} Component={ActionButons} />
						</Plane>
					</PortalProvider>
				</PortalProvider>
			</Column>
			{rightColumn}
		</AppFrame>
	)
}

export default DefaultLayout
