import createSvgIcon from './createSvgIcon'

const OpenInNew = createSvgIcon(
	<path d="M6.75,3 C7.30228475,3 7.75,3.44771525 7.75,4 C7.75,4.55228475 7.30228475,5 6.75,5 L3,5 L3,13 L11,13 L11,9.25 C11,8.73716416 11.3860402,8.31449284 11.8833789,8.25672773 L12,8.25 C12.5522847,8.25 13,8.69771525 13,9.25 L13,13.5 C13,14.3284271 12.3284271,15 11.5,15 L2.5,15 C1.67157288,15 1,14.3284271 1,13.5 L1,4.5 C1,3.67157288 1.67157288,3 2.5,3 L6.75,3 Z M13.9995313,1.00062501 C14.551694,1.0007113 14.9992887,1.44830604 14.999375,2.00046873 L15,5.99984373 C15.0000863,6.55212847 14.552441,6.99991368 14.0001563,7.00000001 C13.4478715,7.0000863 13.0000863,6.55244102 13,6.00015627 L12.999,4.41500001 L8.20710678,9.20710678 C7.81658249,9.59763107 7.18341751,9.59763107 6.79289322,9.20710678 C6.40236893,8.81658249 6.40236893,8.18341751 6.79289322,7.79289322 L11.584,3.00000001 L9.99984373,3.00000001 C9.48700789,2.99991984 9.0643969,2.61381361 9.00670952,2.1164659 L9,1.99984373 C9.00008632,1.44755898 9.44787153,0.999913704 10.0001563,1.00000001 L13.9995313,1.00062501 Z" />,
	{
		size: 16,
		viewBox: '0 0 16 16',
	},
)

export default OpenInNew
