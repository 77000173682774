import createSvgIcon from './createSvgIcon'

const NavArchive = createSvgIcon(
	<g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
		<path
			d="M7,10 C7,8.8954305 7.8954305,8 9,8 L23,8 C24.1045695,8 25,8.8954305 25,10 L25,10 C25,11.1045695 24.1045695,12 23,12 L9,12 C7.8954305,12 7,11.1045695 7,10 Z"
			stroke="#FFFFFF"
			strokeWidth="2"
		/>
		<path
			d="M9,12 L9,22 C9,23.1045695 9.8954305,24 11,24 L21,24 C22.1045695,24 23,23.1045695 23,22 L23,12"
			stroke="#FFFFFF"
			strokeWidth="2"
		/>
		<line x1="14" y1="16" x2="18" y2="16" stroke="#FFFFFF" strokeWidth="2" />
	</g>,
	{
		size: 32,
		viewBox: '0 0 32 32',
	},
)

export default NavArchive
