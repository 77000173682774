type DateObject = {
	time: string
	day: string
}

export const formatDate = (inputDate: Date | number | string): DateObject => {
	const today = new Date()
	const date = new Date(inputDate)
	const hours = date.getHours()
	const minutes = date.getMinutes()

	const time = `${hours >= 10 ? hours : `0${hours}`}:${minutes >= 10 ? minutes : `0${minutes}`}`

	if (today.toDateString() === date.toDateString()) {
		return {
			time: time,
			day: 'Today',
		}
	} else {
		const diffInTime = today.getTime() - date.getTime()
		const diffInDays = Math.floor(diffInTime / (1000 * 3600 * 24))

		if (diffInDays === 1) {
			return {
				time: time,
				day: 'Yesterday',
			}
		}

		return {
			time: time,
			day: `${diffInDays} days ago`,
		}
	}
}

export const extractTime = (createdAt: number) => {
	const date = new Date(createdAt)

	const hours = date.getHours().toString().padStart(2, '0')
	const minutes = date.getMinutes().toString().padStart(2, '0')

	return `${hours}:${minutes}`
}
