import { Theme, css } from '@emotion/react'
import styled from '@emotion/styled'
import Link from 'next/link'
import { useRouter } from 'next/router'
import React from 'react'

import { Title } from './SharedStyledComponents'
import SlideInWrapper from 'components/SlideInWrapper'
import { CaretLeftIcon } from 'components/icons'
import useTemplateId from 'hooks/useTemplateId'
import { useTracking } from 'hooks/useTracking'
import { NestedRoutes, routes } from 'layouts/routes'
import { EVENT_NAMES } from 'lib/tracking'

const HeadingWrapper = styled.div`
	height: 50px;
	display: flex;
	align-items: center;
	gap: ${({ theme }) => theme.spaces.space3};
	justify-content: ${({ theme }) => (theme.isMobile ? 'flex-start' : 'space-between')};
	border-bottom: 1px solid ${({ theme }) => theme.colors.light.borderColor};
`

const BreadcrumbsWrapper = styled.nav`
	width: 100%;
	display: flex;
	gap: ${({ theme }) => theme.spaces.space3};
`

const breadcrumbStyles = (theme: Theme) => css`
	display: flex;
	gap: ${theme.spaces.space2};
	align-items: center;
	color: ${theme.colors.light.breadcrumb};
	text-decoration: none;
`

const BreadcrumbCurrent = styled.span`
	${({ theme }) => theme.typography.smallHeading};
	color: ${({ theme }) => theme.colors.light.primaryTextColor};
	white-space: nowrap;
	align-self: center;
`

const ChildrenWrapper = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: flex-end;
`

type Props = {
	children?: React.ReactNode
	transition?: boolean
}

function HeadingBreadcrumbs({ children, transition = true }: Props) {
	const router = useRouter()
	const path = router.pathname.split('/[')[0]
	const { withTemplateId } = useTemplateId()
	const track = useTracking()
	const route = routes.find(({ route }) => path.startsWith(route))
	const isNestedRoute = route && 'children' in route && route.children.some(({ pathName }) => path.includes(pathName))

	const bareHeading = (
		<HeadingWrapper>
			<Title>{route?.title}</Title>
		</HeadingWrapper>
	)

	if (!isNestedRoute) {
		return bareHeading
	}

	const [currentRoute, previousRoute] = path.split('/').reverse()

	if (!previousRoute || !currentRoute) {
		return bareHeading
	}

	const currentRouteName = (route.children as Readonly<NestedRoutes[]>).find(({ pathName }) =>
		currentRoute.replace('#', '').endsWith(pathName),
	)?.title

	const previousRouteHref = path.split('/').slice(0, -1).join('/')

	const handleBreadcrumbClick = () => {
		track(EVENT_NAMES.NAVIGATION_BACK_CLICKED, { sectionName: currentRoute })
	}

	const Wrapper = transition ? SlideInWrapper : React.Fragment

	return (
		<Wrapper>
			<HeadingWrapper>
				<BreadcrumbsWrapper>
					<Link
						href={'omitTemplateIdInNavigation' in route ? previousRouteHref : withTemplateId(previousRouteHref)}
						css={breadcrumbStyles}
						aria-label="navigate back"
						onClick={handleBreadcrumbClick}
					>
						<CaretLeftIcon />
					</Link>
					<BreadcrumbCurrent>{currentRouteName}</BreadcrumbCurrent>
					<ChildrenWrapper>{children}</ChildrenWrapper>
				</BreadcrumbsWrapper>
			</HeadingWrapper>
		</Wrapper>
	)
}

export default HeadingBreadcrumbs
