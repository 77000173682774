import { useEffect } from 'react'
import useSWR from 'swr'
import type { SWRConfiguration } from 'swr'

import { config } from 'lib/config'
import { store } from 'lib/store'
import type { Message } from 'types/assistants'

export function useOpenAiThreadMessages(id: string | null | undefined, options?: SWRConfiguration) {
	const { data, mutate, isLoading, error } = useSWR<Message[]>(
		id ? `${config.apiURL}/v1.0/integrations/openai/threads/${id}/messages` : null,
		options,
	)

	useEffect(() => {
		if (error) {
			store.dispatch({
				type: 'toast/set',
				payload: { kind: 'error', timeout: 6000, message: "We couldn't load history for selected thread" },
			})
		}
	}, [error])

	return {
		messages: data,
		mutateMessages: mutate,
		messagesError: error,
		areMessagesLoading: isLoading,
	}
}
