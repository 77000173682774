import createSvgIcon from './createSvgIcon'

const Question = createSvgIcon(
	<g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
		<g transform="translate(-1.000000, -1.000000)">
			<rect x="0" y="0" width="20" height="20"></rect>
			<circle
				stroke="currentColor"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
				cx="10"
				cy="10"
				r="8"
			></circle>
			<circle fill="currentColor" fillRule="nonzero" cx="10" cy="13.75" r="1.25"></circle>
			<path
				d="M10,10.625 C11.2081229,10.625 12.1875,9.64562289 12.1875,8.4375 C12.1875,7.22937711 11.2081229,6.25 10,6.25 C8.79187711,6.25 7.8125,7.22937711 7.8125,8.4375"
				stroke="currentColor"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			></path>
		</g>
	</g>,
	{
		size: 16,
		viewBox: '0 0 18 18',
	},
)

export default Question
