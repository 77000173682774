import createEmotionCache from '@emotion/cache'
import { CacheProvider, Global, ThemeProvider } from '@emotion/react'
import { GoogleTagManager } from '@next/third-parties/google'
import { LazyMotion, MotionConfig } from 'framer-motion'
import { setAutoFreeze } from 'immer'
import type { AppProps } from 'next/app'
import Head from 'next/head'
import { useRouter } from 'next/router'
import React from 'react'
import { Provider } from 'react-redux'
import { SWRConfig } from 'swr'

import { promiseRetry } from '@livechat/promise-utils'

import { PreviewControllerProvider } from '../components/WidgetPreview/previewController'
import ChatArchivesLayout from '../layouts/ChatArchivesLayout'
import OAuth from 'components/OAuth'
import OpenWidgetScript from 'components/OpenWidgetScript'
import ToastManager from 'components/ToastManager'
import { TemplateIdProvider } from 'hooks/useTemplateId'
import ConfiguratorLayout from 'layouts/ConfiguratorLayout'
import ErrorPagesLayout from 'layouts/ErrorPagesLayout'
import OnboardingLayout from 'layouts/OnboardingLayout'
import ShareLayout from 'layouts/ShareLayout'
import { config } from 'lib/config'
import { ERROR_ROUTES, PUBLIC_ROUTES } from 'lib/constants'
import { fetcher } from 'lib/fetcher'
import { store } from 'lib/store'
import { initAmplitude } from 'lib/tracking'
import { globalStyles } from 'styles/global'
import { theme } from 'styles/theme'

setAutoFreeze(false)
initAmplitude()

const emotionCache = createEmotionCache({ key: 'ow' })
const loadFramerMotionFeatures = () => promiseRetry(() => import('lib/framerMotionFeatures')).then((mod) => mod.default)

function App({ Component, pageProps }: AppProps) {
	const router = useRouter()
	const isErrorPage = ERROR_ROUTES.includes(router.route)
	const isPublicPage = PUBLIC_ROUTES.includes(router.route)
	const isOnboardingPage = router.route.startsWith('/onboarding/')

	let Layout = (props: { children: React.ReactNode }) => <>{props.children}</>

	if (isOnboardingPage) {
		Layout = OnboardingLayout
	}

	if (router.route.startsWith('/configurator/')) {
		Layout = ConfiguratorLayout
	}

	if (router.route.startsWith('/share/')) {
		Layout = ShareLayout
	}

	if (router.route.startsWith('/chat-archives/')) {
		Layout = ChatArchivesLayout
	}

	if (isErrorPage) {
		Layout = ErrorPagesLayout
	}

	const page = (
		<Layout>
			<Component {...pageProps} />
		</Layout>
	)

	return (
		<>
			<Head>
				<title>OpenWidget</title>
			</Head>
			<GoogleTagManager gtmId="GTM-NLLMSVDW" />
			<CacheProvider value={emotionCache}>
				<ThemeProvider theme={theme}>
					<MotionConfig isStatic={config.isTest}>
						<LazyMotion strict features={loadFramerMotionFeatures}>
							<Global styles={globalStyles({ limitContentWidth: !router.route.startsWith('/hello') })} />
							{isErrorPage ? (
								page
							) : (
								<Provider store={store}>
									<PreviewControllerProvider>
										<ToastManager />
										<SWRConfig value={{ fetcher, revalidateOnFocus: !config.isTest }}>
											{isPublicPage ? (
												<TemplateIdProvider>{page}</TemplateIdProvider>
											) : (
												<OAuth>
													<TemplateIdProvider>{page}</TemplateIdProvider>
												</OAuth>
											)}
										</SWRConfig>
									</PreviewControllerProvider>
								</Provider>
							)}
						</LazyMotion>
					</MotionConfig>
				</ThemeProvider>
			</CacheProvider>
			{!theme.isMobile && !config.isTest && !config.isDev && !isOnboardingPage && <OpenWidgetScript />}
		</>
	)
}

export default App
