import createSvgIcon from './createSvgIcon'

const StackFilled = createSvgIcon(
	<g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
		<path
			d="M14.348,7.169 L7.198,10.282 C6.2007075,10.7247975 5.74240087,11.8852476 6.168,12.89 L11.088,24.785 C11.2862159,25.2729501 11.672468,25.6605962 12.1597008,25.8605686 C12.6469336,26.060541 13.1941445,26.0560111 13.678,25.848 L20.82,22.738 C21.8204302,22.2966364 22.2816603,21.1342118 21.856,20.127 L16.936,8.233 C16.7379536,7.74537416 16.3521172,7.35788644 15.8653416,7.15775924 C15.3785661,6.95763203 14.8317571,6.9616829 14.348,7.169 L14.348,7.169 Z"
			fill="#FFFFFF"
			fillRule="nonzero"
		/>
		<path
			d="M20,6.9999999 C21.0466248,6.99966878 21.9165032,7.80632296 21.995,8.85 L22,8.9999999 L22,12.4999999 C21.9994131,13.0290934 21.5867895,13.4661648 21.0586052,13.4971721 C20.5304208,13.5281794 20.0694908,13.1423904 20.007,12.617 L20,12.4999999 L20,8.9999999 L19,8.9999999 C18.4709066,8.99941314 18.0338352,8.58678954 18.0028279,8.05860519 C17.9718206,7.53042083 18.3576096,7.06949083 18.883,7.007 L19,6.9999999 L20,6.9999999 Z"
			fill="#FFFFFF"
			fillRule="nonzero"
		/>
		<path
			d="M23.08,9.61 C23.2957051,9.10221741 23.8818944,8.86505683 24.39,9.08 C24.647,9.188 24.895,9.29 25.159,9.394 C26.1209854,9.8023658 26.6063832,10.8825283 26.273,11.873 L26.217,12.019 L23.919,17.393 C23.7091293,17.8770855 23.159269,18.1148296 22.6628298,17.9361327 C22.1663906,17.7574357 21.8941986,17.2237878 22.041,16.717 L22.081,16.607 L24.377,11.236 L24.011,11.088 L23.609,10.921 C23.3646354,10.8174196 23.1714717,10.6209438 23.0720603,10.3748537 C22.9726489,10.1287636 22.9751455,9.85324819 23.079,9.609 L23.08,9.61 Z"
			fill="#FFFFFF"
			fillRule="nonzero"
		/>
	</g>,
	{
		size: 32,
		viewBox: '0 0 32 32',
	},
)

export default StackFilled
