import { css, useTheme } from '@emotion/react'
import styled from '@emotion/styled'
import { useRouter } from 'next/router'
import React from 'react'

import WidgetPreview from 'components/WidgetPreview'
import { ChevronIcon } from 'components/icons'
import { usePublicWidgetPreviewProps } from 'hooks/usePublicWidgetPreviewProps'
import { Drawer } from 'layouts/components/Drawer'
import { Foreground } from 'layouts/components/Foreground'
import { Section } from 'layouts/components/Section'
import {
	Content,
	DrawerBottom,
	Main,
	MobileContent,
	Plane,
	Title,
	WidgetContainer,
} from 'layouts/components/SharedStyledComponents'
import { WidgetPlane } from 'layouts/components/WidgetPlane'

const StyledDrawerBottom = styled(DrawerBottom)<{ isDrawerExpanded: boolean }>`
	justify-content: center;
	bottom: ${({ theme }) => theme.spaces.space3};
	position: fixed;
	left: ${({ theme }) => theme.spaces.space3};
	right: ${({ theme }) => theme.spaces.space3};
	border-radius: ${({ theme }) => theme.borderRadiuses.lg};
	background-color: ${({ theme }) => theme.colors.light.primaryBackgroundColor};

	svg {
		scale: 1.2;
		will-change: rotate;
		transition: rotate 200ms ease;

		${({ isDrawerExpanded }) =>
			!isDrawerExpanded &&
			css`
				rotate: 180deg;
			`}
	}
`

type Props = {
	children: React.ReactNode
}

function ShareLayout({ children }: Props) {
	const router = useRouter()
	const organizationId = router.query.organizationId as string | undefined
	const widgetProps = usePublicWidgetPreviewProps(organizationId, organizationId)
	const theme = useTheme()
	const [isDrawerExpanded, setIsDrawerExpanded] = React.useState(true)

	if (theme.isMobile) {
		return (
			<>
				<Foreground onClick={() => setIsDrawerExpanded(false)}>
					<Section>
						<WidgetPlane pushed={isDrawerExpanded}>
							<WidgetPreview {...widgetProps} />
						</WidgetPlane>
					</Section>
				</Foreground>
				<Drawer
					expanded={isDrawerExpanded}
					css={css({
						backgroundColor: theme.colors.light.primaryBackgroundColor,
						paddingTop: '16px',
					})}
				>
					<Title>Add to website</Title>
					<StyledDrawerBottom
						isDrawerExpanded={isDrawerExpanded}
						onClick={() => setIsDrawerExpanded((state) => !state)}
					>
						<ChevronIcon direction={isDrawerExpanded ? 'downwards' : 'upwards'} />
					</StyledDrawerBottom>
					<MobileContent>{children}</MobileContent>
				</Drawer>
			</>
		)
	}

	return (
		<>
			<Main>
				<Plane css={css({ marginLeft: '72px' })}>
					<Title>Add to website</Title>
					<Content>{children}</Content>
				</Plane>
				<WidgetContainer>
					<WidgetPreview {...widgetProps} />
				</WidgetContainer>
			</Main>
		</>
	)
}

export default ShareLayout
