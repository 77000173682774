import { config } from './config'
import { SeamlessInstallationGuide } from 'types/installation'
import { Organization } from 'types/organization'

export function getSeamlessInstallationGuides(tracking?: Organization['tracking']): SeamlessInstallationGuide[] {
	const isGoogleReviewsSource =
		tracking?.accounts_source_url?.includes('google-reviews') ||
		tracking?.accounts_utm_source?.includes('google-reviews')

	return [
		{
			name: 'WordPress',
			logo: 'https://cdn.livechat-static.com/app/img/onboarding/cards/wordpress.svg',
			logoDark: '/images/tutorials/wordpress.svg',
			articleURL: 'https://help.openwidget.com/wordpress-enhance-your-store-with-openwidget/',
			pluginURL: isGoogleReviewsSource
				? 'https://wordpress.org/plugins/free-google-reviews-widget-by-openwidget/'
				: 'https://wordpress.org/plugins/free-customer-service-tools-by-openwidget/',
			isEcommerce: false,
			tag: 'wordpress',
		},
		{
			name: 'Google Tag Manager',
			logo: 'https://cdn.livechat-static.com/app/img/onboarding/cards/gtm.svg',
			logoDark: '/images/tutorials/tagmanager.svg',
			articleURL:
				'https://help.openwidget.com/google-tag-manager-install-openwidget-on-your-website-with-a-few-simple-steps/',
			pluginURL: '',
			isEcommerce: false,
			tag: 'googletagmanager',
		},
		{
			name: 'Wix',
			logo: 'https://cdn.livechat-files.com/api/file/developers/img/applications/cwNxG5SSR/icons/fR1QM5SSR-960x960.png',
			logoDark: '/images/tutorials/wix.svg',
			articleURL: 'https://help.openwidget.com/our-dedicated-google-reviews-for-wix-app-is-here/',
			pluginURL: `${config.apiURL}/v1.0/plugin/wix/install`,
			isEcommerce: false,
			tag: 'wix',
		},
	]
}
