import createSvgIcon from './createSvgIcon'

const CustomButton = createSvgIcon(
	<g transform="translate(35.000000, 26.000000)">
		<rect fill="#FFFFFF" x="0" y="0" width="170" height="188" rx="10"></rect>
		<rect fill="#E4E4E4" x="9.01515152" y="10.3013699" width="151.969697" height="78.5479452" rx="4"></rect>
		<rect fill="#E4E4E4" x="9.01515152" y="100.438356" width="75.9848485" height="77.260274" rx="4"></rect>
		<rect fill="#E4E4E4" x="99.1666667" y="105.589041" width="50.2272727" height="5.15068493" rx="2.57534247"></rect>
		<rect fill="#E4E4E4" x="99.1666667" y="114.60274" width="34.9407115" height="5.15068493" rx="2.57534247"></rect>
		<rect fill="#E4E4E4" x="99.1666667" y="124.90411" width="21.8379447" height="5.15068493" rx="2.57534247"></rect>
		<rect fill="#ADADAD" x="60.530303" y="65.6712329" width="50.2272727" height="11.5890411" rx="5.79452055"></rect>
		<rect fill="#3300FF" x="99.1666667" y="146.794521" width="34.7727273" height="11.5890411" rx="5.79452055"></rect>
		<rect fill="#FEFEFE" x="41.2121212" y="38.630137" width="88.8636364" height="5.15068493" rx="2.57534247"></rect>
		<rect fill="#FEFEFE" x="57.9545455" y="47.6438356" width="61.8181818" height="5.15068493" rx="2.57534247"></rect>
	</g>,
	{
		width: 240,
		height: 240,
		viewBox: '0 0 240 240',
	},
)

export default CustomButton
