import { useInstallations } from './useInstallations'
import { useOrganization } from './useOrganization'
import { INSTALLATION_GUIDES } from 'lib/constants'
import { getSeamlessInstallationGuides } from 'lib/seamlessInstallationGuides'
import { InstallationGuide, SeamlessInstallationGuide } from 'types/installation'

type InstallPrompt = {
	isVisible: boolean
	tutorial: InstallationGuide | SeamlessInstallationGuide | null
	areInstallationsLoading: boolean
}

export function useInstallPrompt(): InstallPrompt {
	const { organization, isOrganizationLoading } = useOrganization()
	const { installations, areInstallationsLoading } = useInstallations()
	const platform = organization?.websitePlatform
	const seamlessInstallationGuides = getSeamlessInstallationGuides(organization?.tracking)
	const guides = [...INSTALLATION_GUIDES, ...seamlessInstallationGuides]

	return {
		isVisible: installations?.length === 0 && !!organization && platform !== 'wix',
		tutorial: (platform && guides.find(({ tag }) => tag === platform)) ?? null,
		areInstallationsLoading: areInstallationsLoading || isOrganizationLoading,
	}
}
