import createSvgIcon from './createSvgIcon'

const Share = createSvgIcon(
	<path d="M3,7 C3.55228475,7 4,7.44771525 4,8 L4,19 L18,19 C18.5128358,19 18.9355072,19.3860402 18.9932723,19.8833789 L19,20 C19,20.5522847 18.5522847,21 18,21 L3.75,21 C2.78350169,21 2,20.2164983 2,19.25 L2,8 C2,7.44771525 2.44771525,7 3,7 Z M16.624695,5.21913119 L21.4906028,9.11151207 C21.8117762,9.27789782 22.03125,9.61331849 22.03125,10 C22.03125,10.3866815 21.8117762,10.7221022 21.4906028,10.8884879 L16.624695,14.7808688 C16.1934331,15.1258784 15.5641407,15.055957 15.2191312,14.624695 C14.8741216,14.1934331 14.944043,13.5641407 15.375305,13.2191312 L18.149,10.9999632 L15.7698064,11 C11.8330299,11.0011865 8.82537442,12.9487643 7.96790768,16.251306 C7.8291152,16.7858667 7.28325463,17.1067002 6.74869397,16.9679077 C6.21413332,16.8291152 5.89329983,16.2832546 6.03209232,15.748694 C7.1375979,11.4908281 10.9819697,9.00144289 15.769505,9 L18.149,8.99996318 L15.375305,6.78086881 C14.977217,6.46239846 14.8870169,5.90169895 15.1468548,5.47812274 L15.2191312,5.37530495 C15.5641407,4.94404302 16.1934331,4.87412164 16.624695,5.21913119 Z" />,
	{
		size: 24,
		viewBox: '0 0 24 24',
	},
)

export default Share
