import createSvgIcon from './createSvgIcon'

const Plus = createSvgIcon(
	<path d="M10,3 C10.5522847,3 11,3.44771525 11,4 L10.999,8.999 L16,9 C16.5522847,9 17,9.44771525 17,10 C17,10.5522847 16.5522847,11 16,11 L10.999,10.999 L11,16 C11,16.5522847 10.5522847,17 10,17 C9.44771525,17 9,16.5522847 9,16 L8.999,10.999 L4,11 C3.44771525,11 3,10.5522847 3,10 C3,9.44771525 3.44771525,9 4,9 L8.999,8.999 L9,4 C9,3.44771525 9.44771525,3 10,3 Z" />,
	{
		size: 20,
		viewBox: '0 0 20 20',
	},
)

export default Plus
