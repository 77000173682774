import { css } from '@emotion/react'
import styled from '@emotion/styled'

import { StyledButton } from 'components/Button'

const EXPANDED_SHADOW =
	'0px -0.60px 3.0162px -0.167px rgba(0, 0, 0, 0.04), 0px -2.290px 11.451px -0.33px rgba(0, 0, 0, 0.0708), 0px -10px 50px -0.5px rgba(0, 0, 0, 0.225)'

const COLLAPSED_SHADOW = '0px -0.48px 2.414px -0.25px rgba(0, 0, 0, 0.05), 0px -4px 20px -0.5px rgba(0, 0, 0, 0.225);'

export const DrawerNav = styled.nav<{ isElevated?: boolean }>`
	top: 16px;
	left: 0;
	right: 0;
	padding: 0 ${({ theme }) => theme.spaces.space6};
	display: flex;
	position: absolute;
	flex-direction: row;
	padding-bottom: 8px;
	border-bottom: 1px solid ${({ theme }) => theme.colors.light.surfaceDecorative};
	z-index: ${({ theme, isElevated }) => (isElevated ? theme.zIndexes.high : theme.zIndexes.none)};

	${StyledButton} {
		border-radius: 24px;
		padding: 12px;
		font-size: 12px;
	}
`

export const Drawer = styled.div<{ expanded: boolean; compact?: boolean }>`
	position: absolute;
	bottom: 0px;
	right: 0px;
	left: 0px;
	margin: 8px;
	will-change: height, box-shadow;
	max-width: 100%;
	overflow: hidden;
	border-radius: 24px;
	background-color: ${({ theme }) => theme.colors.light.surface};
	transition: height 275ms ${({ theme }) => theme.transitions.easings.css.swift};

	${({ expanded, compact, theme }) =>
		expanded
			? css`
					height: ${compact ? 40 : 80}%;
					min-height: 300px;
					padding: ${theme.spaces.space6};
					padding-top: 55px;
					box-shadow: ${EXPANDED_SHADOW};
			  `
			: css`
					height: 0%;
					padding: 55px 0 0;
					box-shadow: ${COLLAPSED_SHADOW};

					${DrawerNav} {
						border: none;
					}
			  `}
`
