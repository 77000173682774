import createSvgIcon from './createSvgIcon'

const Archive = createSvgIcon(
	<path d="M17.5625,3 L2.56250003,3 C1.69955511,3 1,3.69955508 1,4.5625 L1,6.4375 C1,7.18010722 1.52239651,7.82022857 2.25000003,7.96875 L2.25000003,14.5625 C2.25000003,15.4254449 2.94955511,16.125 3.81250003,16.125 L16.3125,16.125 C17.175445,16.125 17.875,15.4254449 17.875,14.5625 L17.875,7.96875 C18.6026036,7.82022857 19.1250001,7.18010722 19.1250001,6.4375 L19.1250001,4.5625 C19.1250001,4.14809923 18.96038,3.75067125 18.6673544,3.45764565 C18.3743288,3.16462006 17.9769008,3 17.5625,3 Z M2.87500003,4.875 L17.25,4.875 L17.25,6.125 L2.87500003,6.125 L2.87500003,4.875 Z M4.12500003,14.25 L4.12500003,8 L16,8 L16,14.25 L4.12500003,14.25 Z M12.875,10.1875 C12.875,10.705267 12.455267,11.125 11.9375,11.125 L8.18750003,11.125 C7.66973308,11.125 7.25000003,10.705267 7.25000003,10.1875 C7.25000003,9.66973305 7.66973308,9.25 8.18750003,9.25 L11.9375,9.25 C12.455267,9.25 12.875,9.66973305 12.875,10.1875 Z" />,
	{
		size: 20,
		viewBox: '0 0 20 20',
	},
)

export default Archive
