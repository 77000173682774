import { config } from './config'
import { FAQ } from 'types/faq'
import { FormName } from 'types/feature'
import { Header } from 'types/header'
import { InstallationGuide } from 'types/installation'
import { LanguageCode } from 'types/localization'
import { Recommendation } from 'types/recommendations'
import { Theme } from 'types/theme'
import { User } from 'types/user'

export const ERROR_ROUTES = ['/oops', '/404']

export const PUBLIC_ROUTES = [...ERROR_ROUTES, '/share/[organizationId]']

export const MINIMIZED_WIDGET_ROUTES = [
	'/configurator/widget-content/visitor-counter',
	'/configurator/widget-content/product-recommendations',
	'/configurator/widget-content/google-reviews',
	'/configurator/widget-content/whatsapp',
	'/configurator/widget-content/messenger',
	'/onboarding/google-reviews',
	'/onboarding/whatsapp-widget',
]

export const FORM_NAMES: FormName[] = ['contact', 'bugreport', 'feedback']

export const TEMP_ENTITY_PREFIX = 'local-'

export const WIDGET_PREVIEW_ID = 'widget-preview'

export const CURRENT_TIMEZONE = Intl.DateTimeFormat().resolvedOptions().timeZone

export const DEFAULT_AVATAR_URL = 'https://cdn.livechat-static.com/api/file/lc/img/ow/placeholders/avatar.png'
export const DEFAULT_OPENAI_AVATAR_URL = 'https://cdn.files-text.com/api/ow/img/integrations/openai/orb.gif'

export const PREVENT_ROUTE_CHANGE_ERROR = 'Route change prevented'

const HelpDeskAppBaseUrl = `https://app.${
	config.isTest || config.isDev || config.appURL.includes('labs.') ? 'labs.' : ''
}helpdesk.com`
export const HelpDeskIntegrationUrls = {
	APP: HelpDeskAppBaseUrl,
	TICKETS: `${HelpDeskAppBaseUrl}/tickets`,
	APP_OW_SETTINGS: `${HelpDeskAppBaseUrl}/settings/openwidget`,
	PROMO_VIDEO_EMBED: 'https://www.youtube.com/embed/L9G0C-7aU8w?si=mlhbKYhJAvkNsAed',
	PROMO_VIDEO: 'https://youtu.be/L9G0C-7aU8w?si=mlhbKYhJAvkNsAed',
} as const

const LiveChatAppBaseUrl = `https://my.${
	config.isTest || config.isDev || config.appURL.includes('labs.') ? 'labs.' : ''
}livechatinc.com`
export const LiveChatIntegrationUrls = {
	APP: LiveChatAppBaseUrl,
	CHATS: `${LiveChatAppBaseUrl}/chats`,
	SETTINGS: `${LiveChatAppBaseUrl}/settings`,
	SETTINGS_AVAILABILITY: `${LiveChatAppBaseUrl}/settings/availability`,
	SUBSCRIPTION: `${LiveChatAppBaseUrl}/subscription`,
	LIVECHAT_SUPPORT: 'https://direct.lc.chat/1520',
	PROMO_VIDEO_EMBED: 'https://www.youtube.com/embed/l86bnupc_4o?si=keI_lo7VReeU4oY2',
	PROMO_VIDEO: 'https://youtu.be/l86bnupc_4o?si=x-CgnhAU0yr9as7M',
} as const

const ChatBotAppBaseUrl = `https://app.${
	config.isTest || config.isDev || config.appURL.includes('labs.') ? 'staging.' : ''
}chatbot.com`
export const ChatBotIntegrationUrls = {
	APP: ChatBotAppBaseUrl,
	DASHBOARD: `${ChatBotAppBaseUrl}/dashboard`,
	ARCHIVES: `${ChatBotAppBaseUrl}/v2/archives`,
	LEARN_MORE: `https://www.chatbot.com/features`,
	SUBSCRIPTION: `${ChatBotAppBaseUrl}/settings/subscription/dashboard`,
} as const

export const LIVECHAT_BETA_BADGE_TOOLTIP_TEXT =
	'Note that not all features offered by LiveChat will work with this integration.'
export const OPENAI_BETA_BADGE_TOOLTIP_TEXT = 'Note that OpenAI Assistants is still an OpenAI beta feature'

export const AVATAR_URLS = new Array(26)
	.fill('https://cdn.livechat-static.com/api/file/lc/img/ow/avatars')
	.map((base, index) => `${base}/${index + 1}.png`)
	.reverse()

export const TEST_USER: User = {
	accountId: 'c2758a7d-146f-4a7d-ae49-3a725c74a5f9',
	organizationId: '2e078c99-dd86-48c1-a5aa-f029c1acc23d',
	avatarUrl: 'https://cdn.livechat-static.com/api/file/lc/img/ow/placeholders/avatar.png',
	email: 'john.doe@livechat.com',
	emailVerified: true,
	name: 'John Doe',
	role: 'administrator',
	isTextMember: true,
}

export const DEFAULT_HEADER: Header = {
	text: 'Hello, nice to see you here 👋',
	logo: 'https://cdn.livechat-static.com/api/file/lc/img/ow/placeholders/logo.png',
	backgroundImage: '',
	backgroundType: 'gradient',
}

export const DEFAULT_FAQ: FAQ = {
	questions: [
		{
			summary: 'What is the delivery time?',
			details:
				"Shipping takes 5 days on average. We will send you a confirmation message with your package's tracking information and delivery date.",
		},
		{ summary: 'Do you ship internationally?', details: 'Yes, we deliver to any location worldwide.' },
		{ summary: 'What is the return policy?', details: 'You have 7 days to return the product.' },
	],
}

export const DEFAULT_THEME: Theme = {
	id: `${TEMP_ENTITY_PREFIX}-theme`,
	primaryColor: '#000000',
	screenPosition: 'right',
	offsetX: 0,
	offsetY: 0,
	mobileOffsetX: 0,
	mobileOffsetY: 0,
	mobileScreenPosition: 'right',
	customMobileSettings: false,
	disableMinimized: false,
	mobileDisableMinimized: false,
	variant: 'light',
	visitorMessageColorText: null,
	visitorMessageColorBackground: null,
	agentMessageColorText: null,
	agentMessageColorBackground: null,
	minimizedColorIcon: null,
	minimizedColorBackground: null,
}

export const INSTALLATION_GUIDES: InstallationGuide[] = [
	{
		name: 'Woocommerce',
		logo: 'https://cdn.livechat-static.com/app/img/onboarding/cards/woo.svg',
		logoDark: '/images/tutorials/woocommerce.svg',
		articleURL: 'https://help.openwidget.com/woocommerce-enhance-your-store-with-openwidget/',
		isEcommerce: true,
		tag: 'woocommerce',
	},
	{
		name: 'Shopify',
		logo: 'https://cdn.livechat-static.com/app/img/onboarding/cards/shopify.svg',
		logoDark: '/images/tutorials/shopify.svg',
		articleURL: 'https://help.openwidget.com/shopify-add-openwidget-to-your-store/',
		isEcommerce: true,
		tag: 'shopify',
	},
	{
		name: 'Squarespace',
		logo: 'https://cdn.livechat-files.com/api/file/developers/img/applications/squarespace/WjliYVViR-icon-480x480.png',
		logoDark: '/images/tutorials/squarespace.svg',
		articleURL: 'https://help.openwidget.com/squarespace-add-openwidget-to-your-website/',
		isEcommerce: true,
		tag: 'squarespace',
	},
	{
		name: 'BigCommerce',
		logo: 'https://cdn.livechat-static.com/app/img/onboarding/cards/bigcommerce.svg',
		logoDark: '/images/tutorials/bigcommerce.svg',
		articleURL: 'https://help.openwidget.com/bigcommerce-add-openwidget-to-your-bigcommerce-store/',
		isEcommerce: true,
		tag: 'bigcommerce',
	},
	{
		name: 'OpenCart',
		logo: 'https://cdn.livechat-static.com/api/file/lc/img/ow/opencart.png',
		logoDark: '/images/tutorials/opencart.svg',
		articleURL: 'https://help.openwidget.com/opencart-add-openwidget-to-your-opencart-store/',
		isEcommerce: true,
		tag: 'opencart',
	},
]

export const LIVECHAT_LOCALIZATION = {
	agents_not_available: 'Our agents are not available at the moment.',
	attach_screenshot: 'Attach a screenshot',
	button_add_feedback: 'Leave a comment',
	client: 'Customer',
	close_chat: 'Close the chat',
	embedded_chat_with: 'Chat with %operator%',
	embedded_leave_message: 'Leave message',
	embedded_minimize_window: 'Minimize window',
	leave_chat_confirm: 'Do you really want to close the chat?',
	new_message: 'New message',
	rate_agent: 'Rate agent',
	rate_bad_and_open_modal: 'Rate as bad and open comment modal',
	rate_good_and_open_modal: 'Rate as good and open comment modal',
	rate_me_add_comment: 'Thank you for the rating! You can also leave a comment:',
	rate_me_bad_tooltip: 'Rate as bad',
	rate_me_confirmation_bad: 'You rated our customer service as bad.',
	rate_me_confirmation_good: 'You rated our customer service as good.',
	rate_me_good_tooltip: 'Rate as good',
	rate_me_placeholder: 'Type in your comment…',
	send_file: 'Send a file',
	send_transcript: 'Send transcript',
	sounds: 'Sounds',
	survey_fill_in_required_fields: 'Fill in required fields',
	user_in_queue:
		'One of our representatives will be with you shortly. You are number %number% in the queue. Your wait time will be approximately %minutes% minute(s). Thank you for your patience.',
	welcome_title: 'Chat with us!',
	welcome_to_chat: 'Hello. How may I help you?',
}

export const DEMO_RECOMMENDATIONS: Recommendation[] = [
	{
		id: '1',
		name: 'Demo product book',
		image: '/images/products/demo-product-book.png',
		link: '',
		currency: 'USD',
		price: 10,
		description: 'This is a demo product book',
		score: 0.9,
	},
	{
		id: '2',
		name: 'Demo product box',
		image: '/images/products/demo-product-box.png',
		link: '',
		currency: 'USD',
		price: 20,
		description: 'This is a demo product box',
		score: 0.8,
	},
	{
		name: 'Demo product hoodie',
		image: '/images/products/demo-product-hoodie.png',
		link: '',
		id: '3',
		currency: 'USD',
		price: 30,
		description: 'This is a demo product hoodie',
		score: 0.7,
	},
]

export const LANGUAGE_CODES = ['da', 'nl', 'en', 'fr', 'de', 'it', 'no', 'pl', 'pt', 'pt-br', 'es', 'sv'] as const

export const LANGUAGE_CODES_TO_NAMES_MAP: Record<LanguageCode, string> = {
	da: 'Danish',
	nl: 'Dutch',
	en: 'English',
	fr: 'French',
	de: 'German',
	it: 'Italian',
	no: 'Norwegian',
	pl: 'Polish',
	pt: 'Portuguese',
	'pt-br': 'Portuguese (Brazil)',
	es: 'Spanish',
	sv: 'Swedish',
}
