import createSvgIcon from './createSvgIcon'

const Phone = createSvgIcon(
	<path d="M13.6875,1 L6.1875,1 C4.97937711,1 4,1.97937711 4,3.1875 L4,16.9375 C4,18.1456229 4.97937711,19.125 6.1875,19.125 L13.6875,19.125 C14.2676611,19.125 14.8240603,18.8945319 15.2342961,18.4842961 C15.6445319,18.0740603 15.875,17.5176611 15.875,16.9375 L15.875,3.1875 C15.875,1.97937711 14.8956229,1 13.6875,1 Z M5.875,6 L14,6 L14,14.125 L5.875,14.125 L5.875,6 Z M6.1875,2.875 L13.6875,2.875 C13.860089,2.875 14,3.01491102 14,3.1875 L14,4.125 L5.875,4.125 L5.875,3.1875 C5.875,3.01491102 6.01491102,2.875 6.1875,2.875 Z M13.6875,17.25 L6.1875,17.25 C6.01491102,17.25 5.875,17.110089 5.875,16.9375 L5.875,16 L14,16 L14,16.9375 C14,17.110089 13.860089,17.25 13.6875,17.25 Z" />,
	{
		size: 20,
		viewBox: '0 0 20 20',
	},
)

export default Phone
