import { css } from '@emotion/react'
import styled from '@emotion/styled'

import Badge from './Badge'
import HoverableItem from './HoverableItem'
import { InfoIcon } from './icons'

const StyledBadge = styled(Badge)<{ withIcon?: boolean }>`
	background-color: ${({ theme }) => theme.colors.light.surfaceAlternative};
	font-weight: 600;
	padding: ${({ theme }) => theme.spaces.space1};
	padding-right: ${({ theme }) => theme.spaces.space3};
	border-radius: ${({ theme }) => theme.borderRadiuses.xl};
	border: 1px solid ${({ theme }) => theme.colors.light.borderColor};
	${({ theme, withIcon }) =>
		!withIcon &&
		css`
			padding: ${theme.spaces.space1} ${theme.spaces.space3};
		`};
`

type Props =
	| {
			withIcon?: false
			tooltipText?: undefined
	  }
	| {
			withIcon: true
			tooltipText: string
	  }

function BetaBadge({ withIcon, tooltipText, ...restProps }: Props) {
	return (
		<StyledBadge withIcon={withIcon} {...restProps}>
			{withIcon && (
				<HoverableItem position={{ top: '50px', left: '50px' }} title={tooltipText}>
					<InfoIcon size={18} />
				</HoverableItem>
			)}
			BETA
		</StyledBadge>
	)
}

export default BetaBadge
