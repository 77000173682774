import createSvgIcon from './createSvgIcon'

const PaperClip = createSvgIcon(
	<path d="M16.4135639,10.7874662 L10.0026264,17.1937162 C8.17145722,19.0242381 5.20307017,19.0237134 3.37254828,17.1925443 C1.54202638,15.3613752 1.54255102,12.3929881 3.3737201,10.5624662 L11.0424701,2.91480993 C12.2633214,1.69460584 14.2421878,1.69513053 15.4623919,2.91598184 C16.682596,4.13683316 16.6820714,6.11569956 15.4612201,7.33590368 L15.4479389,7.34840368 L7.96356385,14.5530912 C7.72213046,14.7853138 7.37514751,14.8710932 7.05332012,14.7781171 C6.73149273,14.6851409 6.48371413,14.4275346 6.40332012,14.1023358 C6.32292611,13.7771371 6.42213046,13.4337513 6.66356385,13.2015287 L14.1409076,6.00465368 C14.6276085,5.51493244 14.6251601,4.72338587 14.1354389,4.23668493 C13.6457176,3.749984 12.854171,3.75243244 12.3674701,4.24215368 L4.6987201,11.8874662 C3.98823607,12.5982293 3.71090938,13.6340403 3.9712063,14.6047191 C4.23150322,15.5753979 4.98987847,16.3334753 5.96065943,16.5933909 C6.93144038,16.8533066 7.96714232,16.5755731 8.67762635,15.8648099 L15.0885639,9.45855993 C15.3258105,9.22131325 15.6716051,9.12865788 15.9956901,9.21549619 C16.3197751,9.3023345 16.5729143,9.5554737 16.6597526,9.87955869 C16.7465909,10.2036437 16.6539355,10.5494383 16.4166889,10.7866849 L16.4135639,10.7874662 Z" />,
	{
		size: 20,
		viewBox: '0 0 20 20',
	},
)

export default PaperClip
