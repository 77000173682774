import createSvgIcon from './createSvgIcon'

const Chat = createSvgIcon(
	<path d="M17.3125,2 L3.5625,2 C2.69955508,2 2,2.69955508 2,3.5625 L2,13.5625 C2,14.4254449 2.69955508,15.125 3.5625,15.125 L8.03359375,15.125 L9.09765625,16.8664063 C9.37938001,17.3358706 9.88627591,17.6236288 10.4337825,17.6249119 C10.9812891,17.6261865 11.4895237,17.3407994 11.7734375,16.8726563 L12.8414063,15.125 L17.3125,15.125 C18.1754449,15.125 18.875,14.4254449 18.875,13.5625 L18.875,3.5625 C18.875,2.69955508 18.1754449,2 17.3125,2 L17.3125,2 Z M17,13.25 L12.6664062,13.25 C12.1202051,13.2500424 11.6136741,13.5352993 11.3304688,14.0023438 L10.4375,15.4632813 L9.54609375,14.0046875 C9.26207246,13.5374067 8.75541831,13.2515259 8.20859375,13.25 L3.875,13.25 L3.875,3.875 L17,3.875 L17,13.25 Z" />,
	{
		size: 20,
		viewBox: '0 0 20 20',
	},
)

export default Chat
