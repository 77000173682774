import createSvgIcon from './createSvgIcon'

const Website = createSvgIcon(
	<>
		<defs>
			<linearGradient x1="12.1965307%" y1="50.9073446%" x2="89.5685973%" y2="50.9073618%" id="OW-website-gradient-1">
				<stop stopColor="#E3E3E3" offset="0%"></stop>
				<stop stopColor="#E3E3E3" stopOpacity="0" offset="50.1044627%"></stop>
				<stop stopColor="#E3E3E3" offset="100%"></stop>
			</linearGradient>
			<linearGradient x1="50%" y1="1.36283803%" x2="50%" y2="62.2079485%" id="OW-website-gradient-2">
				<stop stopColor="#FAFAFA" offset="0%"></stop>
				<stop stopColor="#E3E3E3" stopOpacity="0" offset="100%"></stop>
			</linearGradient>
		</defs>
		<g id="TestWebsite" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
			<rect id="Rectangle" fill="#E3E3E3" x="1" y="0" width="910" height="910"></rect>
			<rect
				id="Rectangle-Copy-3"
				fill="#DADADA"
				opacity="0.544270833"
				x="321"
				y="335"
				width="259"
				height="22"
				rx="11"
			></rect>
			<rect
				id="Rectangle-Copy-4"
				fill="#DADADA"
				opacity="0.544270833"
				x="336"
				y="373"
				width="230"
				height="22"
				rx="11"
			></rect>
			<rect id="Rectangle-Copy" fillOpacity="0.4" fill="#CACACA" x="278" y="116" width="346" height="44" rx="22"></rect>
			<rect
				id="Rectangle-Copy-2"
				fillOpacity="0.4"
				fill="#CACACA"
				x="353"
				y="176"
				width="195"
				height="44"
				rx="22"
			></rect>
			<rect id="Rectangle-Copy-7" fill="#DADADA" x="104" y="646" width="307" height="30" rx="15"></rect>
			<g id="Group" transform="translate(381.000000, 450.000000)">
				<rect id="Rectangle-Copy-5" fill="#DADADA" x="0" y="0" width="140" height="59" rx="8"></rect>
				<rect id="Rectangle-Copy-6" fill="#CCCCCC" x="16" y="21" width="109" height="17" rx="8.5"></rect>
			</g>
			<rect id="Rectangle-Copy-8" fill="#DADADA" x="105" y="708" width="210" height="210" rx="24"></rect>
			<rect id="Rectangle-Copy-9" fill="#DADADA" x="345" y="708" width="210" height="210" rx="24"></rect>
			<rect id="Rectangle-Copy-10" fill="#DADADA" x="585" y="708" width="210" height="210" rx="24"></rect>
			<rect id="Rectangle" fill="url(#OW-website-gradient-1)" x="0" y="0" width="900" height="303"></rect>
			<rect id="Rectangle-Copy-11" fill="url(#OW-website-gradient-2)" x="0" y="0" width="900" height="418"></rect>
		</g>
	</>,
	{
		width: 910,
		height: 910,
		viewBox: '0 0 910 710',
	},
)

export default Website
