import React from 'react'

import { config } from '../../lib/config'
import { scrollToPreviewElement } from '../../lib/helpers'

type PreviewScreen = 'Homescreen' | 'FAQ' | 'Contact' | 'Chat'
type PreviewController = {
	setCurrentView: (view: PreviewScreen) => void
	setVisibilityState: (state: 'maximized' | 'minimized') => void
}

export const PreviewControllerContext = React.createContext<
	{ get: () => PreviewController | null; set: (controller: PreviewController) => void } | undefined
>(undefined)

export const PreviewControllerProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
	const controllerRef = React.useRef<PreviewController | null>(null)
	const contextValue = React.useMemo(
		() => ({
			get() {
				return controllerRef.current
			},
			set: (controller: PreviewController) => {
				controllerRef.current = controller
			},
		}),
		[],
	)

	return <PreviewControllerContext.Provider value={contextValue}>{children}</PreviewControllerContext.Provider>
}

export const usePreviewController = () => {
	const previewControllerCtx = React.useContext(PreviewControllerContext)
	const lastScrollTime = React.useRef<number | null>(null)

	return React.useMemo(
		() => ({
			showView: (view: PreviewScreen, elementTestId?: string) => {
				previewControllerCtx?.get()?.setVisibilityState('maximized')
				previewControllerCtx?.get()?.setCurrentView(view)
				if (elementTestId) {
					// throttle scroll to prevent scroll glitches while typing
					if (!lastScrollTime.current || Date.now() - lastScrollTime.current > (config.isTest ? 0 : 500)) {
						lastScrollTime.current = Date.now()
						scrollToPreviewElement(elementTestId)
					}
				}
			},
			maximize: () => {
				previewControllerCtx?.get()?.setVisibilityState('maximized')
			},
			minimize: () => {
				previewControllerCtx?.get()?.setVisibilityState('minimized')
			},
		}),
		[previewControllerCtx],
	)
}
