import createSvgIcon from './createSvgIcon'

const CheckCircle = createSvgIcon(
	<path d="M12,2 C17.5228475,2 22,6.4771525 22,12 C22,17.5228475 17.5228475,22 12,22 C6.4771525,22 2,17.5228475 2,12 C2,6.4771525 6.4771525,2 12,2 Z M12,4 C7.581722,4 4,7.581722 4,12 C4,16.418278 7.581722,20 12,20 C16.418278,20 20,16.418278 20,12 C20,7.581722 16.418278,4 12,4 Z M16.8485514,9.0597295 C17.2297773,9.4593359 17.2148769,10.0923255 16.8152705,10.4735514 L11.3121455,15.7235514 C10.9255373,16.0923769 10.3172825,16.0921179 9.93098857,15.7229633 L7.18411357,13.0979633 C6.78483196,12.7163973 6.77047066,12.0833952 7.1520367,11.6841136 C7.53360274,11.284832 8.16660483,11.2704707 8.56588643,11.6520367 L10.622,13.617 L15.4347295,9.0264486 C15.8343359,8.64522273 16.4673255,8.66012309 16.8485514,9.0597295 Z" />,
	{
		size: 24,
		viewBox: '0 0 24 24',
	},
)

export default CheckCircle
