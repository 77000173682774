import createSvgIcon from './createSvgIcon'

const UserAvatarSmall = createSvgIcon(
	<path d="M8,1 C10.7614237,1 13,3.23857625 13,6 C13,7.4846623 12.3529153,8.81818986 11.3253903,9.73393827 C12.7878536,10.4150364 14.023923,11.5449051 14.8655089,12.9991064 C15.1421448,13.4771138 14.9789009,14.088873 14.5008936,14.3655089 C14.0228862,14.6421448 13.411127,14.4789009 13.1344911,14.0008936 C12.058936,12.1424099 10.1506038,11 8,11 C5.84939616,11 3.941064,12.1424099 2.86550889,14.0008936 C2.58887302,14.4789009 1.97711381,14.6421448 1.49910644,14.3655089 C1.02109908,14.088873 0.857855234,13.4771138 1.13449111,12.9991064 C1.97607701,11.5449051 3.21214644,10.4150364 4.67485635,9.73272168 C3.64708471,8.81818986 3,7.4846623 3,6 C3,3.23857625 5.23857625,1 8,1 Z M8,3 C6.34314575,3 5,4.34314575 5,6 C5,7.65685425 6.34314575,9 8,9 C9.65685425,9 11,7.65685425 11,6 C11,4.34314575 9.65685425,3 8,3 Z" />,
	{
		size: 16,
		viewBox: '0 0 16 16',
	},
)

export default UserAvatarSmall
