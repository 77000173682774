import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { m } from 'framer-motion'
import Link from 'next/link'
import { useRouter } from 'next/router'
import React from 'react'

import { CheckCircleIcon, WarningCircleIcon, WarningTriangleIcon } from './icons'

export type ToastKind = 'success' | 'warning' | 'error'

const StyledToast = styled(m.div)<{ kind: ToastKind; lowerPosition: boolean }>`
	position: fixed;
	top: ${({ lowerPosition }) => (lowerPosition ? '100px' : '50px')};
	left: 50%;
	translate: -50%;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	gap: ${({ theme }) => theme.spaces.space2};
	padding: ${({ theme }) => theme.spaces.space4};
	padding-right: ${({ theme }) => theme.spaces.space5};
	border-radius: ${({ theme }) => theme.borderRadiuses.lg};
	z-index: ${({ theme }) => theme.zIndexes.floating};
	${({ theme }) => theme.typography.captionMedium};

	${({ kind, theme }) => css`
		color: ${theme.colors.light.toast[kind].foreground};
		background-color: ${theme.colors.light.toast[kind].background};
	`};

	${({ theme, lowerPosition }) =>
		theme.isMobile &&
		css`
			top: ${lowerPosition ? '90px' : '30px'};
			min-width: max-content;
		`};
`

const ColorResetLink = styled(Link)`
	color: inherit;
`

const FormattedMessage = React.memo(function FormattedMessage({ message }: { message: string }) {
	const linkRegex = /\[(.*?)\]\((.*?)\)/g
	const parts = message.split(linkRegex)
	const nodes: React.ReactNode[] = parts.map((part, index, array) => {
		if (index % 3 === 0) {
			return part
		} else if (index % 3 === 1) {
			return (
				<ColorResetLink key={index} href={array[index + 1]} target="_blank" rel="noopener">
					{part}
				</ColorResetLink>
			)
		}
	})

	return <span>{nodes}</span>
})

type Props = {
	message: string
	kind: ToastKind
}

function Toast({ message, kind }: Props) {
	const router = useRouter()
	const lowerPosition = router.route.startsWith('/hello')

	const Icon = {
		success: CheckCircleIcon,
		warning: WarningTriangleIcon,
		error: WarningCircleIcon,
	}[kind]

	return (
		<StyledToast
			kind={kind}
			lowerPosition={lowerPosition}
			key="toast"
			initial={{ y: lowerPosition ? -170 : -110 }}
			animate={{ y: 0 }}
			exit={{ y: lowerPosition ? -170 : -110 }}
			transition={{ ease: [0.14, 0, 0, 1], duration: 0.3 }}
			data-testid="toast"
		>
			<Icon />
			<FormattedMessage message={message} />
		</StyledToast>
	)
}

export default Toast
