import createSvgIcon from './createSvgIcon'

const NavPalette = createSvgIcon(
	<g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
		<path
			d="M16.0000001,25 C11.0294373,25 7.00000013,20.9705627 7.00000013,16 C7.00000013,11.0294373 11.0294373,7 16.0000001,7 C20.97,7 25.0000001,10.582 25.0000001,15 C25.0000001,16.06 24.526,17.078 23.682,17.828 C22.838,18.578 21.693,19 20.5000001,19 L18.0000001,19 C17.0820195,18.9852078 16.2718666,19.5973151 16.0353066,20.4844151 C15.7987466,21.3715151 16.1965436,22.3057423 17.0000001,22.75 C17.4093845,23.1277564 17.5325996,23.7243525 17.3063635,24.2333838 C17.0801273,24.742415 16.5547264,25.0507373 16.0000001,25"
			stroke="#FFFFFF"
			strokeWidth="2"
		/>
		<path
			d="M11.5,14.5 C11.5,15.0522847 11.9477153,15.5 12.5,15.5 C13.0522847,15.5 13.5,15.0522847 13.5,14.5 C13.5,13.9477153 13.0522847,13.5 12.5,13.5 C11.9477153,13.5 11.5,13.9477153 11.5,14.5"
			stroke="#FFFFFF"
			strokeWidth="2"
		/>
		<path
			d="M15.5,11.5 C15.5,12.0522847 15.9477153,12.5 16.5,12.5 C17.0522847,12.5 17.5,12.0522847 17.5,11.5 C17.5,10.9477153 17.0522847,10.5 16.5,10.5 C15.9477153,10.5 15.5,10.9477153 15.5,11.5"
			stroke="#FFFFFF"
			strokeWidth="2"
		/>
		<path
			d="M19.5,14.5 C19.5,15.0522847 19.9477153,15.5 20.5,15.5 C21.0522847,15.5 21.5,15.0522847 21.5,14.5 C21.5,13.9477153 21.0522847,13.5 20.5,13.5 C19.9477153,13.5 19.5,13.9477153 19.5,14.5"
			stroke="#FFFFFF"
			strokeWidth="2"
		/>
	</g>,
	{
		size: 32,
		viewBox: '0 0 32 32',
	},
)

export default NavPalette
