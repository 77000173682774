import useSWR from 'swr'
import type { SWRConfiguration } from 'swr'

import { config } from 'lib/config'
import { Installation } from 'types/installation'

export function useInstallations(options?: SWRConfiguration) {
	const { data, isLoading } = useSWR<Installation[]>(`${config.apiURL}/v1.0/installations`, options)

	return {
		installations: data,
		areInstallationsLoading: isLoading,
	}
}
