import React from 'react'

import NameBadge from '../NameBadge'

type Props = {
	name: string
	email: string
}

function DefaultAvatar({ name, email }: Props) {
	const colorIndex = React.useMemo(() => {
		const text = name || email || ''
		return text.split('').reduce((sum, letter) => sum + letter.charCodeAt(0), 0)
	}, [name, email])

	return <NameBadge colorIndex={colorIndex} textForInitials={name || email || ''} />
}

export default DefaultAvatar
