import useSWR, { SWRConfiguration } from 'swr'

import { config } from '../lib/config'
import { store } from '../lib/store'
import { Template } from '../types/template'
import useTemplateId from './useTemplateId'
import { useUser } from './useUser'

export const useTemplate = (options?: SWRConfiguration) => {
	const user = useUser()
	const { templateId, setUrlQueryTemplateId } = useTemplateId()
	const { data, mutate, isLoading, error } = useSWR<Template>(`${config.apiURL}/v1.0/templates/${templateId}`, options)

	if (error) {
		if (error?.status === 404 && templateId !== user.organizationId) {
			setUrlQueryTemplateId(user.organizationId)
			store.dispatch({
				type: 'toast/set',
				payload: {
					kind: 'error',
					timeout: 6000,
					message: "Chosen template doesn't exist. Changed to main automatically.",
				},
			})
		} else {
			store.dispatch({
				type: 'toast/set',
				payload: { kind: 'error', timeout: 6000, message: 'Something went wrong' },
			})
		}
	}

	return {
		template: data,
		mutateTemplate: mutate,
		isTemplateLoading: isLoading,
	}
}
