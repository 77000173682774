import createSvgIcon from './createSvgIcon'

const NavCode = createSvgIcon(
	<g strokeWidth="1" fill="#FFFFFF" fillRule="evenodd">
		<path
			d="M10.2928932,11.2928932 C10.6834175,10.9023689 11.3165825,10.9023689 11.7071068,11.2928932 C12.0976311,11.6834175 12.0976311,12.3165825 11.7071068,12.7071068 L8.415,16 L11.7071068,19.2928932 C12.0675907,19.6533772 12.0953203,20.2206082 11.7902954,20.6128994 L11.7071068,20.7071068 C11.3165825,21.0976311 10.6834175,21.0976311 10.2928932,20.7071068 L6.29289322,16.7071068 C5.90236893,16.3165825 5.90236893,15.6834175 6.29289322,15.2928932 L10.2928932,11.2928932 Z M20.2928932,11.2928932 C20.6834175,10.9023689 21.3165825,10.9023689 21.7071068,11.2928932 L25.7071068,15.2928932 C26.0976311,15.6834175 26.0976311,16.3165825 25.7071068,16.7071068 L21.7071068,20.7071068 C21.3165825,21.0976311 20.6834175,21.0976311 20.2928932,20.7071068 C19.9023689,20.3165825 19.9023689,19.6834175 20.2928932,19.2928932 L23.585,16 L20.2928932,12.7071068 C19.9324093,12.3466228 19.9046797,11.7793918 20.2097046,11.3871006 L20.2928932,11.2928932 Z M17.0298575,7.75746437 C17.1638062,7.22166947 17.7067407,6.89590877 18.2425356,7.0298575 C18.7783305,7.16380623 19.1040912,7.70674072 18.9701425,8.24253563 L14.9701425,24.2425356 C14.8361938,24.7783305 14.2932593,25.1040912 13.7574644,24.9701425 C13.2216695,24.8361938 12.8959088,24.2932593 13.0298575,23.7574644 L17.0298575,7.75746437 Z"
			stroke="#FFFFFF"
			fillRule="nonzero"
		/>
	</g>,
	{
		size: 32,
		viewBox: '0 0 32 32',
	},
)

export default NavCode
