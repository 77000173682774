import { useInstallations } from './useInstallations'
import { useOrganization } from './useOrganization'
import type { Organization } from 'types/organization'

const OFFER_SUPPORT_ROUTE = { route: '/onboarding/offer-support', title: 'Set up contact form' } as const
const PROVIDE_SELF_HELP_ROUTE = { route: '/onboarding/provide-self-help', title: 'Add FAQ' } as const
const BOOST_REVENUE_ROUTE = { route: '/onboarding/custom-links', title: 'Guide users' } as const
const CUSTOMIZE_ROUTE = { route: '/onboarding/customize', title: 'Customize style' } as const
const ADD_TO_WEBSITE_ROUTE = { route: '/onboarding/add-to-website', title: 'Add to website' } as const
const GOOGLE_REVIEWS_ROUTE = { route: '/onboarding/google-reviews', title: 'Set up Google Reviews' } as const
const WHATSAPP_WIDGET_ROUTE = { route: '/onboarding/whatsapp-widget', title: 'Set up WhatsApp Widget' } as const

type Route = {
	route: string
	title: string
}

const getCustomRouteFromActivationSource = (tracking: Organization['tracking']): Route | null => {
	if (
		tracking?.accounts_source_url?.includes('google-reviews') ||
		tracking?.accounts_utm_source?.includes('google-reviews')
	) {
		return GOOGLE_REVIEWS_ROUTE
	}

	if (
		tracking?.accounts_source_url?.includes('whatsapp-chat-widget') ||
		tracking?.accounts_utm_source?.includes('whatsapp-chat-widget')
	) {
		return WHATSAPP_WIDGET_ROUTE
	}

	return null
}

export function useOnboardingRoutes() {
	const { organization } = useOrganization()
	const { installations } = useInstallations()

	if (!organization || !installations) {
		return { areRoutesLoading: true, routes: [] }
	}

	const customRoute = getCustomRouteFromActivationSource(organization.tracking)
	const hasCustomRoute = customRoute !== null

	const routes: Route[] = [
		OFFER_SUPPORT_ROUTE,
		customRoute ?? PROVIDE_SELF_HELP_ROUTE,
		hasCustomRoute ? PROVIDE_SELF_HELP_ROUTE : BOOST_REVENUE_ROUTE,
		CUSTOMIZE_ROUTE,
	]

	if (installations.length === 0) {
		routes.push(ADD_TO_WEBSITE_ROUTE)
	}

	return { areRoutesLoading: false, routes }
}
