import createSvgIcon from './createSvgIcon'

const EmptyArchive = createSvgIcon(
	<>
		<defs>
			<linearGradient x1="77.7169351%" y1="16.0159664%" x2="50%" y2="83.9840336%" id="linearGradient-1">
				<stop stopColor="#DCDCDC" offset="0%"></stop>
				<stop stopColor="#858585" offset="100%"></stop>
			</linearGradient>
			<linearGradient x1="50%" y1="-2.0469737e-14%" x2="50%" y2="100%" id="linearGradient-2">
				<stop stopColor="#E0E0E0" offset="0%"></stop>
				<stop stopColor="#C6C6C6" offset="100%"></stop>
			</linearGradient>
			<linearGradient x1="80.0223629%" y1="19.9686957%" x2="50%" y2="84.0456746%" id="linearGradient-3">
				<stop stopColor="#E7E7E7" offset="0%"></stop>
				<stop stopColor="#A7A7A7" offset="100%"></stop>
			</linearGradient>
			<linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="linearGradient-4">
				<stop stopColor="#F3F3F3" offset="0%"></stop>
				<stop stopColor="#C6C6C6" offset="100%"></stop>
			</linearGradient>
			<linearGradient x1="80.0223629%" y1="20.1424075%" x2="50%" y2="83.8487422%" id="linearGradient-5">
				<stop stopColor="#E8E8E8" offset="0%"></stop>
				<stop stopColor="#D6D6D6" offset="100%"></stop>
			</linearGradient>
			<linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="linearGradient-6">
				<stop stopColor="#FFFFFF" offset="0%"></stop>
				<stop stopColor="#C6C6C6" offset="100%"></stop>
			</linearGradient>
			<linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="linearGradient-7">
				<stop stopColor="#FFFFFF" offset="0%"></stop>
				<stop stopColor="#EDEDED" offset="100%"></stop>
			</linearGradient>
			<linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="linearGradient-8">
				<stop stopColor="#FAFAFA" stopOpacity="0" offset="0%"></stop>
				<stop stopColor="#FFFFFF" offset="100%"></stop>
			</linearGradient>
		</defs>
		<g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
			<g transform="translate(0.5, 0)">
				<rect fill="#FFFFFF" x="0.5" y="0" width="360" height="220"></rect>
				<g transform="translate(114.5, 73)">
					<path
						d="M16,0 L115,0 C123.836556,-1.623249e-15 131,7.163444 131,16 L131,108 L131,108 L0,108 L0,16 C-1.082166e-15,7.163444 7.163444,1.082166e-15 16,0 Z"
						fill="url(#linearGradient-1)"
					></path>
					<path
						d="M1,108 L1,13.2923077 C1,5.95116886 6.93931117,0 14.2658228,0 L116.734177,0 C124.060689,0 130,5.95116886 130,13.2923077 L130,108"
						stroke="url(#linearGradient-2)"
					></path>
				</g>
				<g transform="translate(108.5, 79)">
					<path
						d="M16,0 L127,0 C135.836556,-1.623249e-15 143,7.163444 143,16 L143,118 L143,118 L0,118 L0,16 C-1.082166e-15,7.163444 7.163444,1.082166e-15 16,0 Z"
						fill="url(#linearGradient-3)"
					></path>
					<path
						d="M0,118 L0,14.5230769 C0,6.50220302 6.48337021,0 14.4810127,0 L128.518987,0 C136.51663,0 143,6.50220302 143,14.5230769 L143,118"
						stroke="url(#linearGradient-4)"
					></path>
				</g>
				<g transform="translate(101.5, 87)">
					<path
						d="M16,0 L142,0 C150.836556,-1.623249e-15 158,7.163444 158,16 L158,130 L158,130 L0,130 L0,16 C-1.082166e-15,7.163444 7.163444,1.082166e-15 16,0 Z"
						fill="url(#linearGradient-5)"
					></path>
					<path
						d="M0,130 L0,16 C0,7.163444 7.163444,0 16,0 L142,0 C150.836556,0 158,7.163444 158,16 L158,130"
						stroke="url(#linearGradient-6)"
					></path>
					<path
						d="M92.5,102 L140.5,102 C144.918278,102 148.5,105.581722 148.5,110 L148.5,130 L148.5,130 L84.5,130 L84.5,110 C84.5,105.581722 88.081722,102 92.5,102 Z"
						fill="url(#linearGradient-7)"
					></path>
					<rect fill="#FFFFFF" x="63.5" y="10" width="85" height="34" rx="8"></rect>
					<rect fill="#CDCDCD" x="23" y="64" width="61" height="7" rx="3.5"></rect>
					<rect fill="#BCBCBC" x="7" y="53" width="10" height="10" rx="5"></rect>
					<rect fill="#CDCDCD" x="23" y="75" width="28" height="7" rx="3.5"></rect>
					<rect fill="#CDCDCD" x="58" y="75" width="34" height="7" rx="3.5"></rect>
				</g>
				<rect fill="url(#linearGradient-8)" x="0" y="135" width="361" height="85"></rect>
			</g>
		</g>
	</>,
	{
		width: 362,
		height: 220,
		viewBox: '0 0 362 220',
	},
)

export default EmptyArchive
