import useSWR from 'swr'
import type { SWRConfiguration } from 'swr'

import { buildQueryString } from '@livechat/url-utils'

import { config } from 'lib/config'

export const WEBSITE_SCREENSHOT_WIDTH = 1400
const apiFlash = 'https://api.apiflash.com/v1/urltoimage'

const query = (organiationWebsite: string) =>
	buildQueryString({
		url: organiationWebsite,
		scale_factor: '2',
		format: 'jpeg',
		quality: '50',
		width: WEBSITE_SCREENSHOT_WIDTH,
		height: '910',
		no_cookie_banners: 'false',
		no_ads: 'true',
		response_type: 'json',
		user_agent: 'Chrome/117.0.0.0',
		access_key: config.apiflashKey ?? '',
	})

const defaultSWROptions: SWRConfiguration = {
	revalidateIfStale: false,
	revalidateOnFocus: false,
}

export function useCaptureScreenshot(previewUrl: string, options?: SWRConfiguration) {
	const { data, isLoading, error } = useSWR<{ url: string }>(`${apiFlash}?${query(previewUrl)}`, {
		...defaultSWROptions,
		...options,
	})

	return {
		screenshotUrl: data?.url,
		isScreenshotUrlLoading: isLoading,
		error,
	}
}
