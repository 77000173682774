import createSvgIcon from './createSvgIcon'

const WarningTriangle = createSvgIcon(
	<path d="M12.0052827,1.99976262 C12.8951964,1.99976262 13.7190964,2.47035109 14.1618368,3.2323833 L22.4169161,17.2767021 C22.8672723,18.0449973 22.8681253,18.9934588 22.4191448,19.7625504 C21.9730856,20.5266381 21.1515483,20.9966751 20.2597552,20.9997626 L3.74734145,20.9997626 C2.85901719,20.9966751 2.03747986,20.5266381 1.59142063,19.7625504 C1.14244013,18.9934588 1.14329313,18.0449973 1.59425621,17.2756683 L9.84618282,3.2367396 C10.2914691,2.47035109 11.115369,1.99976262 12.0052827,1.99976262 Z M12.0052827,3.99976262 C11.8254588,3.99976262 11.6615282,4.09339522 11.5729341,4.24585034 L3.31906852,18.2881015 C3.23417137,18.4329337 3.23401279,18.6092643 3.31864239,18.7542325 C3.40619325,18.9042048 3.57211656,18.9991367 3.75081031,18.9997626 L20.2562863,18.9997626 C20.4384489,18.9991367 20.6043722,18.9042048 20.6919231,18.7542325 C20.7765527,18.6092643 20.7763941,18.4329337 20.6921038,18.2891354 L12.4350855,4.24149404 C12.3490373,4.09339522 12.1851066,3.99976262 12.0052827,3.99976262 Z M12,14 C12.8284271,14 13.5,14.6715729 13.5,15.5 C13.5,16.3284271 12.8284271,17 12,17 C11.1715729,17 10.5,16.3284271 10.5,15.5 C10.5,14.6715729 11.1715729,14 12,14 Z M12.003125,7.75 C12.5554097,7.75 13.003125,8.19771525 13.003125,8.75 L13.003125,11.75 C13.003125,12.3022847 12.5554097,12.75 12.003125,12.75 C11.4508403,12.75 11.003125,12.3022847 11.003125,11.75 L11.003125,8.75 C11.003125,8.19771525 11.4508403,7.75 12.003125,7.75 Z" />,
	{
		size: 24,
		viewBox: '0 0 24 24',
	},
)

export default WarningTriangle
