import createSvgIcon from './createSvgIcon'

const WhatsApp = createSvgIcon(
	<>
		<path d="M18.8787458,10.4282217 C18.8793706,13.3672973 17.3505453,16.0949211 14.8431467,17.6282569 C12.3357481,19.1615926 9.21112071,19.2796692 6.59515184,17.9399404 L4.06155809,18.7875967 C3.49895287,18.9772056 2.87749557,18.8315861 2.45768835,18.4117789 C2.03788114,17.9919717 1.89226165,17.3705144 2.08187059,16.8079092 L2.92640184,14.2743154 C1.33822951,11.1634093 1.83103322,7.39555364 4.16585761,4.79774239 C6.500682,2.19993113 10.1947782,1.30928831 13.4569176,2.55767734 C16.7190569,3.80606637 18.8747587,6.93537005 18.8787458,10.4282217 L18.8787458,10.4282217 Z M17.0037459,10.4282217 C17.002692,7.66935587 15.2762408,5.2057327 12.683394,4.26313677 C10.0905473,3.32054084 7.18501818,4.10026746 5.41246331,6.21436083 C3.63990843,8.3284542 3.37886429,11.3254408 4.75921434,13.7141592 C4.89386558,13.9466333 4.92231309,14.2257034 4.83733934,14.4805654 L4.06155809,16.8079092 L6.38890184,16.0321279 C6.4846406,16.0002403 6.58486752,15.9838875 6.68577684,15.9836904 C6.85042987,15.9839826 7.01210201,16.0276341 7.15452684,16.1102529 C9.18511266,17.2852197 11.688473,17.2868457 13.7205834,16.1145177 C15.7526938,14.9421897 17.004425,12.774245 17.0037459,10.4282217 Z"></path>
		<path d="M14.4075157,12.5096805 C14.1801436,13.6346171 13.1925948,14.4442393 12.0449124,14.4466136 C8.94131133,14.4432936 6.42617363,11.9281559 6.42285368,8.82455481 C6.42522797,7.67687248 7.23485019,6.68932361 8.35978678,6.46195155 C8.71434314,6.39178987 9.07209027,6.56710239 9.23388306,6.89029888 L10.0022311,8.42967212 C10.1304981,8.68706826 10.1113523,8.99340129 9.95203413,9.23282337 L9.38179675,10.0881795 C9.67539126,10.7003791 10.1690881,11.194076 10.7812878,11.4876705 L11.6366439,10.9174331 C11.876066,10.758115 12.182399,10.7389692 12.4397951,10.8672362 L13.9791684,11.6355842 C14.3023649,11.797377 14.4776774,12.1551241 14.4075157,12.5096805 Z"></path>
	</>,
	{
		size: 20,
		viewBox: '0 0 20 20',
	},
)

export default WhatsApp
