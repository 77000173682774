import { useTheme as useAppTheme } from '@emotion/react'
import React from 'react'

import FeatureAccessGuard from '../components/FeatureAccessGuard'
import { useTemplate } from '../hooks/useTemplate'
import DefaultLayout from './DefaultLayout'
import { WidgetContainer } from './components/SharedStyledComponents'
import SuccessStep from './components/SuccessStep'
import WidgetPreview from 'components/WidgetPreview'
import { useAppInstances } from 'hooks/useAppInstances'
import { useApps } from 'hooks/useApps'
import { useFeature } from 'hooks/useFeature'
import { useHeader } from 'hooks/useHeader'
import { useIntegration } from 'hooks/useIntegration'
import { useOrganization } from 'hooks/useOrganization'
import { useTheme } from 'hooks/useTheme'
import { useToast } from 'hooks/useToast'
import { useUser } from 'hooks/useUser'
import { updateTemplate } from 'lib/api'
import { useAppSelector } from 'lib/store'

function ConfiguratorLayout({ children }: { children: React.ReactNode }) {
	const { header } = useHeader()
	const { theme } = useTheme()
	const { apps } = useApps()
	const { appInstances } = useAppInstances()
	const { feature: forms } = useFeature('forms')
	const { feature: visitorCounter } = useFeature('visitor_counter')
	const { feature: productRecommendations } = useFeature('product_recommendations')
	const { feature: googleReviews } = useFeature('google_reviews')
	const { feature: faq } = useFeature('faq')
	const { feature: productCards } = useFeature('product_cards')
	const { feature: whatsapp } = useFeature('whatsapp')
	const { feature: messenger } = useFeature('messenger')
	const { feature: openaiFeature } = useFeature('openai_integration')
	const { feature: linksFeature } = useFeature('links')
	const { feature: chatbotFeature } = useFeature('chatbot_integration')
	const { integration: livechatIntegration } = useIntegration('livechat')
	const { integration: chatbotIntegration } = useIntegration('chatbot')
	const { integration: openaiIntegration } = useIntegration('openai')
	const user = useUser()
	const showToast = useToast()
	const { organization } = useOrganization()
	const appTheme = useAppTheme()
	const isSuccessStepActive = useAppSelector((state) => state.currentModal === 'SuccessStepModal')
	const { template, mutateTemplate } = useTemplate()
	const templateLanguage = template?.language ?? 'en'
	const isWidgetEnabled = template?.isWidgetEnabled ?? true

	async function enableWidgetVisibility() {
		try {
			const updatedTemplate = await updateTemplate(user.organizationId, { isWidgetEnabled: true })
			mutateTemplate(updatedTemplate)
			showToast('success')
		} catch {
			showToast('error')
		}
	}

	const rightColumn = appTheme.isMobile ? (
		<WidgetPreview
			organizationId={user.organizationId}
			theme={theme}
			faq={faq}
			forms={forms}
			apps={apps}
			appInstances={appInstances}
			header={header}
			visitorCounter={visitorCounter}
			productRecommendations={productRecommendations}
			googleReviews={googleReviews}
			productCards={productCards}
			livechatIntegration={livechatIntegration}
			chatbotIntegration={chatbotIntegration}
			chatbotFeature={chatbotFeature}
			openaiIntegration={openaiIntegration}
			openaiFeature={openaiFeature}
			templateLanguage={templateLanguage}
			isChatOnlyModeEnabled={template?.isChatOnlyModeEnabled ?? false}
			whatsapp={whatsapp}
			messenger={messenger}
			links={linksFeature}
			isWidgetEnabled={isWidgetEnabled}
			enableWidgetVisibility={enableWidgetVisibility}
		/>
	) : (
		<WidgetContainer isSuccessStepVisible={isSuccessStepActive}>
			<SuccessStep />
			<WidgetPreview
				organizationId={user.organizationId}
				theme={theme}
				faq={faq}
				forms={forms}
				header={header}
				apps={apps}
				appInstances={appInstances}
				previewUrl={organization?.website}
				visitorCounter={visitorCounter}
				productRecommendations={productRecommendations}
				googleReviews={googleReviews}
				productCards={productCards}
				livechatIntegration={livechatIntegration}
				chatbotIntegration={chatbotIntegration}
				chatbotFeature={chatbotFeature}
				openaiIntegration={openaiIntegration}
				openaiFeature={openaiFeature}
				templateLanguage={templateLanguage}
				isChatOnlyModeEnabled={template?.isChatOnlyModeEnabled ?? false}
				whatsapp={whatsapp}
				messenger={messenger}
				links={linksFeature}
				isWidgetEnabled={isWidgetEnabled}
				enableWidgetVisibility={enableWidgetVisibility}
			/>
		</WidgetContainer>
	)

	return <DefaultLayout leftColumn={<FeatureAccessGuard>{children}</FeatureAccessGuard>} rightColumn={rightColumn} />
}

export default ConfiguratorLayout
