import useSWR, { SWRConfiguration } from 'swr'

import { config } from 'lib/config'
import { store } from 'lib/store'
import type { Thread } from 'types/assistants'

export function useOpenAiThreadMeta(threadId: string | undefined, options?: SWRConfiguration) {
	const { data, mutate, isLoading, error } = useSWR<Thread>(
		threadId ? `${config.apiURL}/v1.0/integrations/openai/threads/${threadId}/meta` : null,
		options,
	)

	if (error) {
		store.dispatch({
			type: 'toast/set',
			payload: { kind: 'error', timeout: 6000, message: 'Something went wrong' },
		})
	}

	return {
		thread: data,
		mutateThread: mutate,
		isThreadLoading: isLoading,
	}
}
