import createSvgIcon from './createSvgIcon'

const Notification = createSvgIcon(
	<path d="M9,3 C9.55228475,3 10,3.44771525 10,4 C10,4.55228475 9.55228475,5 9,5 L4,5 L4,16 L15,16 L15,11 C15,10.4871642 15.3860402,10.0644928 15.8833789,10.0067277 L16,10 C16.5522847,10 17,10.4477153 17,11 L17,17 C17,17.5522847 16.5522847,18 16,18 L3,18 C2.44771525,18 2,17.5522847 2,17 L2,4 C2,3.44771525 2.44771525,3 3,3 L9,3 Z M15,2 C16.6568542,2 18,3.34314575 18,5 C18,6.65685425 16.6568542,8 15,8 C13.3431458,8 12,6.65685425 12,5 C12,3.34314575 13.3431458,2 15,2 Z M15,4 C14.4477153,4 14,4.44771525 14,5 C14,5.55228475 14.4477153,6 15,6 C15.5522847,6 16,5.55228475 16,5 C16,4.44771525 15.5522847,4 15,4 Z" />,
	{
		size: 20,
		viewBox: '0 0 20 20',
	},
)

export default Notification
