import createSvgIcon from './createSvgIcon'

const AppsLogo = createSvgIcon(
	<path d="M5.5226086601655835,0.962563651436346 a4.025280201352217,4.025280201352217 0 1 0 4.025280201352217,4.025280201352217 A4.025280201352217,4.025280201352217 0 0 0 5.5226086601655835,0.962563651436346 zm0,6.440448322163547 a2.4151681208113303,2.4151681208113303 0 1 1 2.4151681208113303,-2.4151681208113303 A2.4151681208113303,2.4151681208113303 0 0 1 5.5226086601655835,7.403011973599893 zm9.660672483245321,1.6101120805408868 a4.025280201352217,4.025280201352217 0 1 0 -4.025280201352217,-4.025280201352217 A4.025280201352217,4.025280201352217 0 0 0 15.183281143410904,9.013124054140782 zm0,-6.440448322163547 a2.4151681208113303,2.4151681208113303 0 1 1 -2.4151681208113303,2.4151681208113303 A2.4151681208113303,2.4151681208113303 0 0 1 15.183281143410904,2.572675731977233 zM5.5226086601655835,10.623236134681669 a4.025280201352217,4.025280201352217 0 1 0 4.025280201352217,4.025280201352217 A4.025280201352217,4.025280201352217 0 0 0 5.5226086601655835,10.623236134681669 zm0,6.440448322163547 a2.4151681208113303,2.4151681208113303 0 1 1 2.4151681208113303,-2.4151681208113303 A2.4151681208113303,2.4151681208113303 0 0 1 5.5226086601655835,17.063684456845216 zm13.685952684597538,-2.4151681208113303 a0.8050560402704434,0.8050560402704434 0 0 1 -0.8050560402704434,0.8050560402704434 H15.988337183681347 v2.4151681208113303 a0.8050560402704434,0.8050560402704434 0 0 1 -1.6101120805408868,0 V15.45357237630433 H11.963056982329132 a0.8050560402704434,0.8050560402704434 0 0 1 0,-1.6101120805408868 h2.4151681208113303 V11.428292174952112 a0.8050560402704434,0.8050560402704434 0 0 1 1.6101120805408868,0 v2.4151681208113303 h2.4151681208113303 A0.8050560402704434,0.8050560402704434 0 0 1 19.20856134476312,14.648516336033886 z" />,
	{
		size: 20,
		viewBox: '0 0 20 20',
	},
)

export default AppsLogo
