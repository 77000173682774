import { useTheme } from '@emotion/react'
import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { m } from 'framer-motion'
import React from 'react'
import useOnClickOutside from 'use-onclickoutside'

import { DarkBackground } from 'layouts/components/SharedStyledComponents'
import { config } from 'lib/config'

const ModalInner = styled(m.div)`
	border-radius: ${({ theme }) => theme.borderRadiuses.xl};
	display: flex;
	flex-direction: column;
	padding: ${({ theme }) => theme.spaces.space7};
	max-width: ${({ theme }) => (theme.isMobile ? '90%' : '400px')};
	opacity: ${config.isTest ? 1 : 0};
	background-color: ${({ theme }) => theme.colors.light.primaryBackgroundColor};
	${({ theme }) =>
		theme.isMobile &&
		css`
			margin-bottom: ${theme.spaces.space7};
			margin-left: ${theme.spaces.space3};
			margin-right: ${theme.spaces.space3};
		`};
	z-index: ${({ theme }) => theme.zIndexes.modal};
	position: relative;
`

const MobileScreenOuter = styled.div`
	height: 100%;
	width: 100%;
	z-index: ${({ theme }) => theme.zIndexes.modal};
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	position: fixed;
`

const Backdrop = styled(DarkBackground)`
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: ${({ theme }) => theme.zIndexes.backdrop};
	pointer-events: fill;
`

type Props = {
	hideModal: () => void
	children: React.ReactNode
	withoutBackdrop?: boolean
}

const AnimatedBackdrop = ({ children }: { children: React.ReactNode }) => (
	<Backdrop
		initial={{ opacity: 0 }}
		animate={{ opacity: 1 }}
		exit={{ opacity: 0 }}
		transition={{ duration: 0.5, ease: 'easeOut' }}
	>
		{children}
	</Backdrop>
)

type ModalContextType = Pick<Props, 'hideModal'> | undefined

const ModalContext = React.createContext<ModalContextType>(undefined)

export const useModal = () => {
	const value = React.useContext(ModalContext)

	if (typeof value === 'undefined') {
		throw new Error("The 'useModal' hook can only be used inside of a Modal component")
	}

	return value
}

const Modal = ({ hideModal, children, withoutBackdrop = false, ...restProps }: Props) => {
	const modalRef = React.useRef<HTMLDivElement>(null)
	const theme = useTheme()
	const animationVariants = {
		initial: { opacity: 0, y: 60 },
		animate: { opacity: 1, y: 0 },
		transition: { delay: 0.15, duration: 0.3, ease: theme.transitions.easings.motion.speedySwift },
	}
	useOnClickOutside(modalRef, hideModal)

	const contextValue = React.useMemo(() => {
		return {
			hideModal,
		}
	}, [hideModal])

	const Wrapper = withoutBackdrop ? React.Fragment : AnimatedBackdrop

	if (theme.isMobile) {
		return (
			<Wrapper>
				<MobileScreenOuter>
					<ModalInner ref={modalRef} {...animationVariants} {...restProps}>
						<ModalContext.Provider value={contextValue}>{children}</ModalContext.Provider>
					</ModalInner>
				</MobileScreenOuter>
			</Wrapper>
		)
	}

	return (
		<Wrapper>
			<ModalInner ref={modalRef} {...animationVariants} {...restProps}>
				<ModalContext.Provider value={contextValue}>{children}</ModalContext.Provider>
			</ModalInner>
		</Wrapper>
	)
}

export default Modal
