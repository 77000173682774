import createSvgIcon from './createSvgIcon'

const HelpDeskLogo = createSvgIcon(
	<path d="M4.498,17.781 C6.309,17.927 8.125,18 9.941,18 C11.755,18 13.57,17.927 15.384,17.806 C17.72,17.611 19.559,15.62 19.733,13.094 L19.748,12.892 C19.838,11.671 19.882,11.06 19.882,9.793 L19.882,7.342 L16.652,10.086 C16.629,11.249 16.679,11.555 16.601,12.549 L16.575,12.893 C16.5,13.841 15.904,14.618 15.183,14.666 C11.703,14.958 8.226,14.94 4.747,14.648 C4.026,14.6 3.43,13.823 3.355,12.876 L3.333,12.59 C3.248,11.502 3.206,10.932 3.206,9.767 L3.207,9.741 C3.231,8.487 3.256,7.208 3.355,5.93 C3.43,5.007 4.001,4.254 4.697,4.157 L15.004,4.157 L10.094,8.371 L7.981,6.473 L5.568,8.544 L10.064,12.582 L19.882,4.157 L19.882,4.157 L19.882,1 L4.498,1 C2.162,1.194 0.323,3.186 0.149,5.711 C0.05,7.061 0.001,8.414 1.77635684e-15,9.767 C1.77635684e-15,11.044 0.045,11.68 0.136,12.899 L0.149,13.07 C0.323,15.596 2.162,17.587 4.498,17.781 Z" />,
	{
		size: 20,
		viewBox: '0 0 20 20',
		color: '#2FC774',
	},
)

export default HelpDeskLogo
