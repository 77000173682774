export const config = {
	isDev: process.env.NODE_ENV === 'development',
	isProd: process.env.NODE_ENV === 'production',
	clientId: process.env.NEXT_PUBLIC_CLIENT_ID ?? '',
	apiURL: process.env.NEXT_PUBLIC_API_URL ?? '',
	appURL: process.env.NEXT_PUBLIC_APP_URL ?? '',
	accountsURL: process.env.NEXT_PUBLIC_ACCOUNTS_URL ?? '',
	billingURL: process.env.NEXT_PUBLIC_BILLING_URL ?? '',
	isTest: typeof window !== 'undefined' && 'Cypress' in window,
	amplitudeKey: process.env.NEXT_PUBLIC_AMPLITUDE_KEY ?? '',
	apiflashKey: process.env.NEXT_PUBLIC_API_FLASH_KEY ?? '',
	googleClientId: process.env.NEXT_PUBLIC_GOOGLE_CLIENT_ID ?? '',
} as const
