import { css } from '@emotion/react'
import styled from '@emotion/styled'

const Fieldset = styled.fieldset<{ disabled?: boolean; fullWidth?: boolean }>`
	position: relative;
	display: flex;
	flex-direction: column;
	border: none;
	margin: 1px;
	padding-left: 0;
	padding-right: 0;
	will-change: opacity;
	opacity: ${({ disabled }) => (disabled ? '0.55' : '1.0')};
	transition: opacity 200ms ${({ theme }) => theme.transitions.easings.css.swift};
	gap: ${({ theme }) => theme.spaces.space4};
	${({ fullWidth }) =>
		fullWidth &&
		css`
			width: 100%;
		`}

	${({ theme }) =>
		theme.isMobile &&
		css`
			padding-top: 0;
		`}
`

export default Fieldset
