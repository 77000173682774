import createSvgIcon from './createSvgIcon'

const ArrowForward = createSvgIcon(
	<path d="M14.7269184,9.40162618 C14.9017369,9.57753419 15,9.81648417 15,10.0656887 C15,10.3148932 14.9017369,10.5538432 14.7269184,10.7297512 L9.15613525,16.3547512 C8.7929186,16.7215028 8.20402808,16.7215028 7.84081145,16.3547512 C7.47759481,15.9879996 7.4775948,15.3933778 7.84081144,15.0266262 L11.8262426,11.0024074 L3,11 C2.48722357,11 2,10.517767 2,10 C2,9.48223305 2.48722357,9 3,9 L11.8977787,9 L7.84235888,5.10318868 C7.47914224,4.73643709 7.47914225,4.14181528 7.84235889,3.77506369 C8.20557552,3.40831211 8.79446604,3.4083121 9.15768269,3.77506368 L14.7269184,9.40162618 Z M17,2 C16.4477153,2 16,2.42980664 16,2.96 L16,17.04 C16,17.5701934 16.4477153,18 17,18 C17.5522847,18 18,17.5701934 18,17.04 L18,2.96 C18,2.42980664 17.5522847,2 17,2 L17,2 Z" />,
	{
		size: 20,
		viewBox: '0 0 20 20',
	},
)

export default ArrowForward
