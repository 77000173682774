import { css, keyframes, useTheme } from '@emotion/react'
import styled from '@emotion/styled'
import { m } from 'framer-motion'
import React from 'react'

import { WEBSITE_SCREENSHOT_WIDTH, useCaptureScreenshot } from 'hooks/useCaptureScreenshot'
import { Alignment } from 'types/theme'

const websitePreviewEntry = keyframes`
	from {
		opacity: 0;
	}

	to {
		opacity: 1;
	}
`

const WebsiteScreenshot = styled(m.img)<{ isScreenshotReady: boolean; screenPosition?: Alignment['screenPosition'] }>`
	height: 910px;
	width: ${WEBSITE_SCREENSHOT_WIDTH}px;
	position: absolute;

	${({ screenPosition }) =>
		screenPosition &&
		css`
			transform-origin: top ${screenPosition};
			bottom: 0;
		`}

	${({ isScreenshotReady }) =>
		isScreenshotReady &&
		css`
			animation: ${websitePreviewEntry} 200ms ease-in-out forwards;
		`}
`

type Props = {
	previewUrl: string
	screenPosition?: Alignment['screenPosition']
	setIsLoaded: (isLoaded: boolean) => void
	setIsLoading: (isLoading: boolean) => void
	getScreenshotOffsetForRightAlignment: () => number
}

const WebsitePreview = ({
	screenPosition,
	previewUrl,
	setIsLoaded,
	setIsLoading,
	getScreenshotOffsetForRightAlignment,
}: Props) => {
	const theme = useTheme()
	const { screenshotUrl, isScreenshotUrlLoading, error } = useCaptureScreenshot(previewUrl, {
		onErrorRetry: (error, _, config, revalidate) => {
			if (error.status === 400) {
				return
			}

			setTimeout(() => revalidate({ retryCount: config.errorRetryCount }), config.errorRetryInterval)
		},
	})

	React.useEffect(() => {
		if (isScreenshotUrlLoading) {
			setIsLoading(true)
		} else if (error) {
			setIsLoading(false)
			setIsLoaded(false)
		}
	}, [isScreenshotUrlLoading, setIsLoading, setIsLoaded, error])

	if (!screenshotUrl) {
		return null
	}

	const onWebsitePreviewLoaded = () => {
		setIsLoaded(true)
		setIsLoading(false)
	}

	return (
		<WebsiteScreenshot
			data-testid="customer-website-screenshot"
			screenPosition={screenPosition}
			src={screenshotUrl}
			onLoad={onWebsitePreviewLoaded}
			aria-hidden
			isScreenshotReady={!isScreenshotUrlLoading}
			animate={
				screenPosition && {
					x: screenPosition === 'left' ? 0 : getScreenshotOffsetForRightAlignment(),
				}
			}
			transition={{ duration: 0.5, ease: theme.transitions.easings.motion.speedySwift }}
		/>
	)
}

export default WebsitePreview
