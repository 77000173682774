import styled from '@emotion/styled'

export const Foreground = styled.main`
	width: 100vw;
	height: 100vh;
	display: flex;
	overflow: hidden;

	/* Avoid Chrome to see Safari hack */
	/* https://github.com/postcss/postcss-100vh-fix */
	@supports (-webkit-touch-callout: none) {
		/* The hack for Safari */
		height: -webkit-fill-available;
	}
`
