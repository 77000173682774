import createSvgIcon from './createSvgIcon'

const Article = createSvgIcon(
	<path d="M20.25,3.5 C21.1681734,3.5 21.9211923,4.20711027 21.9941988,5.10647279 L22,5.25 L22,18.75 C22,19.6681734 21.2928897,20.4211923 20.3935272,20.4941988 L20.25,20.5 L3.75,20.5 C2.8318266,20.5 2.07880766,19.7928897 2.0058012,18.8935272 L2,18.75 L2,5.25 C2,4.3318266 2.70711027,3.57880766 3.60647279,3.5058012 L3.75,3.5 L20.25,3.5 Z M20,5.5 L4,5.5 L4,18.5 L20,18.5 L20,5.5 Z M16.875,14.378125 C17.4272847,14.378125 17.875,14.8258403 17.875,15.378125 C17.875,15.8909608 17.4889598,16.3136322 16.9916211,16.3713973 L16.875,16.378125 L7.125,16.378125 C6.57271525,16.378125 6.125,15.9304097 6.125,15.378125 C6.125,14.8652892 6.51104019,14.4426178 7.00837887,14.3848527 L7.125,14.378125 L16.875,14.378125 Z M16.875,11.003125 C17.4272847,11.003125 17.875,11.4508403 17.875,12.003125 C17.875,12.5159608 17.4889598,12.9386322 16.9916211,12.9963973 L16.875,13.003125 L7.125,13.003125 C6.57271525,13.003125 6.125,12.5554097 6.125,12.003125 C6.125,11.4902892 6.51104019,11.0676178 7.00837887,11.0098527 L7.125,11.003125 L16.875,11.003125 Z M16.875,7.628125 C17.4272847,7.628125 17.875,8.07584025 17.875,8.628125 C17.875,9.14096084 17.4889598,9.56363216 16.9916211,9.62139727 L16.875,9.628125 L7.125,9.628125 C6.57271525,9.628125 6.125,9.18040975 6.125,8.628125 C6.125,8.11528916 6.51104019,7.69261784 7.00837887,7.63485273 L7.125,7.628125 L16.875,7.628125 Z" />,
	{
		size: 24,
		viewBox: '0 0 24 24',
	},
)

export default Article
