import { useRouter } from 'next/router'
import { useEffect } from 'react'

type Props = {
	to: string
	shallow?: boolean
}

function Redirect({ to, shallow }: Props) {
	const router = useRouter()

	useEffect(() => {
		if (router.pathname !== to) {
			router.push(to, undefined, { shallow })
		}
	}, [router, to, shallow])

	return null
}

export default Redirect
