import createSvgIcon from './createSvgIcon'

const UserAvatar = createSvgIcon(
	<path d="M12 2a7 7 0 0 1 3.34 13.153c2.7.863 5.034 2.755 6.482 5.361a1 1 0 0 1-1.749.972c-1.67-3.008-4.748-4.854-8.073-4.854s-6.403 1.846-8.073 4.854a1 1 0 0 1-1.749-.972c1.448-2.606 3.782-4.499 6.482-5.361A7 7 0 0 1 12 2Zm0 2a5 5 0 1 0 0 10 5 5 0 0 0 0-10Z" />,
	{
		size: 24,
		viewBox: '0 0 24 24',
	},
)

export default UserAvatar
