import { css } from '@emotion/react'
import styled from '@emotion/styled'

const Label = styled.label<{ required?: boolean; compact?: boolean }>`
	${({ theme }) => theme.typography.paragraphMedium};

	${({ compact, theme }) => compact && theme.typography.paragraph};

	cursor: pointer;
	flex: 1;

	${({ required, theme }) =>
		required &&
		css`
			&::after {
				content: '*';
				margin-left: 4px;
				color: ${theme.colorPalette.red[500]};
			}
		`}
`

export default Label
