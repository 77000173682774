import createSvgIcon from './createSvgIcon'

const OffsetSide = createSvgIcon(
	<path d="M4 17.25a1 1 0 0 1-2 0v-14a1 1 0 1 1 2 0v14Zm9.707-11.957 4 4a1 1 0 0 1 0 1.414l-4 4a1 1 0 0 1-1.414-1.414L14.584 11H7a1 1 0 0 1 0-2h7.584l-2.291-2.293a1 1 0 0 1-.083-1.32l.083-.094a1 1 0 0 1 1.414 0Z" />,
	{
		size: 20,
		viewBox: '0 0 20 20',
	},
)

export default OffsetSide
