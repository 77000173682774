import createSvgIcon from './createSvgIcon'

const Repeat = createSvgIcon(
	<path d="M2.00073928,9.81492883 C2.00418412,6.53716609 4.72223726,4.00344484 8,4 L15,4 L14.725,3.85078125 C14.4877533,3.61353457 14.3950979,3.26774 14.4819363,2.94365501 C14.5687746,2.61957001 14.8219138,2.36643082 15.1459988,2.27959251 C15.4700837,2.19275419 15.8158783,2.28540957 16.053125,2.52265625 L17.928125,4.39765625 C18.1046449,4.57356426 18.2038643,4.81251424 18.2038643,5.06171875 C18.2038643,5.31092326 18.1046449,5.54987324 17.928125,5.72578125 L16.053125,7.60078125 C15.6863734,7.96753282 15.0917516,7.96753281 14.725,7.60078122 C14.3582484,7.23402964 14.3582484,6.63940785 14.725,6.27265625 L15,6 L8,6 C5.75741404,6.00258313 4.00258313,7.75741404 4,10 C4,10.5177669 3.51776695,11 3,11 C2.48223305,11 2,10.5177669 2,10 L2.00073928,9.81492883 Z M17,9 C16.482233,9 16,9.48223305 16,10 C15.9974169,12.242586 14.242586,13.9974169 12,14 L5,14.125 L5.275,13.8507812 C5.64175159,13.4840297 5.64175159,12.8894078 5.275,12.5226562 C4.90824841,12.1559047 4.31362659,12.1559047 3.946875,12.5226562 L2.071875,14.3976563 C1.89535514,14.5735643 1.79613572,14.8125142 1.79613572,15.0617188 C1.79613572,15.3109233 1.89535514,15.5498732 2.071875,15.7257813 L3.946875,17.6007812 C4.31362659,17.9675328 4.90824841,17.9675328 5.275,17.6007812 C5.64175159,17.2340297 5.64175159,16.6394078 5.275,16.2726563 L5,16 L12,16 C15.2777627,15.9965552 17.9965552,13.2777627 18,10 C18,9.48223305 17.517767,9 17,9 Z" />,
	{
		size: 20,
		viewBox: '0 0 20 20',
	},
)

export default Repeat
