import createSvgIcon from './createSvgIcon'

const Trash = createSvgIcon(
	<path d="M11.6,1 C12.8402832,1 13.9075236,1.87048168 13.994307,3.0451094 L14,3.2 L14,4.5 L17.5,4.5 C18.0522847,4.5 18.5,4.94771525 18.5,5.5 C18.5,6.01283584 18.1139598,6.43550716 17.6166211,6.49327227 L17.5,6.5 L17,6.5 L17,17.3846154 C17,18.2474994 16.3149544,18.9252642 15.4747187,18.9942176 L15.3333333,19 L4.66666667,19 C3.81082303,19 3.08073299,18.3676502 3.00625216,17.5266427 L3,17.3846154 L3,6.5 L2.5,6.5 C1.94771525,6.5 1.5,6.05228475 1.5,5.5 C1.5,4.98716416 1.88604019,4.56449284 2.38337887,4.50672773 L2.5,4.5 L6,4.5 L6,3.2 C6,2 7.01556196,1.0795135 8.23927163,1.00489002 L8.4,1 L11.6,1 Z M15,6.5 L5,6.5 L5,17 L15,17 L15,6.5 Z M12,8 C12.5128358,8 12.9355072,8.38604019 12.9932723,8.88337887 L13,9 L13,14 C13,14.5522847 12.5522847,15 12,15 C11.4871642,15 11.0644928,14.6139598 11.0067277,14.1166211 L11,14 L11,9 C11,8.44771525 11.4477153,8 12,8 Z M8,8 C8.51283584,8 8.93550716,8.38604019 8.99327227,8.88337887 L9,9 L9,14 C9,14.5522847 8.55228475,15 8,15 C7.48716416,15 7.06449284,14.6139598 7.00672773,14.1166211 L7,14 L7,9 C7,8.44771525 7.44771525,8 8,8 Z M11.6,3 L8.4,3 C8.1838382,3 8.04436193,3.09308229 8.00892304,3.16583993 L8,3.2 L8,4.5 L12,4.5 L12,3.2 C12,3.13716743 11.891404,3.03510906 11.7015638,3.00723183 L11.6,3 Z" />,
	{
		size: 20,
		viewBox: '0 0 20 20',
	},
)

export default Trash
