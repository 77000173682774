import * as React from 'react'
import { SerializedStyles, css } from '@emotion/react'

export type Props = JSX.IntrinsicElements['svg'] & {
	size?: number
	alt?: string
	css?: SerializedStyles
}

const svgIconStyles = (size: Props['size']) => css`
	display: inline-block;
	flex-shrink: 0;
	width: ${size}px;
	height: ${size}px;
	user-select: none;
	fill: currentColor;
`

const SvgIcon: React.FC<Props> = ({ size, color = 'inherit', alt, children, ...restProps }) => {
	const a11yProps = alt ? { alt } : { 'aria-hidden': true }
	return (
		<svg color={color} css={svgIconStyles(size)} {...restProps} {...a11yProps}>
			{children}
		</svg>
	)
}

export default SvgIcon
