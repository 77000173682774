import { Theme, css } from '@emotion/react'
import styled from '@emotion/styled'
import React from 'react'
import { useEffectOnce } from 'react-use'

import { noop } from '@livechat/data-utils'

import { LogoutIcon, OpenInNewIcon, QuestionIcon, UserAvatarSmallIcon } from 'components/icons'
import { useTracking } from 'hooks/useTracking'
import { useUser } from 'hooks/useUser'
import { config } from 'lib/config'
import { EVENT_NAMES } from 'lib/tracking'
import { formControlReset } from 'styles/reset'

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
`

const Section = styled.div`
	width: 100%;
	overflow-wrap: break-word;
	padding-bottom: ${({ theme }) => theme.spaces.space5};
	border-bottom: 1px solid ${({ theme }) => theme.colors.light.surfaceDecorative};
`

const Name = styled.p`
	margin: ${({ theme }) => theme.spaces.space2} 0;
	line-height: 1.15;
	${({ theme }) => theme.typography.captionMedium};
`

const Email = styled.p`
	margin: 0;
	line-height: 1.15;
	${({ theme }) => theme.typography.caption};
`

const ButtonsSection = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	padding-top: ${({ theme }) => theme.spaces.space5};
	gap: ${({ theme }) => theme.spaces.space2};
`

const InteractiveElementStyles = (theme: Theme) => css`
	width: 100%;
	${theme.typography.caption};
	padding: ${theme.spaces.space3};
	border-radius: ${theme.borderRadiuses.md};
	background-color: ${theme.colors.light.surface};
	display: flex;
	align-items: center;
	gap: 6px;
	cursor: pointer;
	transition: background-color 100ms ease;

	@media (hover: hover) {
		&:hover,
		&:focus {
			background-color: ${theme.colors.light.surfaceVariant};
		}
	}
`

const Button = styled.button`
	${formControlReset};
	${({ theme }) => InteractiveElementStyles(theme)};
	color: inherit;
`

const Link = styled.a`
	${({ theme }) => InteractiveElementStyles(theme)};
	text-decoration: none;
	color: inherit;
`

type Props = {
	onLogout: () => void
	onKeyUp?: (event: React.KeyboardEvent<HTMLButtonElement | HTMLAnchorElement>) => void
	onHelpCenterOpen?: () => void
}

function UserContent({ onLogout, onKeyUp = noop, onHelpCenterOpen = noop }: Props) {
	const hasLoggedRef = React.useRef(false)
	const track = useTracking()
	const { name, email, accountId } = useUser()

	useEffectOnce(() => {
		if (hasLoggedRef.current) {
			return
		}
		hasLoggedRef.current = true
		track(EVENT_NAMES.USER_PROFILE_OPENED)
	})

	const handleHelpCenterOpen = () => {
		track(EVENT_NAMES.USER_PROFILE_HELP_CENTER_CLICKED)
		onHelpCenterOpen()
	}

	return (
		<Wrapper>
			<Section>
				<Name>{name}</Name>
				<Email>{email}</Email>
			</Section>
			<ButtonsSection>
				<Link
					href="https://help.openwidget.com"
					target="_blank"
					rel="noopener, noreferrer"
					role="menuitem"
					onClick={handleHelpCenterOpen}
					onKeyUp={onKeyUp}
				>
					<QuestionIcon />
					Help Center
				</Link>
				<Button role="menuitem" onClick={onLogout} onKeyUp={onKeyUp}>
					<LogoutIcon />
					Log out
				</Button>
				<Link
					href={`${config.accountsURL}/panel/accounts/${accountId}`}
					target="_blank"
					rel="noopener, noreferrer"
					role="menuitem"
					onClick={handleHelpCenterOpen}
					onKeyUp={onKeyUp}
				>
					<UserAvatarSmallIcon />
					Edit profile
				</Link>
				<Link
					href={`${config.accountsURL}/panel/accounts`}
					target="_blank"
					rel="noopener, noreferrer"
					role="menuitem"
					onClick={handleHelpCenterOpen}
					onKeyUp={onKeyUp}
				>
					<OpenInNewIcon />
					Go to Text Accounts
				</Link>
			</ButtonsSection>
		</Wrapper>
	)
}

export default UserContent
