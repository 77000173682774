import createSvgIcon from './createSvgIcon'

const Box = createSvgIcon(
	<path d="M16.0625,3.95603835 L9.18750004,0.192757103 C8.72052698,-0.0642523677 8.15447311,-0.0642523677 7.68750004,0.192757103 L0.812500042,3.95603835 C0.311417435,4.23020714 0,4.75594712 0,5.3271321 L0,12.7974446 C0,13.3686296 0.311417435,13.8943696 0.812500042,14.1685384 L7.68750004,17.9318196 C8.15439723,18.1891241 8.72060285,18.1891241 9.18750004,17.9318196 L16.0625,14.1685384 C16.5635826,13.8943696 16.8750001,13.3686296 16.8750001,12.7974446 L16.8750001,5.3271321 C16.8750001,4.75594712 16.5635826,4.23020714 16.0625,3.95603835 L16.0625,3.95603835 Z M8.43750004,1.9193196 L14.0625,4.99978835 L8.43750004,8.07791335 L2.81250004,4.99978835 L8.43750004,1.9193196 Z M1.87500004,6.6240071 L7.50000004,9.7021321 L7.50000004,15.6919759 L1.87500004,12.6122884 L1.87500004,6.6240071 Z M9.37500004,15.6919759 L9.37500004,9.7021321 L15,6.6240071 L15,12.6122884 L9.37500004,15.6919759 Z" />,
	{
		size: 20,
		viewBox: '0 0 20 20',
	},
)

export default Box
