import createSvgIcon from './createSvgIcon'

const Dice = createSvgIcon(
	<path d="M15,2 C16.6568542,2 18,3.34314575 18,5 L18,15 C18,16.6568542 16.6568542,18 15,18 L5,18 C3.34314575,18 2,16.6568542 2,15 L2,5 C2,3.34314575 3.34314575,2 5,2 L15,2 Z M15,4 L5,4 C4.44771525,4 4,4.44771525 4,5 L4,15 C4,15.5522847 4.44771525,16 5,16 L15,16 C15.5522847,16 16,15.5522847 16,15 L16,5 C16,4.44771525 15.5522847,4 15,4 Z M13,12 C13.5522847,12 14,12.4477153 14,13 C14,13.5522847 13.5522847,14 13,14 C12.4477153,14 12,13.5522847 12,13 C12,12.4477153 12.4477153,12 13,12 Z M7,12 C7.55228475,12 8,12.4477153 8,13 C8,13.5522847 7.55228475,14 7,14 C6.44771525,14 6,13.5522847 6,13 C6,12.4477153 6.44771525,12 7,12 Z M7,6 C7.55228475,6 8,6.44771525 8,7 C8,7.55228475 7.55228475,8 7,8 C6.44771525,8 6,7.55228475 6,7 C6,6.44771525 6.44771525,6 7,6 Z M13,6 C13.5522847,6 14,6.44771525 14,7 C14,7.55228475 13.5522847,8 13,8 C12.4477153,8 12,7.55228475 12,7 C12,6.44771525 12.4477153,6 13,6 Z" />,
	{
		size: 20,
		viewBox: '0 0 20 20',
	},
)

export default Dice
