import createSvgIcon from './createSvgIcon'

const WarningCircle = createSvgIcon(
	<path d="M12,2 C17.5228475,2 22,6.4771525 22,12 C22,17.5228475 17.5228475,22 12,22 C6.4771525,22 2,17.5228475 2,12 C2,6.4771525 6.4771525,2 12,2 Z M12,4 C7.581722,4 4,7.581722 4,12 C4,16.418278 7.581722,20 12,20 C16.418278,20 20,16.418278 20,12 C20,7.581722 16.418278,4 12,4 Z M12,14.625 C12.8284271,14.625 13.5,15.2965729 13.5,16.125 C13.5,16.9534271 12.8284271,17.625 12,17.625 C11.1715729,17.625 10.5,16.9534271 10.5,16.125 C10.5,15.2965729 11.1715729,14.625 12,14.625 Z M12.003125,6.5 C12.5554097,6.5 13.003125,6.94771525 13.003125,7.5 L13.003125,12.375 C13.003125,12.9272847 12.5554097,13.375 12.003125,13.375 C11.4508403,13.375 11.003125,12.9272847 11.003125,12.375 L11.003125,7.5 C11.003125,6.94771525 11.4508403,6.5 12.003125,6.5 Z" />,
	{
		size: 24,
		viewBox: '0 0 24 24',
	},
)

export default WarningCircle
