import createSvgIcon from './createSvgIcon'

const VariantDark = createSvgIcon(
	<>
		<defs>
			<linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="darkTheme-linearGradient-1">
				<stop stopColor="#343434" offset="0%"></stop>
				<stop stopColor="#1C1C1C" offset="100%"></stop>
			</linearGradient>
			<linearGradient x1="80.0223629%" y1="18.8799112%" x2="50%" y2="85.28%" id="darkTheme-linearGradient-2">
				<stop stopColor="#555353" offset="0%"></stop>
				<stop stopColor="#1C1C1C" offset="100%"></stop>
			</linearGradient>
			<linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="darkTheme-linearGradient-3">
				<stop stopColor="#737171" offset="0%"></stop>
				<stop stopColor="#282828" offset="100%"></stop>
			</linearGradient>
			<linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="darkTheme-linearGradient-4">
				<stop stopColor="#444343" offset="0%"></stop>
				<stop stopColor="#2C2C2C" offset="100%"></stop>
			</linearGradient>
		</defs>
		<g id="Icons-playground" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
			<g id="Group-8">
				<rect
					id="Rectangle-Copy-43"
					fill="url(#darkTheme-linearGradient-1)"
					x="0"
					y="0"
					width="180"
					height="120"
				></rect>
				<path
					d="M56,36 L124,36 C132.836556,36 140,43.163444 140,52 L140,120 L140,120 L40,120 L40,52 C40,43.163444 47.163444,36 56,36 Z"
					id="Rectangle-Copy-42"
					fill="url(#darkTheme-linearGradient-2)"
				></path>
				<path
					d="M40,120 L40,52 C40,43.163444 47.163444,36 56,36 L124,36 C132.836556,36 140,43.163444 140,52 L140,120 L140,120"
					id="Path-Copy-8"
					stroke="url(#darkTheme-linearGradient-3)"
				></path>
				<path
					d="M55.5,99 L124.5,99 C128.918278,99 132.5,102.581722 132.5,107 L132.5,120 L132.5,120 L47.5,120 L47.5,107 C47.5,102.581722 51.081722,99 55.5,99 Z"
					id="Rectangle-Copy-41"
					fill="url(#darkTheme-linearGradient-4)"
				></path>
				<circle id="Oval-Copy-8" fill="#606060" cx="59.5" cy="56.5" r="10.5"></circle>
				<rect id="Rectangle-Copy-40" fill="#606060" x="49" y="74" width="61" height="7" rx="3.5"></rect>
				<rect id="Rectangle-Copy-39" fill="#606060" x="49" y="85" width="28" height="7" rx="3.5"></rect>
				<path
					d="M65.7212806,58.1302784 C65.3175025,59.4483862 64.5082849,60.6055675 63.4087806,61.4371534 C61.4398543,62.9190348 58.8022403,63.1600656 56.5973334,62.0595981 C54.3924265,60.9591306 52.9994338,58.7064267 53,56.2421534 C52.9956866,54.8319371 53.4541302,53.4592411 54.3050306,52.3346534 C55.1366165,51.2351491 56.2937978,50.4259314 57.6119056,50.0221534 C57.7889444,49.9676403 57.981614,50.0154739 58.1125995,50.1464595 C58.2435851,50.277445 58.2914187,50.4701145 58.2369056,50.6471534 C57.6496435,52.5897085 58.1787574,54.69782 59.6137482,56.1328108 C61.048739,57.5678016 63.1568505,58.0969155 65.0994056,57.5096534 C65.2764444,57.4551403 65.469114,57.5029739 65.6000995,57.6339595 C65.7310851,57.764945 65.7789187,57.9576145 65.7244056,58.1346534 L65.7212806,58.1302784 Z"
					id="Path"
					fill="#272727"
					fillRule="nonzero"
				></path>
			</g>
		</g>
	</>,
	{
		viewBox: '0 0 180 120',
	},
)

export default VariantDark
