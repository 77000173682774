import createSvgIcon from './createSvgIcon'

const NavHelpFilled = createSvgIcon(
	<g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
		<path
			d="M16,6 C21.5228475,6 26,10.4771525 26,16 C26,21.5228475 21.5228475,26 16,26 C10.4771525,26 6,21.5228475 6,16 C6,10.4771525 10.4771525,6 16,6 Z M16,19 C15.4871642,19 15.0644928,19.3860402 15.0067277,19.8833789 L15,20.01 C15,20.5622847 15.4477153,21.01 16,21.01 C16.5128358,21.01 16.9355072,20.6239598 16.9932723,20.1266211 L17,20 C17,19.4477153 16.5522847,19 16,19 Z M17.3679944,12.3269955 C16.1370945,11.6964507 14.6316609,11.9976622 13.7367304,13.0549198 C13.3799102,13.476462 13.4323777,14.1074493 13.8539198,14.4642696 C14.2430357,14.7936421 14.8106137,14.7742724 15.1762797,14.4377892 L15.2632696,14.3470802 C15.5575756,13.9993914 16.052651,13.9003353 16.4580776,14.1080196 C16.8724695,14.3191677 17.0867725,14.7849511 16.9780286,15.2349175 C16.8783466,15.6473875 16.5311017,15.9477211 16.11735,15.9938441 L16.0029957,16.0000136 C15.4507134,15.9983592 15.001659,16.4447312 15.0000045,16.9970134 C14.99835,17.5492957 15.444722,17.9983502 15.9970043,18.0000318 C17.3857552,18.0041649 18.5958329,17.0546298 18.9220636,15.7047346 C19.2482952,14.3548352 18.6053862,12.957485 17.3679944,12.3269955 Z"
			fill="#FFFFFF"
		/>
	</g>,
	{
		size: 32,
		viewBox: '0 0 32 32',
	},
)

export default NavHelpFilled
