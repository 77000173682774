import createSvgIcon from './createSvgIcon'

const NavArticleFilled = createSvgIcon(
	<g stroke="none" strokeWidth="1" fill="#FFFFFF" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
		<path
			d="M7,10 C7,8.8954305 7.8954305,8 9,8 L23,8 C24.1045695,8 25,8.8954305 25,10 L25,22 C25,23.1045695 24.1045695,24 23,24 L9,24 C7.8954305,24 7,23.1045695 7,22 L7,10 Z"
			stroke="#FFFFFF"
			strokeWidth="2"
		/>
		<line x1="11" y1="12" x2="21" y2="12" stroke="#000" strokeWidth="2" />
		<line x1="11" y1="16" x2="21" y2="16" stroke="#000" strokeWidth="2" />
		<line x1="11" y1="20" x2="21" y2="20" stroke="#000" strokeWidth="2" />
	</g>,
	{
		size: 32,
		viewBox: '0 0 32 32',
	},
)

export default NavArticleFilled
