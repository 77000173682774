import React from 'react'

import { fromPairs } from '@livechat/data-utils'

import { usePublicOrganizationEntities } from './usePublicOrganizationEntities'
import type { Props } from 'components/WidgetPreview/Widget'
import { Feature, FeatureName } from 'types/feature'

export function usePublicWidgetPreviewProps(organizationId?: string, templateId?: string): Props {
	const { organizationEntities } = usePublicOrganizationEntities(organizationId, templateId)

	const features = React.useMemo(
		() =>
			organizationEntities &&
			(fromPairs(organizationEntities.features.map((feature) => [feature.name, feature])) as {
				[F in FeatureName]: Feature<F>
			}),
		[organizationEntities],
	)

	const props = React.useMemo((): Props | null => {
		if (!organizationEntities || !features) {
			return null
		}

		return {
			organizationId,
			theme: organizationEntities.theme,
			header: organizationEntities.header,
			templateLanguage: organizationEntities.language,
			isChatOnlyModeEnabled: organizationEntities.isChatOnlyModeEnabled,
			previewUrl: organizationEntities.website,
			faq: features.faq,
			visitorCounter: features.visitor_counter,
			productRecommendations: features.product_recommendations,
			googleReviews: features.google_reviews,
			forms: features.forms,
			productCards: features.product_cards,
			links: features.links,
			openaiFeature: features.openai_integration,
			openaiIntegration: {
				connected: features.openai_integration?.enabled,
			},
			livechatIntegration: {
				connected: features.livechat_integration?.enabled,
				isLicenseActive: features.livechat_integration?.enabled,
			},
			chatbotFeature: features.chatbot_integration,
			chatbotIntegration: {
				connected: features.chatbot_integration?.enabled,
				isExpired: !features.chatbot_integration?.enabled,
				storyId: features.chatbot_integration?.properties.clientId,
				stories: [
					{
						id: features.chatbot_integration?.properties.clientId,
						name: features.chatbot_integration?.properties.botName,
						description: features.chatbot_integration?.properties.botDescription,
					},
				],
			},
			whatsapp: features.whatsapp,
			messenger: features.messenger,
		}
	}, [organizationEntities, features, organizationId])

	if (!props) {
		return {}
	}

	return props
}
