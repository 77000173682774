import createSvgIcon from './createSvgIcon'

const Edit = createSvgIcon(
	<path d="M12.9177248,2 C13.2973981,2 13.6631827,2.13328471 13.9490506,2.37007983 L14.0664801,2.47781402 L17.5167513,5.92812671 C17.8259138,6.23259089 17.9999866,6.6483629 17.9999866,7.08227523 C17.9999866,7.46194851 17.8667144,7.82773312 17.62992,8.11360105 L17.522186,8.23103052 L15.8437511,9.91213257 C15.8052998,9.9724075 15.7597517,10.0294618 15.7071068,10.0821068 C15.6544618,10.1347517 15.5974075,10.1802998 15.5371326,10.2187511 L8.22257038,17.5306418 C7.9570255,17.7948755 7.60905674,17.9571896 7.24173839,17.9926446 L7.08331546,18 L3.61986133,18 C2.77493996,18 2.08110154,17.353105 2.00661617,16.5275788 L1.99998661,16.3801387 L1.99998661,12.9201675 C1.99870009,12.5455603 2.12782294,12.1839588 2.36359877,11.89493 L2.47110604,11.7756775 L9.77929991,4.46593503 C9.81815823,4.40452781 9.864356,4.34643044 9.91789322,4.29289322 C9.97143044,4.239356 10.0295278,4.19315823 10.090935,4.15429991 L11.7635763,2.48324869 C12.0680404,2.17408616 12.4838124,2 12.9177248,2 Z M10.6429866,6.433 L4,13.075 L4,16 L6.924,16 L13.5669866,9.357 L10.6429866,6.433 Z M12.918,4.158 L12.0579866,5.018 L14.9819866,7.942 L15.842,7.082 L12.918,4.158 Z" />,
	{
		size: 20,
		viewBox: '0 0 20 20',
	},
)

export default Edit
