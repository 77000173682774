import createSvgIcon from './createSvgIcon'

const Envelope = createSvgIcon(
	<path d="M17,3 C17.5522847,3 18,3.44771525 18,4 L18,15 C18,15.5522847 17.5522847,16 17,16 L3,16 C2.44771525,16 2,15.5522847 2,15 L2,4 C2,3.44771525 2.44771525,3 3,3 L17,3 Z M16,6.414 L11.4142136,11 C10.633165,11.7810486 9.36683502,11.7810486 8.58578644,11 L4,6.415 L4,14 L16,14 L16,6.414 Z M14.584,5 L5.415,5 L10,9.58578644 L14.584,5 Z" />,
	{
		size: 20,
		viewBox: '0 0 20 20',
	},
)

export default Envelope
