import createSvgIcon from './createSvgIcon'

const Star = createSvgIcon(
	<path d="M19.0516001,7.5605585 C18.8574372,6.96539621 18.3267841,6.54357927 17.7031626,6.4886835 L13.2500376,6.10352725 L11.5078501,1.95196475 L11.5078501,1.95196475 C11.2628525,1.37480654 10.696574,1 10.0695689,1 C9.44256374,1 8.87628519,1.37480654 8.6312876,1.95196475 L6.88675635,6.10352725 L2.43363135,6.4886835 C1.80648832,6.54061155 1.27170108,6.96369139 1.07685323,7.56205517 C0.882005369,8.16041895 1.06512826,8.81727506 1.54144385,9.22852725 L4.92581885,12.1816522 L3.91019385,16.5754022 C3.76779378,17.1870786 4.00546044,17.8245681 4.51353564,18.1937365 C5.02161085,18.5629048 5.70134023,18.5919969 6.2391001,18.2675897 L10.0672251,15.9425897 L13.8953501,18.2675897 C14.4330773,18.5914794 15.11243,18.5621181 15.6202076,18.1930422 C16.1279851,17.8239663 16.3655882,17.1868423 16.2234751,16.5754022 L15.2078501,12.1816522 L18.5922251,9.22852725 C19.0672728,8.81577808 19.2483418,8.15832506 19.0516001,7.5605585 L19.0516001,7.5605585 Z M13.8367564,10.890246 C13.4001358,11.2701194 13.2092487,11.8602161 13.3406626,12.4238397 L14.2305064,16.2769647 L10.8758189,14.2394647 C10.3791738,13.9370367 9.75527636,13.9370367 9.25863135,14.2394647 L5.90394385,16.2769647 L6.7937876,12.4238397 C6.92520148,11.8602161 6.73431437,11.2701194 6.29769385,10.890246 L3.3219126,8.29415225 L7.2391001,7.95508975 C7.81730217,7.90558136 8.31992846,7.53847444 8.54300635,7.002746 L10.0672251,3.37071475 L11.5914439,7.002746 C11.8145217,7.53847444 12.317148,7.90558136 12.8953501,7.95508975 L16.8125376,8.29415225 L13.8367564,10.890246 Z" />,
	{
		size: 20,
		viewBox: '0 0 20 20',
	},
)

export default Star
