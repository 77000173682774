import createSvgIcon from './createSvgIcon'

const AlignRight = createSvgIcon(
	<path d="M29,9 C30.1045695,9 31,9.8954305 31,11 L31,29 C31,30.1045695 30.1045695,31 29,31 L11,31 C9.8954305,31 9,30.1045695 9,29 L9,11 C9,9.8954305 9.8954305,9 11,9 L29,9 Z M27,21 L23,21 C22.4871642,21 22.0644928,21.3860402 22.0067277,21.8833789 L22,22 L22,27 C22,27.5128358 22.3860402,27.9355072 22.8833789,27.9932723 L23,28 L27,28 C27.5128358,28 27.9355072,27.6139598 27.9932723,27.1166211 L28,27 L28,22 C28,21.4871642 27.6139598,21.0644928 27.1166211,21.0067277 L27,21 Z" />,
	{
		size: 40,
		viewBox: '0 0 40 40',
	},
)

export default AlignRight
