import { TypedUseSelectorHook, useDispatch, useSelector, useStore } from 'react-redux'
import { createStore } from 'redux'

import { ToastKind } from 'components/Toast'
import { Feature } from 'types/feature'
import { Header } from 'types/header'
import { LanguageCode } from 'types/localization'
import { OpenGraphProduct } from 'types/opengraph'
import { Theme } from 'types/theme'

export type Modals =
	| 'SuccessStepModal'
	| 'UnsavedChangesModal'
	| 'ConfirmEnableModal'
	| 'ConfirmDisableModal'
	| 'YouTubeVideoModal'
	| 'EditTemplateModal'

export type GuidelineTooltips = 'OpenAiArchives'

export type StoreState = {
	header?: Header | null
	faq?: Partial<Feature<'faq'>> | null
	theme?: Theme | null
	fetchedProducts?: OpenGraphProduct[] | null
	currentModal?: Modals | null
	currentGuidelineTooltip?: GuidelineTooltips | null
	toast?: { kind: ToastKind; timeout?: number; message: string } | null
	connectionStatus?: 'online' | 'offline'
	visitorCounter?: (Partial<Feature<'visitor_counter'>> & { isVisibleInMinimized?: boolean }) | null
	googleReviews?: Partial<Feature<'google_reviews'>> | null
	productRecommendations?: Partial<Feature<'product_recommendations'>> | null
	forms?: Partial<Feature<'forms'>> | null
	productCards?: Partial<Feature<'product_cards'>> | null
	selectedVisibilityTarget?: 'mobile' | 'desktop'
	language?: LanguageCode
	whatsapp?: Partial<Feature<'whatsapp'>> | null
	openai?: Partial<Feature<'openai_integration'>> | null
	messenger?: Partial<Feature<'messenger'>> | null
	links?: Partial<Feature<'links'>> | null
	isChatOnlyModeEnabled?: boolean
}

type StoreAction =
	| { type: 'header/set'; payload: StoreState['header'] }
	| { type: 'faq/set'; payload: StoreState['faq'] }
	| { type: 'theme/set'; payload: StoreState['theme'] }
	| { type: 'fetchedProducts/set'; payload: StoreState['fetchedProducts'] }
	| { type: 'currentModal/set'; payload: StoreState['currentModal'] }
	| { type: 'currentGuidelineTooltip/set'; payload: StoreState['currentGuidelineTooltip'] }
	| { type: 'toast/set'; payload: StoreState['toast'] }
	| { type: 'connectionStatus/set'; payload: StoreState['connectionStatus'] }
	| { type: 'visitorCounter/set'; payload: StoreState['visitorCounter'] }
	| { type: 'productRecommendations/set'; payload: StoreState['productRecommendations'] }
	| { type: 'googleReviews/set'; payload: StoreState['googleReviews'] }
	| { type: 'forms/set'; payload: StoreState['forms'] }
	| { type: 'selectedVisibilityTarget/set'; payload: StoreState['selectedVisibilityTarget'] }
	| { type: 'productCards/set'; payload: StoreState['productCards'] }
	| { type: 'language/set'; payload: StoreState['language'] }
	| { type: 'whatsapp/set'; payload: StoreState['whatsapp'] }
	| { type: 'openai/set'; payload: StoreState['openai'] }
	| { type: 'messenger/set'; payload: StoreState['messenger'] }
	| { type: 'links/set'; payload: StoreState['links'] }
	| { type: 'isChatOnlyModeEnabled/set'; payload: StoreState['isChatOnlyModeEnabled'] }
	| { type: 'state/reset'; payload: never }

function reducer(state: StoreState = {}, action: StoreAction): StoreState {
	switch (action.type) {
		case 'header/set':
			return { ...state, header: action.payload }

		case 'faq/set':
			return { ...state, faq: action.payload }

		case 'theme/set':
			return { ...state, theme: action.payload }

		case 'fetchedProducts/set':
			return { ...state, fetchedProducts: action.payload }

		case 'currentModal/set':
			return { ...state, currentModal: action.payload }

		case 'currentGuidelineTooltip/set':
			return { ...state, currentGuidelineTooltip: action.payload }

		case 'toast/set':
			return { ...state, toast: action.payload }

		case 'connectionStatus/set':
			return { ...state, connectionStatus: action.payload }

		case 'visitorCounter/set':
			return {
				...state,
				visitorCounter: {
					...state.visitorCounter,
					...action.payload,
				},
			}

		case 'productRecommendations/set':
			return {
				...state,
				productRecommendations: {
					...state.productRecommendations,
					...action.payload,
				},
			}

		case 'googleReviews/set':
			return { ...state, googleReviews: action.payload }

		case 'forms/set': {
			return { ...state, forms: { ...action.payload } }
		}

		case 'selectedVisibilityTarget/set':
			return {
				...state,
				selectedVisibilityTarget: action.payload,
			}

		case 'productCards/set':
			return { ...state, productCards: action.payload }

		case 'language/set':
			return { ...state, language: action.payload }

		case 'whatsapp/set':
			return {
				...state,
				whatsapp: {
					...state.whatsapp,
					...action.payload,
				},
			}

		case 'openai/set':
			return { ...state, openai: action.payload }

		case 'messenger/set':
			return {
				...state,
				messenger: {
					...state.messenger,
					...action.payload,
				},
			}

		case 'links/set':
			return {
				...state,
				links: {
					...state.links,
					...action.payload,
				},
			}

		case 'isChatOnlyModeEnabled/set':
			return {
				...state,
				isChatOnlyModeEnabled: action.payload,
			}

		case 'state/reset':
			return {}

		default:
			return state
	}
}

export const store = createStore(reducer)

export type Store = typeof store

export const useAppStore: () => Store = useStore

export const useAppDispatch: () => typeof store.dispatch = useDispatch

export const useAppSelector: TypedUseSelectorHook<StoreState> = useSelector
