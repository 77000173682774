import createSvgIcon from './createSvgIcon'

const Info = createSvgIcon(
	<path d="M12,2 C17.5228475,2 22,6.4771525 22,12 C22,17.5228475 17.5228475,22 12,22 C6.4771525,22 2,17.5228475 2,12 C2,6.4771525 6.4771525,2 12,2 Z M12,4 C7.581722,4 4,7.581722 4,12 C4,16.418278 7.581722,20 12,20 C16.418278,20 20,16.418278 20,12 C20,7.581722 16.418278,4 12,4 Z M12,11 C12.5522847,11 13,11.4477153 13,12 L13,16.032 L13.0872588,16.0582986 C13.438482,16.1840995 13.6988376,16.5008107 13.7432723,16.8833789 L13.75,17 C13.75,17.5522847 13.3022847,18 12.75,18 L12,18 C11.4477153,18 11,17.5522847 11,17 L11,12.967 L10.9127412,12.9417014 C10.561518,12.8159005 10.3011624,12.4991893 10.2567277,12.1166211 L10.25,12 C10.25,11.4477153 10.6977153,11 11.25,11 L12,11 Z M11.5,7 C12.3284271,7 13,7.67157288 13,8.5 C13,9.32842712 12.3284271,10 11.5,10 C10.6715729,10 10,9.32842712 10,8.5 C10,7.67157288 10.6715729,7 11.5,7 Z" />,
	{
		size: 24,
		viewBox: '0 0 24 24',
	},
)

export default Info
