import styled from '@emotion/styled'

const Badge = styled.span`
	${({ theme }) => theme.typography.smallcaps};
	color: ${({ theme }) => theme.colors.light.alternativeTextColor};
	display: inline-flex;
	gap: ${({ theme }) => theme.spaces.space2};
	align-items: center;
	border-radius: ${({ theme }) => theme.borderRadiuses.lg};
	padding: ${({ theme }) => theme.spaces.space2};
	white-space: nowrap;
`

export default Badge
