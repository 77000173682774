import styled from '@emotion/styled'

import { WIDGET_PREVIEW_ID } from 'lib/constants'

export const WidgetPlane = styled.div<{ pushed?: boolean }>`
	height: 100%;
	border-radius: ${({ theme }) => theme.borderRadiuses.lg};
	background-color: ${({ theme }) => theme.colors.light.surfaceVariant};

	#${WIDGET_PREVIEW_ID} {
		will-change: transform;
		transform: scale(${({ pushed }) => (pushed ? '0.9' : '1.0')});
		transition: transform 275ms ${({ theme }) => theme.transitions.easings.css.swift};
	}
`
