import createSvgIcon from './createSvgIcon'

const HomescreenView = createSvgIcon(
	<>
		<defs>
			<linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="linearGradient-1">
				<stop stopColor="#999999" offset="0%"></stop>
				<stop stopColor="#F3F3F3" offset="100%"></stop>
			</linearGradient>
			<linearGradient x1="77.7573621%" y1="5.89556579%" x2="50%" y2="100%" id="linearGradient-2">
				<stop stopColor="#F9F9F9" offset="0%"></stop>
				<stop stopColor="#D6D6D6" offset="100%"></stop>
			</linearGradient>
			<linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="linearGradient-3">
				<stop stopColor="#FFFFFF" offset="0%"></stop>
				<stop stopColor="#C6C6C6" offset="100%"></stop>
			</linearGradient>
			<linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="linearGradient-4">
				<stop stopColor="#FFFFFF" offset="0%"></stop>
				<stop stopColor="#EDEDED" offset="100%"></stop>
			</linearGradient>
			<linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="linearGradient-5">
				<stop stopColor="#FFFFFF" offset="0%"></stop>
				<stop stopColor="#EDEDED" offset="100%"></stop>
			</linearGradient>
		</defs>
		<g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
			<g>
				<rect fill="url(#linearGradient-1)" x="0" y="0" width="180" height="120"></rect>
				<path
					d="M56,16 L124,16 C132.836556,16 140,23.163444 140,32 L140,120 L140,120 L40,120 L40,32 C40,23.163444 47.163444,16 56,16 Z"
					fill="url(#linearGradient-2)"
				></path>
				<path
					d="M40,120 L40,32 C40,23.163444 47.163444,16 56,16 L124,16 C132.836556,16 140,23.163444 140,32 L140,120"
					stroke="url(#linearGradient-3)"
				></path>
				<rect fill="url(#linearGradient-4)" x="47.5" y="73" width="85" height="32" rx="8"></rect>
				<path
					d="M55.5,110 L124.5,110 C128.918278,110 132.5,113.581722 132.5,118 L132.5,120 L132.5,120 L47.5,120 L47.5,118 C47.5,113.581722 51.081722,110 55.5,110 Z"
					id="Rectangle-Copy-44"
					fill="url(#linearGradient-5)"
				></path>
				<circle fill="#AAAAAA" cx="57.5" cy="34.5" r="8.5"></circle>
				<rect fill="#CFCBCB" x="49" y="49" width="61" height="7" rx="3.5"></rect>
				<rect fill="#CFCBCB" x="49" y="60" width="28" height="7" rx="3.5"></rect>
			</g>
		</g>
	</>,
	{
		viewBox: '0 0 180 120',
	},
)

export default HomescreenView
