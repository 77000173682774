import useSWR from 'swr'
import type { SWRConfiguration } from 'swr'

import useTemplateId from './useTemplateId'
import { config } from 'lib/config'
import { store } from 'lib/store'
import type { Theme } from 'types/theme'

export const useTheme = (options?: SWRConfiguration) => {
	const { withTemplateId } = useTemplateId()

	const { data, mutate, isLoading, error } = useSWR<Theme>(withTemplateId(`${config.apiURL}/v1.0/theme`), options)

	if (error) {
		store.dispatch({
			type: 'toast/set',
			payload: { kind: 'error', timeout: 6000, message: 'Something went wrong' },
		})
	}

	return {
		theme: data,
		mutateTheme: mutate,
		isThemeLoading: isLoading,
	}
}
