import * as React from 'react'

import SvgIcon, { Props as SvgIconProps } from './SvgIcon'

const createSvgIcon = (element: React.ReactElement<SVGElement>, defaultProps: SvgIconProps): typeof SvgIcon => {
	// eslint-disable-next-line react/display-name
	return React.memo((props) => (
		<SvgIcon {...defaultProps} {...props}>
			{element}
		</SvgIcon>
	))
}

export default createSvgIcon
