import createSvgIcon from './createSvgIcon'

const Browser = createSvgIcon(
	<path d="M17.3333333,3 L2.66666667,3 C1.74619208,3 1,3.68130582 1,4.52173913 L1,15.4782609 C1,16.3186942 1.74619208,17 2.66666667,17 L17.3333333,17 C18.2538079,17 19,16.3186942 19,15.4782609 L19,4.52173913 C19,3.68130582 18.2538079,3 17.3333333,3 L17.3333333,3 Z M17.0148148,5 L17.0148148,7 L3.01481481,7 L3.01481481,5 L17.0148148,5 Z M3.01481481,15 L3.01481481,9 L17.0148148,9 L17.0148148,15 L3.01481481,15 Z" />,
	{
		size: 20,
		viewBox: '0 0 20 20',
	},
)

export default Browser
