import createSvgIcon from './createSvgIcon'

const NavArchiveFilled = createSvgIcon(
	<g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
		<path
			d="M6,9 C6,7.8954305 6.8954305,7 8,7 L24,7 C25.1045695,7 26,7.8954305 26,9 L26,9 C26,10.1045695 25.1045695,11 24,11 L8,11 C6.8954305,11 6,10.1045695 6,9 Z"
			fill="#FFFFFF"
			fillRule="nonzero"
		/>
		<path
			d="M23,13 C23.513,13 23.936,13.463 23.993,14.06 L24,14.2 L24,21.4 C24,23.317 22.751,24.884 21.176,24.994 L21,25 L11,25 C9.402,25 8.096,23.501 8.005,21.612 L8,21.4 L8,14.2 C8,13.537 8.448,13 9,13 L23,13 L23,13 Z M18,15 L14,15 L13.883,15.007 C13.3799491,15.0668364 13.0011144,15.4934029 13.0011144,16 C13.0011144,16.5065971 13.3799491,16.9331636 13.883,16.993 L14,17 L18,17 L18.117,16.993 C18.6200509,16.9331636 18.9988856,16.5065971 18.9988856,16 C18.9988856,15.4934029 18.6200509,15.0668364 18.117,15.007 L18,15 Z"
			fill="#FFFFFF"
			fillRule="nonzero"
		/>
	</g>,
	{
		size: 32,
		viewBox: '0 0 32 32',
	},
)

export default NavArchiveFilled
