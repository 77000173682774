import createSvgIcon from './createSvgIcon'

const Messenger = createSvgIcon(
	<path d="M14.8545268,9.84150291 L12.3545268,12.3415029 C12.1786188,12.5180228 11.9396689,12.6172422 11.6904643,12.6172422 C11.4412598,12.6172422 11.2023098,12.5180228 11.0264018,12.3415029 L9.19124559,10.5063467 L7.35452684,12.3415029 C6.98777525,12.7082545 6.39315345,12.7082545 6.02640186,12.3415029 C5.65965028,11.9747513 5.65965027,11.3801295 6.02640184,11.0133779 L8.52640184,8.51337791 C8.70230985,8.33685805 8.94125983,8.23763862 9.19046434,8.23763862 C9.43966885,8.23763862 9.67861884,8.33685805 9.85452684,8.51337791 L11.6912456,10.3500967 L13.5279643,8.51259666 C13.8947159,8.14584509 14.4893377,8.1458451 14.8560893,8.51259668 C15.2228409,8.87934827 15.2228409,9.47397006 14.8560893,9.84072166 L14.8545268,9.84150291 Z M18.8787458,10.4282217 C18.8793706,13.3672973 17.3505453,16.0949211 14.8431467,17.6282569 C12.3357481,19.1615926 9.21112071,19.2796692 6.59515184,17.9399404 L4.06155809,18.7875967 C3.49895287,18.9772056 2.87749557,18.8315861 2.45768835,18.4117789 C2.03788114,17.9919717 1.89226165,17.3705144 2.08187059,16.8079092 L2.92640184,14.2743154 C1.33822951,11.1634093 1.83103322,7.39555364 4.16585761,4.79774239 C6.500682,2.19993113 10.1947782,1.30928831 13.4569176,2.55767734 C16.7190569,3.80606637 18.8747587,6.93537005 18.8787458,10.4282217 L18.8787458,10.4282217 Z M17.0037459,10.4282217 C17.0030206,7.66926021 15.2767451,5.20536751 12.6838834,4.2625331 C10.0910216,3.31969868 7.18533035,4.09928344 5.41263025,6.21337974 C3.63993016,8.32747604 3.3788163,11.3245769 4.75921434,13.7133779 C4.89409652,13.9460619 4.92254936,14.2254688 4.83733934,14.4805654 L4.06155809,16.8079092 L6.38890184,16.0321279 C6.4846406,16.0002403 6.58486752,15.9838875 6.68577684,15.9836904 C6.85042987,15.9839826 7.01210201,16.0276341 7.15452684,16.1102529 C9.18511266,17.2852197 11.688473,17.2868457 13.7205834,16.1145177 C15.7526938,14.9421897 17.004425,12.774245 17.0037459,10.4282217 Z" />,
	{
		size: 20,
		viewBox: '0 0 20 20',
	},
)

export default Messenger
