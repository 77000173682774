import createSvgIcon from './createSvgIcon'

const Chats = createSvgIcon(
	<path d="M17.5204386,13.1309857 C18.4022424,11.2522861 18.3434149,9.06719214 17.3618044,7.23865969 C16.3801938,5.41012724 14.5914986,4.15367771 12.5384073,3.85051694 C11.5637086,1.70281721 9.51147779,0.243337693 7.16283905,0.0275884621 C4.81420031,-0.188160769 2.53041933,0.873006647 1.18075346,2.80718701 C-0.168912414,4.74136738 -0.376858695,7.25104668 0.63606355,9.38098569 L0.0704385504,11.3622357 C-0.0704646858,11.8540766 0.0668750479,12.3836372 0.429055078,12.7450052 C0.791235109,13.1063731 1.32110268,13.2425238 1.81262605,13.1005169 L3.79387605,12.5348919 C4.37247615,12.8047055 4.98724492,12.9889256 5.61887605,13.0817669 C6.34980135,14.6710063 7.68463037,15.9029132 9.32729112,16.5042444 C10.9699519,17.1055756 12.78465,17.0266205 14.3688761,16.2848919 L16.3501261,16.8505169 C16.8411129,16.9906953 17.3695052,16.8537201 17.7305578,16.4926675 C18.0916105,16.1316148 18.2285857,15.6032225 18.0884073,15.1122357 L17.5204386,13.1309857 Z M3.8852823,10.5942669 C3.79809177,10.5941991 3.71131803,10.6062949 3.6274698,10.6302044 L2.10481355,11.0653607 L2.5399698,9.54270444 C2.60782202,9.30591557 2.57975792,9.0519355 2.4618448,8.83567319 C1.34751424,6.78930276 1.90547111,4.23509135 3.77161964,2.83979419 C5.63776818,1.44449703 8.24549573,1.63176369 9.89312502,3.27939297 C11.5407543,4.92702226 11.728021,7.53474981 10.3327238,9.40089835 C8.93742664,11.2670469 6.38321523,11.8250037 4.3368448,10.7106732 C4.19857238,10.6344424 4.04317542,10.5946522 3.8852823,10.5950482 L3.8852823,10.5942669 Z M15.6165323,13.2927044 L16.0516886,14.8153607 L14.5290323,14.3802044 C14.2922434,14.3123522 14.0382634,14.3404163 13.8220011,14.4583294 C11.7331251,15.5948207 9.1243267,14.988943 7.75012605,13.0481732 C11.1288431,12.4378339 13.4646337,9.32446861 13.1055948,5.90989194 C14.4106624,6.35884832 15.4515593,7.36011634 15.9508091,8.64678371 C16.450059,9.93345108 16.3569197,11.3747453 15.6962198,12.5864544 C15.5777822,12.8025039 15.5491557,13.0564936 15.6165323,13.2934857 L15.6165323,13.2927044 Z" />,
	{
		size: 20,
		viewBox: '0 0 20 20',
	},
)

export default Chats
