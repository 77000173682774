import createSvgIcon from './createSvgIcon'

const KnowledgeBaseLogo = createSvgIcon(
	<path d="M16.75,4.44571429 L16.75,1 L16.6,1 C13.8,1.12857143 11.5,2.98 10.1,4.44571429 L10,4.52285714 L9.925,4.44571429 C8.5,2.98 6.2,1.12857143 3.4,1 L3.25,1 L3.25,4.44571429 L0,4.44571429 L0,10.3857143 C0,11.8257143 0.05,13.2657143 0.15,14.4485714 C0.35,16.8142857 2.175,18.6657143 4.475,18.82 C6.25,18.9228571 8.125,19 10,19 C11.875,19 13.75,18.9228571 15.525,18.7942857 C17.825,18.64 19.65,16.7885714 19.85,14.4228571 C19.95,13.24 20,11.8 20,10.36 L20,4.44571429 L16.75,4.44571429 Z M16.75,10.3857143 C16.75,11.7485714 16.7,13.0857143 16.6,14.1657143 C16.525,14.86 15.975,15.4257143 15.3,15.4514286 C11.775,15.7085714 8.225,15.7085714 4.7,15.4514286 C4.025,15.4257143 3.45,14.86 3.4,14.1657143 C3.3,13.0857143 3.25,11.7228571 3.25,10.3857143 L3.25,4.44571429 C5.6,4.54857143 7.95,7.14571429 8.65,8.14857143 L10,10.0771429 L11.35,8.14857143 C12.05,7.12 14.4,4.54857143 16.75,4.44571429 L16.75,10.3857143 Z" />,
	{
		size: 20,
		viewBox: '0 0 20 20',
		color: '#9146FF',
	},
)

export default KnowledgeBaseLogo
