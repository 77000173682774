import createSvgIcon from './createSvgIcon'

const Image = createSvgIcon(
	<path d="M10.6875,7.6875 C10.6875,6.99714406 11.2471441,6.4375 11.9375,6.4375 C12.6278559,6.4375 13.1875,6.99714406 13.1875,7.6875 C13.1875,8.37785594 12.6278559,8.9375 11.9375,8.9375 C11.2471441,8.9375 10.6875,8.37785594 10.6875,7.6875 Z M17.875,4.5625 L17.875,15.8125 C17.875,16.6754449 17.1754449,17.375 16.3125,17.375 L2.5625,17.375 C1.69955508,17.375 1,16.6754449 1,15.8125 L1,4.5625 C1,3.69955508 1.69955508,3 2.5625,3 L16.3125,3 C17.1754449,3 17.875,3.69955508 17.875,4.5625 Z M2.875,4.875 L2.875,11.103125 L5.5203125,8.4578125 C6.13046852,7.84784072 7.11953148,7.84784072 7.7296875,8.4578125 L10.9460937,11.671875 L12.2882813,10.3304687 C12.8984373,9.72049697 13.8875002,9.72049697 14.4976563,10.3304687 L16,11.8351563 L16,4.875 L2.875,4.875 Z M2.875,15.5 L12.1203125,15.5 L6.625,10.0046875 L2.875,13.7546875 L2.875,15.5 Z M16,15.5 L16,14.484375 L13.3929687,11.8773438 L12.271875,13 L14.771875,15.5 L16,15.5 Z" />,
	{
		size: 20,
		viewBox: '0 0 20 20',
	},
)

export default Image
