import React from 'react'
import { useAsyncFn } from 'react-use'

type RenderProps = ReturnType<typeof useAsyncFn>['0'] & {
	callAsyncFn: ReturnType<typeof useAsyncFn>['1']
}

type Props = {
	asyncFn: Parameters<typeof useAsyncFn>['0']
	children: (props: RenderProps) => React.ReactNode
}

function UseAsyncFn({ asyncFn, children }: Props) {
	const [state, callAsyncFn] = useAsyncFn(asyncFn)

	return <>{children({ ...state, callAsyncFn })}</>
}

export default UseAsyncFn
