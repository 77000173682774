import { AnimatePresence } from 'framer-motion'
import React from 'react'

import Toast from './Toast'
import { useAppDispatch, useAppSelector } from 'lib/store'

export default function ToastManager() {
	const dispatch = useAppDispatch()
	const toast = useAppSelector((state) => state.toast)

	React.useEffect(() => {
		if (toast?.timeout) {
			const timeout = setTimeout(() => dispatch({ type: 'toast/set', payload: null }), toast?.timeout)
			return () => clearTimeout(timeout)
		}
	}, [toast?.kind, toast?.timeout, dispatch])

	React.useEffect(() => {
		const setOffline = () => dispatch({ type: 'connectionStatus/set', payload: 'offline' })
		const setOnline = () => dispatch({ type: 'connectionStatus/set', payload: 'online' })

		window.addEventListener('offline', setOffline)
		window.addEventListener('online', setOnline)

		return () => {
			window.removeEventListener('offline', setOffline)
			window.removeEventListener('online', setOnline)
		}
	}, [dispatch])

	return <AnimatePresence>{toast && <Toast {...toast} />}</AnimatePresence>
}
