import { useTheme } from '@emotion/react'
import React from 'react'

import {
	NavArchiveFilledIcon,
	NavArchiveIcon,
	NavArticleFilledIcon,
	NavArticleIcon,
	NavCodeIcon,
	NavPaletteFilledIcon,
	NavPaletteIcon,
	StackFilledIcon,
	StackStrokeIcon,
} from 'components/icons'
import { useIntegration } from 'hooks/useIntegration'
import { useUser } from 'hooks/useUser'

export const routes = [
	{
		route: '/chat-archives/openai',
		title: 'OpenAI Assistant chats',
		icon: {
			active: <NavArchiveFilledIcon />,
			default: <NavArchiveIcon />,
		},
	},
	{
		route: '/configurator/widget-content',
		title: 'Widget content',
		icon: {
			active: <NavArticleFilledIcon />,
			default: <NavArticleIcon />,
		},
		children: [
			{
				pathName: 'contact',
				title: 'Contact card',
			},
			{
				pathName: 'whatsapp',
				title: 'WhatsApp',
			},
			{
				pathName: 'messenger',
				title: 'Messenger',
			},
			{
				pathName: 'faq',
				title: 'FAQ',
			},
			{
				pathName: 'product',
				title: 'Product carousel',
			},
			{
				pathName: 'visitor-counter',
				title: 'Visitor counter',
			},
			{
				pathName: 'product-recommendations',
				title: 'Product recommendations',
			},
			{
				pathName: 'helpdesk',
				title: 'HelpDesk',
			},
			{
				pathName: 'google-reviews',
				title: 'Google reviews',
			},
			{
				pathName: 'livechat',
				title: 'LiveChat',
			},
			{
				pathName: 'chatbot',
				title: 'ChatBot',
			},
			{
				pathName: 'apps',
				title: 'Apps',
			},
			{
				pathName: 'openai-assistants',
				title: 'OpenAI Assistants',
			},
			{
				pathName: 'custom-links',
				title: 'Custom links',
			},
		],
	},
	{
		route: '/configurator/customize-look',
		title: 'Customize look',
		icon: {
			active: <NavPaletteFilledIcon />,
			default: <NavPaletteIcon />,
		},
		children: [
			{
				pathName: 'advanced',
				title: 'Widget position and visibility',
			},
		],
	},
	{
		route: '/configurator/code-snippet',
		title: 'Add to website',
		icon: {
			active: <NavCodeIcon />,
			default: <NavCodeIcon />,
		},
		notificationDot: true,
	},
	{
		route: '/advanced-settings',
		title: 'Advanced settings',
		icon: {
			active: <StackFilledIcon />,
			default: <StackStrokeIcon />,
		},
		children: [
			{
				pathName: 'templates',
				title: 'Templates',
			},
			{
				pathName: 'rules',
				title: 'Rules',
			},
		],
		omitTemplateIdInNavigation: true,
	},
] as const

export type Routes = typeof routes

export type ArchivesRoutesConfig = Routes['0']
export type WidgetContentRoutesConfig = Routes['1']
export type CustomizeLookRoutesConfig = Routes['2']
export type AdvancedSettingsRoutesConfig = Routes['4']

export type WidgetContentRoutes =
	`${WidgetContentRoutesConfig['route']}/${WidgetContentRoutesConfig['children'][number]['pathName']}${
		| ''
		| `/${string}`}`
export type CustomizeLookRoutes = `${CustomizeLookRoutesConfig['route']}${'' | `/${string}`}`
export type ArchivesRoutes = `${ArchivesRoutesConfig['route']}${'' | `/${string}`}`
export type AdvancedSettingsRoutes =
	`${AdvancedSettingsRoutesConfig['route']}/${AdvancedSettingsRoutesConfig['children'][number]['pathName']}${
		| ''
		| `/${string}`}`

export type WidgetContentRouteTitles =
	| WidgetContentRoutesConfig['children'][number]['title']
	| CustomizeLookRoutesConfig['children'][number]['title']
export type NestedRoutes = WidgetContentRoutesConfig['children'][number] | CustomizeLookRoutesConfig['children'][number]

export function useAllowedRoutes() {
	const openAIIntegration = useIntegration('openai')
	const theme = useTheme()
	const user = useUser()

	const allowedRoutes = React.useMemo(
		() =>
			routes.filter((routeConfig) => {
				if (routeConfig.route.startsWith('/chat-archives/') && !openAIIntegration.integration?.connected) {
					return false
				}

				if (routeConfig.route.startsWith('/advanced-settings') && (!user.isTextMember || theme.isMobile)) {
					return false
				}

				return true
			}),
		[openAIIntegration, user, theme.isMobile],
	)

	return allowedRoutes
}
