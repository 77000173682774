import createSvgIcon from './createSvgIcon'

const NavPaletteFilled = createSvgIcon(
	<>
		<defs>
			<path
				d="M16,6 C14.6868,6 13.3864,6.2587 12.1731,6.7612 C10.9599,7.2638 9.8575,8.0003 8.9289,8.9289 C8.0003,9.8575 7.2637,10.9599 6.7612,12.1732 C6.2586,13.3864 6,14.6868 6,16 C6,17.3132 6.2586,18.6136 6.7612,19.8268 C7.2637,21.0401 8.0003,22.1425 8.9289,23.0711 C9.8575,23.9997 10.9599,24.7362 12.1731,25.2388 C13.374,25.7362 14.6601,25.9947 15.9596,25.9999 C16.4214,26.0319 16.8826,25.9237 17.2825,25.6891 C17.697,25.4458 18.0249,25.0788 18.2201,24.6395 C18.4154,24.2003 18.4681,23.7109 18.3708,23.2402 C18.2736,22.7695 18.0314,22.341 17.6781,22.0151 C17.6192,21.9607 17.554,21.9137 17.4839,21.8749 C17.2886,21.7669 17.1351,21.5968 17.0477,21.3915 C16.9603,21.1862 16.944,20.9576 17.0015,20.7421 C17.059,20.5265 17.1869,20.3364 17.3649,20.2019 C17.5429,20.0674 17.7608,19.9963 17.9839,19.9999 L18,20 L20.5,20 C21.9231,20 23.3083,19.4985 24.3463,18.5758 C25.3882,17.6497 26,16.3666 26,15 C26,12.5725 24.9141,10.272 23.0283,8.5957 C21.1463,6.9229 18.6166,6 16,6 Z M16,10 C15.1715,10 14.5,10.6716 14.5,11.5 C14.5,12.3284 15.1715,13 16,13 C16.8284,13 17.5,12.3284 17.5,11.5 C17.5,10.6716 16.8284,10 16,10 Z M11.5,13 C10.6715,13 10,13.6716 10,14.5 C10,15.3284 10.6715,16 11.5,16 C12.3284,16 13,15.3284 13,14.5 C13,13.6716 12.3284,13 11.5,13 Z M20.5,13 C19.6715,13 19,13.6716 19,14.5 C19,15.3284 19.6715,16 20.5,16 C21.3284,16 22,15.3284 22,14.5 C22,13.6716 21.3284,13 20.5,13 Z"
				id="path-1"
			></path>
		</defs>
		<g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
			<mask fill="#FFFFFF">
				<use xlinkHref="#path-1" />
			</mask>
			<use fill="#FFFFFF" xlinkHref="#path-1" />
		</g>
	</>,
	{
		size: 32,
		viewBox: '0 0 32 32',
	},
)

export default NavPaletteFilled
