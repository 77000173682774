import { useTheme } from '@emotion/react'
import styled from '@emotion/styled'
import { m } from 'framer-motion'
import React from 'react'

import UserContent from './UserContent'
import { logout } from 'lib/auth'
import { useAppStore } from 'lib/store'

const Popover = styled(m.div)`
	position: absolute;
	bottom: 0;
	left: 50px;
	margin-top: ${({ theme }) => theme.spaces.space2};
	min-width: 239px;
	max-width: 300px;
	padding: ${({ theme }) => theme.spaces.space6};
	border-radius: ${({ theme }) => theme.borderRadiuses.lg};
	background-color: ${({ theme }) => theme.colors.light.surface};
	box-shadow: ${({ theme }) => theme.boxShadows.md};
	display: flex;
	flex-direction: column;
	gap: ${({ theme }) => theme.spaces.space3};
	z-index: ${({ theme }) => theme.zIndexes.above};
`

type Props = {
	hideUserPopover: () => void
}

function UserPopover({ hideUserPopover }: Props) {
	const theme = useTheme()
	const store = useAppStore()

	const handleKeyUp = (event: React.KeyboardEvent) => {
		if (event.key === 'Escape') {
			hideUserPopover()
		}
	}

	const onLogout = () => {
		try {
			logout()
		} catch (error) {
			store.dispatch({
				type: 'toast/set',
				payload: { kind: 'error', timeout: 4000, message: 'Something went wrong' },
			})
			throw error
		}
	}

	return (
		<Popover
			role="menu"
			onMouseDown={(event) => event.preventDefault()}
			initial={{ opacity: 0 }}
			animate={{ opacity: 1 }}
			exit={{ opacity: 0 }}
			transition={{ duration: 0.15, ease: theme.transitions.easings.motion.speedySwift }}
		>
			<UserContent onLogout={onLogout} onKeyUp={handleKeyUp} onHelpCenterOpen={() => hideUserPopover()} />
		</Popover>
	)
}

export default UserPopover
