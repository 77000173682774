import React from 'react'
import ReactDOM from 'react-dom'

import { noop } from '@livechat/data-utils'

type ContextValue = {
	portalRoot: HTMLDivElement | null
	setPortalRoot: (portalRoot: HTMLDivElement) => void
}

type Props = {
	children: React.ReactNode
	context: React.Context<ContextValue>
}

export function createPortalContext() {
	return React.createContext<ContextValue>({ portalRoot: null, setPortalRoot: noop })
}

export function PortalProvider({ children, context }: Props) {
	const { Provider } = context
	const [portalRoot, setPortalRoot] = React.useState<HTMLDivElement | null>(null)
	const contextValue = React.useMemo(() => ({ portalRoot, setPortalRoot }), [portalRoot])

	return <Provider value={contextValue}>{children}</Provider>
}

export function PortalOutlet({
	context,
	Component,
}: Pick<Props, 'context'> & { Component?: React.ComponentType<JSX.IntrinsicElements['div']> }) {
	const { setPortalRoot } = React.useContext(context)

	if (Component) {
		return <Component ref={setPortalRoot} />
	}

	return <div ref={setPortalRoot} />
}

export function Portal({ children, context: contex }: Props) {
	const { portalRoot } = React.useContext(contex)

	return portalRoot ? ReactDOM.createPortal(children, portalRoot) : null
}
