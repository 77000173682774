import { BezierDefinition } from 'framer-motion'

import * as designTokens from '@livechat/chat-widget-design-tokens'
import { mapValues } from '@livechat/data-utils'

import { isMobile } from 'lib/helpers'

const easings = {
	...designTokens.transitions.easings,
	speedySwift: 'cubic-bezier(0.07, 0.71, 0.25, 1.02)',
}

designTokens.colorPalette

export const theme = {
	...designTokens,
	colorPalette: {
		...designTokens.colorPalette,
		red: {
			...designTokens.colorPalette.red,
			50: '#F7E9E9',
		},
	},
	isMobile: isMobile(),
	colors: {
		...designTokens.colors,
		light: {
			...designTokens.colors.light,
			primaryBackgroundColor: '#fafafa',
			secondaryBackgroundColor: '#ffffff',
			secondaryButtonBackground: '#e3e3e3',
			accent: '#5000ff',
			primaryTextColor: '#000000',
			secondaryTextColor: '#333333',
			alternativeTextColor: '#5e5e5e',
			surfaceInteractive: '#000000',
			surfaceAlternative: '#f2f2f2',
			borderColor: '#e8e8e8',
			breadcrumb: '#b0b0b0',
			toggleBackground: '#c9c9c9',
			toast: {
				success: {
					background: '#97d991',
					foreground: '#0a3d00',
				},
				warning: {
					background: '#ffa31a',
					foreground: '#352103',
				},
				error: {
					background: '#e22235',
					foreground: '#ffffff',
				},
			},
			badgeBackground: '#DDE1DB',
			warningSurface: '#FAE8CC',
			warningPrimary: '#E05300',
			notification: '#E05200',
			checkbox: {
				inactive: '#EBEBEB',
				active: '#000000',
				tick: '#ffffff',
			},
			desaturetedGreen: '#F0FAEA',
		},
	},
	typography: {
		heading: {
			fontSize: '40px',
			fontWeight: '600',
			fontFamily: 'Inter, sans-serif',
		},
		subHeading: {
			fontSize: '24px',
			fontWeight: '600',
			fontFamily: 'Inter, sans-serif',
		},
		smallHeading: {
			fontSize: '18px',
			fontWeight: '600',
			fontFamily: 'Inter, sans-serif',
		},
		paragraphMedium: {
			fontSize: '16px',
			fontWeight: '600',
			fontFamily: 'Inter, sans-serif',
		},
		paragraph: {
			fontSize: '16px',
			fontWeight: '400',
			fontFamily: 'Inter, sans-serif',
		},
		paragraphHigh: {
			fontSize: '18px',
			fontWeight: '400',
			fontFamily: 'Inter, sans-serif',
		},
		captionMedium: {
			fontSize: '14px',
			fontWeight: '600',
			fontFamily: 'Inter, sans-serif',
		},
		caption: {
			fontSize: '14px',
			fontWeight: '400',
			fontFamily: 'Inter, sans-serif',
		},
		captionSmall: {
			fontSize: '12px',
			fontWeight: '400',
			fontFamily: 'Inter, sans-serif',
		},
		smallcaps: {
			fontSize: '12px',
			fontWeight: '500',
			fontFamily: 'Inter, sans-serif',
		},
		code: {
			fontSize: '11px',
			fontWeight: 700,
			fontFamily: 'Courier New, monospace',
		},
	},
	zIndexes: {
		none: 0,
		base: 1,
		above: 2,
		high: 3,
		popover: 10,
		backdrop: 15,
		modal: 20,
		floating: 30,
	},
	transitions: {
		...designTokens.transitions,
		easings: {
			css: easings,
			motion: {
				...mapValues(
					(value) =>
						value
							.match(/cubic-bezier\((.+)\)/)?.[1]
							.split(',')
							.map(Number),
					easings,
				),
			} as Record<keyof typeof easings, BezierDefinition>,
		},
	},
} as const

export type Theme = typeof theme
