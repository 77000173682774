import createSvgIcon from './createSvgIcon'

const Clock = createSvgIcon(
	<path d="M10.4375,2 C5.77759742,2 2,5.77759742 2,10.4375 C2,15.0974026 5.77759742,18.875 10.4375,18.875 C15.0974026,18.875 18.875,15.0974026 18.875,10.4375 C18.8698333,5.77973921 15.0952608,2.00516668 10.4375,2 L10.4375,2 Z M10.4375,17 C6.81313133,17 3.875,14.0618687 3.875,10.4375 C3.875,6.81313133 6.81313133,3.875 10.4375,3.875 C14.0618687,3.875 17,6.81313133 17,10.4375 C16.9961247,14.0602623 14.0602623,16.9961247 10.4375,17 Z M15.75,10.4375 C15.75,10.955267 15.330267,11.375 14.8125,11.375 L10.4375,11.375 C9.91973305,11.375 9.5,10.955267 9.5,10.4375 L9.5,6.0625 C9.5,5.54473305 9.91973305,5.125 10.4375,5.125 C10.955267,5.125 11.375,5.54473305 11.375,6.0625 L11.375,9.5 L14.8125,9.5 C15.330267,9.5 15.75,9.91973305 15.75,10.4375 Z" />,
	{
		size: 20,
		viewBox: '0 0 20 20',
	},
)

export default Clock
