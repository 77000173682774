import createSvgIcon from './createSvgIcon'

const Sparkle = createSvgIcon(
	<path d="M7.99992919,5 C8.46200244,5 8.94438649,5.30364406 9.10518117,5.73684211 L10.3624053,9.6375 L14.2745368,10.5953947 C14.7121948,10.7512209 15.0032484,11.167016 14.9998584,11.6315789 C15.0032484,12.0961419 14.7121948,12.511937 14.2745368,12.6677632 L10.3624053,14.3625 L9.10518117,18.2631579 C8.94438649,18.6963559 8.46200244,19 7.99992919,19 C7.53785595,19 7.0554719,18.6963559 6.89467722,18.2631579 L5.63745309,14.3625 L1.72064057,12.6677632 C1.2874469,12.5069668 1,12.0936569 1,11.6315789 C1,11.169501 1.2874469,10.756191 1.72064057,10.5953947 L5.63745309,9.6375 L6.89467722,5.73684211 C7.0554719,5.30364406 7.53785595,5 7.99992919,5 Z M17,7 C17.5128358,7 17.9355072,7.38604019 17.9932723,7.88337887 L18,8 C18.5522847,8 19,8.44771525 19,9 C19,9.51283584 18.6139598,9.93550716 18.1166211,9.99327227 L18,10 C18,10.5522847 17.5522847,11 17,11 C16.4477153,11 16,10.5522847 16,10 C15.4477153,10 15,9.55228475 15,9 C15,8.44771525 15.4477153,8 16,8 C16,7.44771525 16.4477153,7 17,7 Z M13,1 C13.5522847,1 14,1.44771525 14,2 L14,3 L15,3 C15.5522847,3 16,3.44771525 16,4 C16,4.55228475 15.5522847,5 15,5 L14,5 L14,6 C14,6.55228475 13.5522847,7 13,7 C12.4477153,7 12,6.55228475 12,6 L12,4.999 L11,5 C10.4477153,5 10,4.55228475 10,4 C10,3.44771525 10.4477153,3 11,3 L12,2.999 L12,2 C12,1.44771525 12.4477153,1 13,1 Z" />,
	{
		size: 20,
		viewBox: '0 0 20 20',
	},
)

export default Sparkle
