import { useRouter } from 'next/router'
import React from 'react'

import { capitalizeFirstLetter } from '@livechat/data-utils'

import { trackAmplitudeEvent } from 'lib/tracking'

export function useTracking() {
	const router = useRouter()

	const segmentName = React.useMemo(() => {
		const routeName = router.route.split('/')[1]

		return routeName ? capitalizeFirstLetter(routeName) : ''
	}, [router.route])

	const track = React.useCallback(
		(name: string, properties?: Record<string, string | string[] | boolean | number>) => {
			trackAmplitudeEvent(segmentName ? `${segmentName}: ${name}` : name, properties)
		},
		[segmentName],
	)

	return track
}
