import createSvgIcon from './createSvgIcon'

const Logout = createSvgIcon(
	<g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
		<g transform="translate(-2.000000, -2.000000)">
			<rect x="0" y="0" width="20" height="20"></rect>
			<polyline
				stroke="#000000"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
				points="14.0329553 7.06506811 17.07125 10 14.0329553 12.9750479"
			></polyline>
			<line
				x1="8"
				y1="10"
				x2="16.75"
				y2="10"
				stroke="#000000"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			></line>
			<path
				d="M8,16.875 L3.625,16.875 C3.27982203,16.875 3,16.595178 3,16.25 L3,3.75 C3,3.40482203 3.27982203,3.125 3.625,3.125 L8,3.125"
				stroke="#000000"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			></path>
		</g>
	</g>,
	{
		size: 16,
		viewBox: '0 0 16 16',
	},
)

export default Logout
