import useSWR from 'swr'
import type { SWRConfiguration } from 'swr'

import useTemplateId from './useTemplateId'
import { config } from 'lib/config'
import { store } from 'lib/store'
import type { Feature, FeatureName } from 'types/feature'

export function useFeature<Name extends FeatureName>(name: Name, options?: SWRConfiguration) {
	const { withTemplateId } = useTemplateId()

	const { data, mutate, isLoading, error } = useSWR<Feature<Name>>(
		withTemplateId(`${config.apiURL}/v1.0/features/${name}`),
		options,
	)

	if (error) {
		store.dispatch({
			type: 'toast/set',
			payload: { kind: 'error', timeout: 6000, message: 'Something went wrong' },
		})
	}

	return {
		feature: data,
		mutateFeature: mutate,
		isFeatureLoading: isLoading,
	}
}
