import createSvgIcon from './createSvgIcon'

const NavArticle = createSvgIcon(
	<path
		d="M23,7 C24.6568542,7 26,8.34314575 26,10 L26,22 C26,23.6568542 24.6568542,25 23,25 L9,25 C7.34314575,25 6,23.6568542 6,22 L6,10 C6,8.34314575 7.34314575,7 9,7 L23,7 Z M23,9 L9,9 C8.44771525,9 8,9.44771525 8,10 L8,22 C8,22.5522847 8.44771525,23 9,23 L23,23 C23.5522847,23 24,22.5522847 24,22 L24,10 C24,9.44771525 23.5522847,9 23,9 Z M21,19 C21.5522847,19 22,19.4477153 22,20 C22,20.5522847 21.5522847,21 21,21 L11,21 C10.4477153,21 10,20.5522847 10,20 C10,19.4477153 10.4477153,19 11,19 L21,19 Z M21,15 C21.5522847,15 22,15.4477153 22,16 C22,16.5522847 21.5522847,17 21,17 L11,17 C10.4477153,17 10,16.5522847 10,16 C10,15.4477153 10.4477153,15 11,15 L21,15 Z M21,11 C21.5522847,11 22,11.4477153 22,12 C22,12.5522847 21.5522847,13 21,13 L11,13 C10.4477153,13 10,12.5522847 10,12 C10,11.4477153 10.4477153,11 11,11 L21,11 Z"
		fill="#FFFFFF"
		fillRule="nonzero"
	/>,
	{
		size: 32,
		viewBox: '0 0 32 32',
	},
)

export default NavArticle
