import createSvgIcon from './createSvgIcon'

const LogoText = createSvgIcon(
	<>
		<defs>
			<clipPath id="owSignetLogoClip">
				<path d="M24.005 0c4.481 0 9.122.163 13.283.49 5.441.49 9.922 4.736 10.402 10.452l.056.987.058 1.252c.058 1.375.116 4.493.174 9.357l-.03 1.577c-.06 2.756-.15 5.226-.258 6.943-.48 5.716-4.961 9.962-10.402 10.452-3.783.297-7.962.459-12.057.486L23.995 42c-4.481 0-9.122-.164-13.283-.49C5.271 41.02.79 36.774.31 31.058l-.056-.987-.04-.838a241.722 241.722 0 0 1-.183-6.19v-4.086l.032-1.564c.06-2.557.145-4.836.247-6.45C.79 5.225 5.271.98 10.712.49 14.495.193 18.674.031 22.77.004L24.005 0ZM24 8.004l-1.465.006-1.416.02c-3.302.058-6.581.213-9.767.463-1.76.163-3.04 1.47-3.2 3.103l-.054.586c-.148 1.745-.243 4.194-.299 6.884v3.868l.035 1.475c.066 2.407.169 4.53.318 5.995.16 1.634 1.44 2.94 3.2 3.103 3.186.25 6.465.405 9.767.463l1.416.02 1.562.006 1.368-.006c3.779-.036 7.542-.196 11.183-.482 1.76-.164 3.04-1.47 3.2-3.103l.054-.586c.18-2.137.284-5.327.33-8.724l-.031-2.03-.035-1.475c-.066-2.406-.169-4.53-.318-5.995-.16-1.633-1.44-2.94-3.2-3.103a162.01 162.01 0 0 0-9.767-.463l-1.416-.02L24 8.005Z" />
			</clipPath>
		</defs>
		<foreignObject x="0" y="0" width="48" height="42" clipPath="url(#owSignetLogoClip)">
			<div className="owLogoSignetGradient" />
		</foreignObject>
	</>,
	{
		size: 48,
		viewBox: '0 0 48 42',
	},
)

export default LogoText
