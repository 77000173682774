import styled from '@emotion/styled'
import { AnimatePresence } from 'framer-motion'
import React from 'react'
import useOnClickOutside from 'use-onclickoutside'

import DefaultAvatar from './DefaultAvatar'
import UserPopover from './UserPopover'
import { useUser } from 'hooks/useUser'
import { formControlReset } from 'styles/reset'

const Wrapper = styled.div`
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 42px;
`

const AvatarButton = styled.button`
	${formControlReset};
	height: 32px;
	width: 32px;
	border-radius: ${({ theme }) => theme.borderRadiuses.round};
	padding: 0;
	cursor: pointer;
	overflow: hidden;
	transition: filter 100ms ease;

	@media (hover: hover) {
		&:hover {
			filter: brightness(0.9);
		}
	}
`

const Image = styled.img`
	height: 100%;
	width: 100%;
	object-fit: cover;
`

type Props = {
	onClick?: () => void
}

function UserPopoverSection({ onClick }: Props) {
	const [showUserPopover, setShowUserPopover] = React.useState(false)
	const wrapperRef = React.useRef<HTMLDivElement | null>(null)
	const { name, email, avatarUrl } = useUser()

	const hideUserPopover = () => setShowUserPopover(false)

	useOnClickOutside(wrapperRef, hideUserPopover)

	const handleKeyUp = (event: React.KeyboardEvent) => {
		if (event.key === 'Escape') {
			hideUserPopover()
		}
	}

	const handleBlur = (event: React.FocusEvent) => {
		const currentTarget = event.currentTarget

		requestAnimationFrame(() => {
			if (!currentTarget.contains(document.activeElement)) {
				hideUserPopover()
			}
		})
	}

	return (
		<Wrapper ref={wrapperRef} onBlur={handleBlur}>
			<AvatarButton
				onClick={() => {
					if (onClick) {
						onClick()
					}
					setShowUserPopover((current) => !current)
				}}
				onKeyUp={handleKeyUp}
				aria-label={`${showUserPopover ? 'hide' : 'show'} user menu`}
				aria-expanded={showUserPopover}
				aria-haspopup
			>
				{avatarUrl ? (
					<Image src={avatarUrl} alt="user avatar" aria-hidden />
				) : (
					<DefaultAvatar name={name} email={email} />
				)}
			</AvatarButton>
			<AnimatePresence>{showUserPopover && <UserPopover hideUserPopover={hideUserPopover} />}</AnimatePresence>
		</Wrapper>
	)
}

export default UserPopoverSection
