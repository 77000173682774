import { useEffect } from 'react'
import useSWR from 'swr'
import type { SWRConfiguration } from 'swr'

import useTemplateId from './useTemplateId'
import { config } from 'lib/config'
import { store } from 'lib/store'
import { App } from 'types/apps'

export function useApps(options?: SWRConfiguration) {
	const { withTemplateId } = useTemplateId()

	const { data, isLoading, error } = useSWR<App[]>(withTemplateId(`${config.apiURL}/v1.0/apps`), options)

	useEffect(() => {
		if (error) {
			store.dispatch({
				type: 'toast/set',
				payload: { kind: 'error', timeout: 6000, message: 'Something went wrong' },
			})
		}
	}, [error])

	return {
		apps: data,
		areAppsLoading: isLoading,
	}
}
