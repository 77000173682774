import * as z from 'zod'
import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { zodResolver } from '@hookform/resolvers/zod'
import { useRouter } from 'next/router'
import React from 'react'
import { useForm } from 'react-hook-form'
import isURL from 'validator/lib/isURL'

import WebsitePreview from './WebsitePreview'
import Button from 'components/Button'
import FieldError from 'components/fields/FieldError'
import Form from 'components/fields/Form'
import { StyledInput } from 'components/fields/Input'
import { ArrowRightIcon, LogoTextIcon } from 'components/icons'
import { WebsiteIcon } from 'components/icons'
import { WEBSITE_SCREENSHOT_WIDTH } from 'hooks/useCaptureScreenshot'
import { ensureProtocol } from 'lib/helpers'
import { useAppSelector } from 'lib/store'
import { skeletonPulse } from 'styles/animations'
import { Alignment } from 'types/theme'

const WebsiteSkeleton = styled(WebsiteIcon)<{
	isScreenshotUrlLoading?: boolean
	screenPosition?: Alignment['screenPosition']
}>`
	position: absolute;

	${({ isScreenshotUrlLoading }) =>
		isScreenshotUrlLoading &&
		css`
			animation: ${skeletonPulse} 750ms ease-in-out alternate infinite;
		`}

	${({ screenPosition }) =>
		screenPosition &&
		css`
			transform-origin: top ${screenPosition};
			${screenPosition}: 0;
			bottom: 0;
		`}
`

const FakeWebBrowser = styled.div<{ isSuccessStepActive: boolean }>`
	height: min(900px, 100%);
	width: min(910px, 100%);
	border-radius: ${({ theme }) => theme.borderRadiuses.lg};
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	justify-content: center;
	position: relative;
`

const Caption = styled.p`
	${({ theme }) => theme.typography.caption}
	display: flex;
	justify-content: flex-end;
	min-width: 140px;
`

const StyledForm = styled(Form)`
	padding: 0;
`

const NavigationContainer = styled.div`
	width: 100%;
	height: 40px;
	background-color: #dee1e6;
	display: flex;
	align-items: center;
	gap: ${({ theme }) => theme.spaces.space4};
	padding-left: ${({ theme }) => theme.spaces.space6};
	border-top-right-radius: inherit;
	border-top-left-radius: inherit;
`

const Tab = styled.div`
	height: 80%;
	width: 150px;
	align-self: flex-end;
	background-color: ${({ theme }) => theme.colors.light.floatSurface};
	border-top-right-radius: ${({ theme }) => theme.borderRadiuses.xl};
	border-top-left-radius: ${({ theme }) => theme.borderRadiuses.xl};
	display: flex;
	align-items: center;
	padding-left: 6px;
	padding-top: ${({ theme }) => theme.spaces.space2};
	gap: ${({ theme }) => theme.spaces.space3};
`

const FakeCTAButton = styled.div<{ size: number }>`
	width: ${({ size }) => `${size}px`};
	height: ${({ size }) => `${size}px`};
	border-radius: ${({ theme }) => theme.borderRadiuses.round};
	background-color: ${({ theme }) => theme.colors.light.inactiveElement};
`

const FakeFavicon = styled(FakeCTAButton)`
	background-color: #e0e0e0;
`

const FakeWebsiteTitle = styled.div`
	width: 40%;
	height: 10px;
	background-color: #e0e0e0;
	border-radius: ${({ theme }) => theme.borderRadiuses.xl};
`

const WebsiteInputOuter = styled.div`
	width: 100%;
	height: 80px;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: ${({ theme }) => theme.colors.light.floatSurface};
`

const WebsiteInputInner = styled.div`
	max-width: 400px;
	display: flex;
	justify-content: flex-end;
	align-items: center;
	gap: ${({ theme }) => theme.spaces.space4};
	overflow: visible;

	@media (max-width: 900px) {
		max-width: 300px;
	}
`

const InputOuter = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	position: relative;
	min-width: 280px;

	@media (max-width: 900px) {
		min-width: 150px;
	}
`

const WebsiteContent = styled.div<{ isSuccessStepActive: boolean; alignment: Alignment | null }>`
	height: 100%;
	width: 100%;
	position: relative;
	overflow: hidden;
	border-end-end-radius: ${({ theme }) => theme.borderRadiuses.xl};
	border-end-start-radius: ${({ theme }) => theme.borderRadiuses.xl};
`

const FadedLogo = styled(LogoTextIcon)`
	height: 14px;
	width: 94px;
	fill: #bababa;
	z-index: ${({ theme }) => theme.zIndexes.base};
	position: absolute;
	top: 12px;
	left: 50%;
	translate: -50%;

	@media (max-width: 1092px) {
		display: none;
	}
`

const FormSchema = z.object({
	url: z.union([z.literal(''), z.string().refine(isURL, 'Invalid URL format')]),
})

type FormValues = z.infer<typeof FormSchema>

type Props = {
	children: React.ReactNode
	alignment: Alignment | null
	defaultPreviewURL: string
	banner?: React.ReactNode
}

const FakeBrowser = ({ children, alignment, defaultPreviewURL, banner }: Props) => {
	const [isWebsitePreviewLoaded, setIsWebsitePreviewLoaded] = React.useState(false)
	const [isWebsitePreviewLoading, setIsWebsitePreviewLoading] = React.useState(false)
	const [previewUrl, setPreviewUrl] = React.useState(defaultPreviewURL)
	const isSuccessStepActive = useAppSelector((state) => state.currentModal === 'SuccessStepModal')
	const selectedVisibilityTarget = useAppSelector((state) => state.selectedVisibilityTarget) ?? 'desktop'
	const screenPosition =
		selectedVisibilityTarget === 'desktop' ? alignment?.screenPosition : alignment?.mobileScreenPosition
	const router = useRouter()
	const isSharePage = router.route.startsWith('/share/')
	const fakeBrowserRef = React.useRef<null | HTMLDivElement>(null)

	const { register, handleSubmit, formState, resetField } = useForm<FormValues>({
		defaultValues: { url: previewUrl },
		mode: 'onSubmit',
		reValidateMode: 'onSubmit',
		resolver: zodResolver(FormSchema),
	})

	const { errors, isValid, isDirty } = formState

	const onSubmit = ({ url }: FormValues) => {
		if (!isValid || !url) {
			return
		}

		const urlWithProtocol = ensureProtocol(url)

		resetField('url', { keepDirty: false, defaultValue: urlWithProtocol })

		setPreviewUrl(urlWithProtocol)
	}

	const getScreenshotOffsetForRightAlignment = () => {
		if (!fakeBrowserRef?.current) {
			return 0
		}

		return -(WEBSITE_SCREENSHOT_WIDTH - fakeBrowserRef.current.clientWidth)
	}

	const previewStatus = () => {
		if (isWebsitePreviewLoading) {
			return 'Generating preview'
		}

		return isDirty ? 'Preview on' : 'Previewing'
	}

	React.useEffect(() => {
		setPreviewUrl(defaultPreviewURL ?? '')
		resetField('url', { keepDirty: false, defaultValue: defaultPreviewURL })
	}, [defaultPreviewURL, resetField])

	return (
		<FakeWebBrowser ref={fakeBrowserRef} isSuccessStepActive={isSuccessStepActive}>
			{!isSuccessStepActive && (
				<>
					<NavigationContainer>
						<FakeCTAButton size={12} />
						<FakeCTAButton size={12} />
						<FakeCTAButton size={12} />
						<Tab>
							<FakeFavicon size={18} />
							<FakeWebsiteTitle />
						</Tab>
						<FadedLogo />
					</NavigationContainer>
					{!isSharePage && (
						<StyledForm id="website preview" onSubmit={handleSubmit(onSubmit)}>
							<WebsiteInputOuter>
								<WebsiteInputInner>
									<Caption>{previewStatus()}</Caption>
									<InputOuter>
										<StyledInput
											aria-label="website url"
											length="lg"
											placeholder="Your website URL"
											compact
											disabled={isWebsitePreviewLoading}
											{...register('url')}
										/>
										{errors.url && <FieldError css={css({ bottom: '-15px' })}>{errors.url.message}</FieldError>}
									</InputOuter>
									<Button
										kind="primary"
										type="submit"
										disabled={!isDirty}
										loading={isWebsitePreviewLoading}
										compact
										aria-label="submit url"
									>
										<ArrowRightIcon />
									</Button>
								</WebsiteInputInner>
							</WebsiteInputOuter>
						</StyledForm>
					)}
				</>
			)}
			<WebsiteContent isSuccessStepActive={isSuccessStepActive} alignment={alignment}>
				{previewUrl && alignment && (
					<WebsitePreview
						screenPosition={screenPosition}
						previewUrl={ensureProtocol(previewUrl)}
						setIsLoaded={setIsWebsitePreviewLoaded}
						setIsLoading={setIsWebsitePreviewLoading}
						getScreenshotOffsetForRightAlignment={getScreenshotOffsetForRightAlignment}
					/>
				)}
				{(!isWebsitePreviewLoaded || isWebsitePreviewLoading) && alignment && (
					<WebsiteSkeleton
						screenPosition={screenPosition}
						data-testid="fallback-website-screenshot"
						isScreenshotUrlLoading={isWebsitePreviewLoading}
					/>
				)}
				{children}
			</WebsiteContent>
			{banner}
		</FakeWebBrowser>
	)
}

export default FakeBrowser
