import createBackoff from '@livechat/backoff'
import { promiseDeferred } from '@livechat/promise-utils'
import { extendURLByQueryParams } from '@livechat/url-utils'

import { authFailover, getToken, removeToken } from 'lib/auth'
import { config } from 'lib/config'

export type FetchError<T = unknown> = Error & {
	data: T
	status: number
}

export function fetchWithServiceUnavailableFailover(url: string, options?: RequestInit) {
	let retriesCount = 0
	const { promise, resolve, reject } = promiseDeferred<Response>()
	const backoff = createBackoff({ min: 500, max: 5000, jitter: 0.1 })

	const handleResponse = (response: Response) => {
		if (response.status === 503 && ++retriesCount < 20) {
			setTimeout(attempt, backoff.duration())
			return
		}

		resolve(response)
	}

	const attempt = () => {
		fetch(url, options).then(handleResponse, reject)
	}

	attempt()

	return promise
}

export function validateFetchError(error: unknown): error is FetchError<{ message: string }> {
	return typeof error === 'object' && error !== null && 'status' in error && 'data' in error
}

export const getTemplateIdParamFromUrl = (): string | null => {
	return new URLSearchParams(window.location.search).get('tid')
}

export const extendUrlWithTemplateId = (url: string): string => {
	const shouldAddTemplateId = !url.includes('tid=') && url.startsWith(config.apiURL)
	if (shouldAddTemplateId) {
		const tid = getTemplateIdParamFromUrl()
		if (tid !== null) {
			return extendURLByQueryParams(url, { tid: tid.toString() })
		}
	}
	return url
}

const endpointsWithoutTemplateId = ['/v1.0/installations']
const shouldAddTemplateId = (url: string): boolean => {
	return !endpointsWithoutTemplateId.some((endpoint) => url.includes(endpoint))
}

export async function fetcher<D>(url: string, options?: RequestInit): Promise<D> {
	const token = getToken()
	const shouldAuthorize =
		url.startsWith(config.apiURL) || url.startsWith(config.accountsURL) || url.startsWith(config.billingURL)
	const publicPage = url.endsWith('/public')
	if (shouldAddTemplateId(url)) {
		url = extendUrlWithTemplateId(url)
	}

	const response = await fetchWithServiceUnavailableFailover(url, {
		...options,
		headers: {
			...options?.headers,
			...(shouldAuthorize && { Authorization: `Bearer ${token}` }),
			...(options?.body && typeof options.body === 'string' && { 'Content-Type': 'application/json' }),
		},
	})
	if (response.status >= 500 && (!options?.method || options.method === 'GET')) {
		window.location.replace(`${config.appURL}/oops`)
		await new Promise((resolve) => window.addEventListener('unload', resolve))
	}

	if (publicPage && response.status === 400) {
		window.location.replace(`${config.appURL}/404`)
		await new Promise((resolve) => window.addEventListener('unload', resolve))
	}

	let data = {}
	if (response.headers.get('content-type')?.includes('application/json')) {
		data = await response.json()
	}

	if (response.ok || response.status === 410) {
		return data as D
	}

	if (response.status === 401 && shouldAuthorize) {
		try {
			await authFailover()
		} catch {
			removeToken()
			window.location.replace(`${config.accountsURL}/logout`)
			await new Promise((resolve) => window.addEventListener('unload', resolve))
		}

		return await fetcher(url, options)
	}

	const error = new Error('Unable to fetch') as FetchError
	error.status = response.status
	error.data = data

	throw error
}
