import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { useRouter } from 'next/router'
import React from 'react'

import ThreadDetails from '../components/integrations/openai/history/ThreadDetails'
import { useTemplates } from '../hooks/useTemplates'
import DefaultLayout from './DefaultLayout'
import { Plane } from './components/SharedStyledComponents'

const ThreadDetailsWrapper = styled(Plane)<{ hasMultipleTemplates: boolean }>`
	background-color: ${({ theme }) => theme.colors.light.surface};
	display: flex;
	flex-direction: column;
	max-width: 1000px;
	width: ${({ theme }) => (theme.isMobile ? '100%' : 'calc(100% - 600px)')};
	height: ${({ theme }) => (theme.isMobile ? 'calc(100% - 90px)' : '100%')};

	align-self: flex-end;
	justify-content: flex-start;
	${({ theme, hasMultipleTemplates }) =>
		!theme.isMobile &&
		hasMultipleTemplates &&
		css`
			margin-bottom: -10px;
			height: calc(100% - 70px);
		`};
`

function ChatArchivesLayout({ children }: { children: React.ReactNode }) {
	const router = useRouter()
	const threadId = router.query.threadId as string
	const { templates } = useTemplates()
	const hasMultipleTemplates = typeof templates !== 'undefined' && templates.length > 1

	const rightColumn = (
		<ThreadDetailsWrapper hasMultipleTemplates={hasMultipleTemplates}>
			<ThreadDetails threadId={threadId} />
		</ThreadDetailsWrapper>
	)

	return <DefaultLayout leftColumn={children} rightColumn={rightColumn} />
}

export default ChatArchivesLayout
