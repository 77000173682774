import createSvgIcon from './createSvgIcon'

const Carousel = createSvgIcon(
	<path d="M14.4375,3 L5.6875,3 C4.82455508,3 4.125,3.69955508 4.125,4.5625 L4.125,14.5625 C4.125,15.4254449 4.82455508,16.125 5.6875,16.125 L14.4375,16.125 C15.3004449,16.125 16,15.4254449 16,14.5625 L16,4.5625 C16,4.14809923 15.8353799,3.75067125 15.5423543,3.45764565 C15.2493288,3.16462006 14.8519008,3 14.4375,3 L14.4375,3 Z M14.125,14.25 L6,14.25 L6,4.875 L14.125,4.875 L14.125,14.25 Z M19.125,3.9375 L19.125,15.1875 C19.125,15.705267 18.705267,16.125 18.1875,16.125 C17.669733,16.125 17.25,15.705267 17.25,15.1875 L17.25,3.9375 C17.25,3.41973305 17.669733,3 18.1875,3 C18.705267,3 19.125,3.41973305 19.125,3.9375 Z M2.875,3.9375 L2.875,15.1875 C2.875,15.705267 2.45526695,16.125 1.9375,16.125 C1.41973305,16.125 1,15.705267 1,15.1875 L1,3.9375 C1,3.41973305 1.41973305,3 1.9375,3 C2.45526695,3 2.875,3.41973305 2.875,3.9375 Z" />,
	{
		size: 20,
		viewBox: '0 0 20 20',
	},
)

export default Carousel
