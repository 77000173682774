import createSvgIcon from './createSvgIcon'

const ColorPicker = createSvgIcon(
	<g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
		<path
			d="M14.86875,13.36875 L9.61875,18.61875 C8.86445404,19.3821551 7.75716551,19.6795821 6.721875,19.396875 L4.528125,20.353125 C4.24824862,20.4758831 3.92187844,20.4165431 3.703125,20.203125 L3.703125,20.203125 C3.54383624,20.0463335 3.49895578,19.8069711 3.590625,19.603125 L4.603125,17.278125 C4.32041787,16.2428345 4.61784489,15.135546 5.38125,14.38125 L10.63125,9.13125"
			stroke="#000000"
			strokeWidth="2.25"
		></path>
		<path
			d="M17.60625,10.10625 L18.065625,10.565625 C18.6445979,11.1532167 18.6445979,12.0967833 18.065625,12.684375 L16.659375,14.090625 C16.518407,14.2337684 16.325903,14.3143709 16.125,14.3143709 C15.924097,14.3143709 15.731593,14.2337684 15.590625,14.090625 L9.909375,8.409375 C9.76623161,8.26840697 9.68562908,8.075903 9.68562908,7.875 C9.68562908,7.674097 9.76623161,7.48159303 9.909375,7.340625 L11.315625,5.934375 C11.9032167,5.35540212 12.8467833,5.35540212 13.434375,5.934375 L13.89375,6.39375 L15.73125,4.55625 C16.74375,3.54375 18.39375,3.4875 19.425,4.471875 C19.9393073,4.95831824 20.2353402,5.63186755 20.2459312,6.33970006 C20.2565222,7.04753258 19.9807734,7.72963722 19.48125,8.23125 L17.60625,10.10625 Z"
			stroke="#000000"
			strokeWidth="2.25"
			fill="#000000"
		></path>
	</g>,
	{
		size: 24,
		viewBox: '0 0 24 24',
	},
)

export default ColorPicker
