import styled from '@emotion/styled'

import createSvgIcon from './createSvgIcon'

const ChevronIcon = createSvgIcon(
	<path d="M3.29289322,5.29289322 C3.68341751,4.90236893 4.31658249,4.90236893 4.70710678,5.29289322 L8,8.585 L11.2928932,5.29289322 C11.6533772,4.93240926 12.2206082,4.90467972 12.6128994,5.20970461 L12.7071068,5.29289322 C13.0976311,5.68341751 13.0976311,6.31658249 12.7071068,6.70710678 L8.70710678,10.7071068 C8.31658249,11.0976311 7.68341751,11.0976311 7.29289322,10.7071068 L3.29289322,6.70710678 C2.90236893,6.31658249 2.90236893,5.68341751 3.29289322,5.29289322 Z" />,
	{
		size: 16,
		viewBox: '0 0 16 16',
	},
)

const Chevron = styled(ChevronIcon)<{ direction?: 'upwards' | 'downwards' }>`
	transition: rotate 200ms ${({ theme }) => theme.transitions.easings.css.swift};
	rotate: ${({ direction }) => (direction === 'upwards' ? '180deg' : 'unset')};
`

export default Chevron
