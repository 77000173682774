import createSvgIcon from './createSvgIcon'

const Globe = createSvgIcon(
	<path d="M9.4375,2 C4.77759742,2 1,5.77759742 1,10.4375 C1,15.0974026 4.77759742,18.875 9.4375,18.875 C14.0974026,18.875 17.875,15.0974026 17.875,10.4375 C17.8698333,5.77973921 14.0952608,2.00516668 9.4375,2 L9.4375,2 Z M7.80234375,13.875 L11.0726563,13.875 C10.7182364,14.9546594 10.1635019,15.9578464 9.4375,16.8320312 C8.71149815,15.9578464 8.15676355,14.9546594 7.80234375,13.875 Z M7.36015625,12 C7.21328232,10.9635106 7.21328232,9.9114894 7.36015625,8.875 L11.5148438,8.875 C11.6617177,9.9114894 11.6617177,10.9635106 11.5148438,12 L7.36015625,12 Z M2.87499999,10.4375 C2.87499999,9.9109129 2.93872262,9.38626063 3.06484375,8.875 L5.4703125,8.875 C5.34322572,9.91279041 5.34322572,10.9622096 5.4703125,12 L3.06484375,12 C2.93872262,11.4887394 2.87499999,10.9640871 2.87499999,10.4375 Z M11.0726563,7 L7.80234375,7 C8.15676355,5.92034061 8.71149815,4.91715358 9.4375,4.04296875 C10.1635019,4.91715358 10.7182364,5.92034061 11.0726563,7 Z M13.4046875,8.875 L15.8101562,8.875 C16.0632798,9.9012895 16.0632798,10.9737105 15.8101562,12 L13.4046875,12 C13.5317743,10.9622096 13.5317743,9.91279041 13.4046875,8.875 L13.4046875,8.875 Z M15.025,7 L13.03125,7 C12.7770428,6.08285485 12.4110145,5.20045128 11.9414063,4.37265625 C13.219225,4.90362014 14.2978944,5.82268917 15.025,7 Z M6.9375,4.37265625 C6.46653884,5.20026383 6.09919574,6.08267443 5.84375,7 L3.85,7 C4.57812381,5.82206357 5.65823823,4.90292773 6.9375,4.37265625 L6.9375,4.37265625 Z M3.85,13.875 L5.84375,13.875 C6.09919574,14.7923256 6.46653884,15.6747362 6.9375,16.5023438 C5.65823823,15.9720723 4.57812381,15.0529364 3.85,13.875 L3.85,13.875 Z M11.9414062,16.5023438 C12.4110145,15.6745487 12.7770428,14.7921452 13.03125,13.875 L15.025,13.875 C14.2978944,15.0523108 13.219225,15.9713799 11.9414062,16.5023438 L11.9414062,16.5023438 Z" />,
	{
		size: 20,
		viewBox: '0 0 20 20',
	},
)

export default Globe
