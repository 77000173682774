import createSvgIcon from './createSvgIcon'

const StackStroke = createSvgIcon(
	<g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
		<path
			d="M7.604,11.197 L14.742,8.088 C14.9799546,7.98600897 15.2490158,7.98444943 15.4881367,8.0836752 C15.7272577,8.18290098 15.9161658,8.37449973 16.012,8.615 L20.936,20.517 C21.1489255,21.0191053 20.9202592,21.5992236 20.422,21.821 L13.285,24.93 C13.0469332,25.032312 12.777617,25.0340409 12.5382562,24.9347938 C12.2988955,24.8355467 12.1098203,24.643753 12.014,24.403 L7.09,12.5 C6.87707452,11.9978947 7.10574077,11.4177764 7.604,11.196 L7.604,11.197 Z"
			stroke="#FFFFFF"
			strokeWidth="2"
		/>
		<path d="M19,8 L20,8 C20.5522847,8 21,8.44771525 21,9 L21,12.5" stroke="#FFFFFF" strokeWidth="2" />
		<path
			d="M24,10 C24.264,10.112 24.52,10.217 24.768,10.315 C25.2763377,10.5307069 25.5136106,11.1176214 25.298,11.626 L23,17"
			stroke="#FFFFFF"
			strokeWidth="2"
		/>
	</g>,
	{
		size: 32,
		viewBox: '0 0 32 32',
	},
)

export default StackStroke
