import createSvgIcon from './createSvgIcon'

const LiveChatLogo = createSvgIcon(
	<path d="M19.7390657,12.433 C19.6380657,13.62 19.0950657,14.726 18.2180657,15.526 C17.3410657,16.327 16.1960657,16.764 15.0120657,16.75 L12.4050657,16.75 L7.43906573,20 L7.43906573,16.75 L12.4050657,13.5 L15.0170657,13.5 C15.7840657,13.528 16.4410657,12.953 16.5210657,12.185 C16.6730657,9.687 16.6610657,7.181 16.4840657,4.685 C16.4220657,3.997 15.8680657,3.458 15.1830657,3.42 C13.4900657,3.315 11.7070657,3.25 9.92206573,3.25 C8.13706573,3.25 6.35406573,3.31 4.66106573,3.43 C3.97506573,3.468 3.42106573,4.007 3.36006573,4.695 C3.18306573,7.191 3.17106573,9.697 3.32206573,12.195 C3.40806573,12.959 4.06406573,13.528 4.82706573,13.5 L7.43906573,13.5 L7.43906573,16.75 L4.83206573,16.75 C2.37906573,16.775 0.322065728,14.891 0.114065728,12.43 C-0.0499342716,9.766 -0.0369342716,7.093 0.152065728,4.43 C0.346065728,2.149 2.16106573,0.349 4.43006573,0.19 C6.19806573,0.062 8.06006573,0 9.92206573,0 C11.7840657,0 13.6460657,0.062 15.4140657,0.19 C17.6900657,0.345 19.5120657,2.151 19.7020657,4.44 C19.8910657,7.1 19.9030657,9.77 19.7390657,12.433 Z" />,
	{
		size: 20,
		viewBox: '0 0 20 20',
		color: '#FF5100',
	},
)

export default LiveChatLogo
