import createSvgIcon from './createSvgIcon'

const Drag = createSvgIcon(
	<path d="M10,5.5 C10,6.32842712 9.32842712,7 8.5,7 C7.67157288,7 7,6.32842712 7,5.5 C7,4.67157288 7.67157288,4 8.5,4 C9.32842712,4 10,4.67157288 10,5.5 Z M15.25,7 C16.0784271,7 16.75,6.32842712 16.75,5.5 C16.75,4.67157288 16.0784271,4 15.25,4 C14.4215729,4 13.75,4.67157288 13.75,5.5 C13.75,6.32842712 14.4215729,7 15.25,7 Z M8.5,10.375 C7.67157288,10.375 7,11.0465729 7,11.875 C7,12.7034271 7.67157288,13.375 8.5,13.375 C9.32842712,13.375 10,12.7034271 10,11.875 C10,11.0465729 9.32842712,10.375 8.5,10.375 Z M15.25,10.375 C14.4215729,10.375 13.75,11.0465729 13.75,11.875 C13.75,12.7034271 14.4215729,13.375 15.25,13.375 C16.0784271,13.375 16.75,12.7034271 16.75,11.875 C16.75,11.0465729 16.0784271,10.375 15.25,10.375 Z M8.5,16.75 C7.67157288,16.75 7,17.4215729 7,18.25 C7,19.0784271 7.67157288,19.75 8.5,19.75 C9.32842712,19.75 10,19.0784271 10,18.25 C10,17.4215729 9.32842712,16.75 8.5,16.75 Z M15.25,16.75 C14.4215729,16.75 13.75,17.4215729 13.75,18.25 C13.75,19.0784271 14.4215729,19.75 15.25,19.75 C16.0784271,19.75 16.75,19.0784271 16.75,18.25 C16.75,17.4215729 16.0784271,16.75 15.25,16.75 Z" />,
	{
		size: 20,
		viewBox: '0 0 20 20',
	},
)

export default Drag
