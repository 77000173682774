import { mutate } from 'swr'

import { isEmpty } from '@livechat/data-utils'

import { Template } from '../types/template'
import { config } from './config'
import { extendUrlWithTemplateId, fetcher, getTemplateIdParamFromUrl } from './fetcher'
import { store } from './store'
import type { AppInstance } from 'types/apps'
import type { Feature, FeatureName } from 'types/feature'
import type { FileKind, UploadedFile } from 'types/file'
import type { Header } from 'types/header'
import type { IntegrationConnectPayload, IntegrationName } from 'types/integrations'
import { LanguageCode } from 'types/localization'
import type { GetOpenGraphDataBody, GetOpenGraphDataResponse } from 'types/opengraph'
import type { Organization } from 'types/organization'
import type { NewTheme, Theme } from 'types/theme'

type Method = 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH'

const callApiEndpoint = <D, R>(method: Method, url: string, data?: D, signal?: AbortSignal): Promise<R> => {
	if (method === 'PATCH' && data && isEmpty(data)) {
		return Promise.resolve(null as R)
	}

	return fetcher(url, {
		method,
		signal,
		...(data && {
			body: data instanceof FormData ? data : JSON.stringify(data),
		}),
	})
}

export const getOpengraphData = (params: GetOpenGraphDataBody) => {
	return callApiEndpoint<GetOpenGraphDataBody, GetOpenGraphDataResponse>(
		'POST',
		`${config.apiURL}/v1.0/product/get_opengraph_data`,
		params,
	)
}

export const updateHeader = (header: Partial<Header>) => {
	return callApiEndpoint<Partial<Header>, Header>('PATCH', `${config.apiURL}/v1.0/header`, header)
}

export const updateTheme = (theme: Partial<NewTheme>) => {
	return callApiEndpoint<Partial<NewTheme>, Theme>('PATCH', `${config.apiURL}/v1.0/theme`, theme)
}

export const updateOrganization = (organization: Partial<Organization>) => {
	return callApiEndpoint<Partial<Organization>, Organization>(
		'PATCH',
		`${config.apiURL}/v1.0/organization`,
		organization,
	)
}

export const updateTemplate = (organizationId: string, template: Partial<Template>) => {
	const templateId = getTemplateIdParamFromUrl() ?? organizationId
	return callApiEndpoint<Partial<Template>, Template>(
		'PATCH',
		`${config.apiURL}/v1.0/templates/${templateId}`,
		template,
	).then(async (updatedTemplate) => {
		if (typeof template.language === 'string') {
			mutate(extendUrlWithTemplateId(`${config.apiURL}/v1.0/templates/${templateId}`), updatedTemplate, {
				revalidate: false,
			})
			await Promise.allSettled([
				mutate(extendUrlWithTemplateId(`${config.apiURL}/v1.0/header`), undefined, { revalidate: true }),
				mutate(extendUrlWithTemplateId(`${config.apiURL}/v1.0/features/faq`), undefined, { revalidate: true }),
				mutate(extendUrlWithTemplateId(`${config.apiURL}/v1.0/features/forms`), undefined, { revalidate: true }),
				mutate(extendUrlWithTemplateId(`${config.apiURL}/v1.0/features/openai_integration`), undefined, {
					revalidate: true,
				}),
			])
		}
		return updatedTemplate
	})
}

export const triggerInitialConfiguration = (url: string) => {
	return callApiEndpoint<{ url: string }, void>('POST', `${config.apiURL}/v1.0/product/configure`, { url })
}

export const enhanceContactCardMessage = (message: string) => {
	return callApiEndpoint<{ message: string }, { result: string }>(
		'POST',
		`${config.apiURL}/v1.0/enhancements/contact_card_message`,
		{ message },
	)
}

export const enhanceOpenAIWelcomeMessage = (welcomeMessage: string) => {
	return callApiEndpoint<{ welcomeMessage: string }, { result: string }>(
		'POST',
		`${config.apiURL}/v1.0/enhancements/openai_welcome_message`,
		{ welcomeMessage },
	)
}

export const enhanceFAQAnswer = (question: string, answer: string) => {
	return callApiEndpoint<{ question: string; answer: string }, { result: string }>(
		'POST',
		`${config.apiURL}/v1.0/enhancements/faq_answer`,
		{ question, answer },
	)
}

export const enhanceProductCardCopy = (title: string, description: string) => {
	return callApiEndpoint<{ title: string; description: string }, { titleResult: string; descriptionResult: string }>(
		'POST',
		`${config.apiURL}/v1.0/enhancements/product_card_copy`,
		{ title, description },
	)
}

export const updateFeature = <
	T extends FeatureName,
	D = T extends 'google_reviews'
		? Pick<Feature<T>, 'enabled'> & { properties: Pick<Feature<T>['properties'], 'placeId'> }
		: Partial<Omit<Feature<T>, 'name'>>,
>(
	feature: T,
	data: D,
) => {
	return callApiEndpoint<D, Feature<T>>('PATCH', `${config.apiURL}/v1.0/features/${feature}`, data)
}

export const connectIntegration = <T extends IntegrationName>(name: T, payload: IntegrationConnectPayload[T]) => {
	return callApiEndpoint<IntegrationConnectPayload[T], never>(
		'POST',
		`${config.apiURL}/v1.0/integrations/${name}/connect`,
		payload,
	)
}

export const disconnectIntegration = (name: IntegrationName) => {
	return callApiEndpoint<never, never>('POST', `${config.apiURL}/v1.0/integrations/${name}/disconnect`).then(
		async (data) => {
			await mutate(extendUrlWithTemplateId(`${config.apiURL}/v1.0/features/forms`), undefined, { revalidate: true })
			store.dispatch({ type: 'state/reset', payload: undefined as never })
			return data
		},
	)
}

export const configureRecommendations = (website: string) => {
	return callApiEndpoint<{ website: string }, never>('POST', `${config.apiURL}/v1.0/recommendations/configure`, {
		website,
	})
}

export const searchPlaces = (query: string, signal?: AbortSignal) => {
	return callApiEndpoint<never, Array<{ description: string; placeId: string }>>(
		'GET',
		`${config.apiURL}/v1.0/places/search?q=${encodeURIComponent(query)}`,
		undefined,
		signal,
	)
}

export const getPlaceIdFromGoogleMapsUrl = (url: string) => {
	return callApiEndpoint<never, { placeId: string }>(
		'GET',
		`${config.apiURL}/v1.0/places/place_id?url=${encodeURIComponent(url)}`,
	)
}

export const uploadFile = (namespace: 'img', kind: FileKind, file: File) => {
	const formData = new FormData()
	formData.append('file', file)

	return callApiEndpoint<FormData, UploadedFile>('POST', `${config.apiURL}/v1.0/file/${namespace}/${kind}`, formData)
}

export const addAppInstance = (id: string, properties?: Record<string, string>) =>
	callApiEndpoint<{ appId: string; properties?: Record<string, string> }, AppInstance>(
		'POST',
		`${config.apiURL}/v1.0/apps/instances`,
		{ appId: id, properties },
	)

export const removeAppInstance = (appId: string) =>
	callApiEndpoint<never, never>('DELETE', `${config.apiURL}/v1.0/apps/instances/${appId}`)

export const sendForm = (data: unknown) => callApiEndpoint('POST', `${config.apiURL}/v1.0/product/send_form`, data)

export const detectWebsiteLanguage = (url: string) =>
	callApiEndpoint<{ url: string }, { language: LanguageCode | null }>(
		'POST',
		`${config.apiURL}/v1.0/product/get_website_language`,
		{ url },
	)

export const createTemplate = (name: string) =>
	callApiEndpoint<Pick<Template, 'name'>, Template>('POST', `${config.apiURL}/v1.0/templates`, { name })

export const deleteTemplate = (id: string) =>
	callApiEndpoint<never, never>('DELETE', `${config.apiURL}/v1.0/templates/${id}`)
