import createSvgIcon from './createSvgIcon'

const AlignLeft = createSvgIcon(
	<path d="M29,9 C30.1045695,9 31,9.8954305 31,11 L31,29 C31,30.1045695 30.1045695,31 29,31 L11,31 C9.8954305,31 9,30.1045695 9,29 L9,11 C9,9.8954305 9.8954305,9 11,9 L29,9 Z M17,21 L13,21 C12.4871642,21 12.0644928,21.3860402 12.0067277,21.8833789 L12,22 L12,27 C12,27.5128358 12.3860402,27.9355072 12.8833789,27.9932723 L13,28 L17,28 C17.5128358,28 17.9355072,27.6139598 17.9932723,27.1166211 L18,27 L18,22 C18,21.4871642 17.6139598,21.0644928 17.1166211,21.0067277 L17,21 Z" />,
	{
		size: 40,
		viewBox: '0 0 40 40',
	},
)

export default AlignLeft
