import React from 'react'

import { ToastKind } from 'components/Toast'
import { useAppStore } from 'lib/store'

const DEFAULT_MESSAGES = {
	success: 'Changes saved successfully',
	error: 'Something went wrong',
	warning: 'Something went wrong',
}

export function useToast() {
	const store = useAppStore()

	const showToast = React.useCallback(
		(kind: ToastKind, message?: string, duration?: number) => {
			store.dispatch({
				type: 'toast/set',
				payload: {
					kind,
					timeout: duration ?? 4000,
					message: message ?? DEFAULT_MESSAGES[kind],
				},
			})
		},
		[store],
	)

	return showToast
}
