import createSvgIcon from './createSvgIcon'

const Code = createSvgIcon(
	<path d="M15.3417431,2.81020658 C15.8237028,2.98546467 16.089,3.49270769 15.9733252,3.97984414 L15.9397934,4.09174306 L9.93979342,20.5917431 C9.75105394,21.1107766 9.17729051,21.3785329 8.65825694,21.1897934 C8.17629719,21.0145353 7.91100002,20.5072923 8.02667479,20.0201559 L8.06020658,19.9082569 L14.0602066,3.40825694 C14.2489461,2.88922336 14.8227095,2.62146709 15.3417431,2.81020658 Z M6.70710678,7.29289322 C7.06759074,7.65337718 7.09532028,8.22060824 6.79029539,8.61289944 L6.70710678,8.70710678 L3.415,12 L6.70710678,15.2928932 C7.06759074,15.6533772 7.09532028,16.2206082 6.79029539,16.6128994 L6.70710678,16.7071068 C6.34662282,17.0675907 5.77939176,17.0953203 5.38710056,16.7902954 L5.29289322,16.7071068 L1.29289322,12.7071068 C0.932409257,12.3466228 0.904679722,11.7793918 1.20970461,11.3871006 L1.29289322,11.2928932 L5.29289322,7.29289322 C5.68341751,6.90236893 6.31658249,6.90236893 6.70710678,7.29289322 Z M18.6128994,7.20970461 L18.7071068,7.29289322 L22.7071068,11.2928932 C23.0675907,11.6533772 23.0953203,12.2206082 22.7902954,12.6128994 L22.7071068,12.7071068 L18.7071068,16.7071068 C18.3165825,17.0976311 17.6834175,17.0976311 17.2928932,16.7071068 C16.9324093,16.3466228 16.9046797,15.7793918 17.2097046,15.3871006 L17.2928932,15.2928932 L20.585,12 L17.2928932,8.70710678 C16.9324093,8.34662282 16.9046797,7.77939176 17.2097046,7.38710056 L17.2928932,7.29289322 C17.6533772,6.93240926 18.2206082,6.90467972 18.6128994,7.20970461 Z" />,
	{
		size: 24,
		viewBox: '0 0 24 24',
	},
)

export default Code
