import { keyframes } from '@emotion/react'

export const rotation = keyframes`
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
`

export const pulse = keyframes`
	0% {
		transform: scale(1);
	}

	50% {
		transform: scale(1.2);
	}

	100% {
		transform: scale(1);
	}
`

export const skeletonPulse = keyframes`
from {
	opacity: 0.2;
}

to {
	opacity: 1;
}
`

export const skeletonShimmer = keyframes`
from {
    left: -150%;
}

to {
		left: 150%;
}
`

export const progress = keyframes`
	from {
		width: 0%;
	}
	to {
		width: 100%;
	}
`
