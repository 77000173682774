import createSvgIcon from './createSvgIcon'

const AddImage = createSvgIcon(
	<path d="M17,14 C17.5522847,14 18,14.4477153 18,15 L17.9999096,17 L20,17 C20.5522847,17 21,17.4477153 21,18 C21,18.5522847 20.5522847,19 20,19 L17.9999096,19 L18,21 C18,21.5522847 17.5522847,22 17,22 C16.4477153,22 16,21.5522847 16,21 L15.9999096,18.999 L14,19 C13.4477153,19 13,18.5522847 13,18 C13,17.4477153 13.4477153,17 14,17 L15.9999096,16.999 L16,15 C16,14.4477153 16.4477153,14 17,14 Z M19.9,5 L4.1,5 C4.04477153,5 3.99990958,5.04477153 3.99990958,5.1 L3.99990958,15.09 L14.8184494,7.60173594 C15.5451985,7.0986019 16.5160367,7.13220451 17.2062594,7.6843827 L20,9.92 L20,5.1 C20,5.04477153 19.9552285,5 19.9,5 Z M21.9965971,12.0836684 L21.98883,12.1499666 L21.9722858,12.234783 L21.9360552,12.3526235 L21.9061036,12.4236 L21.8506115,12.5259853 C21.8086739,12.5937995 21.7586285,12.6563867 21.7018735,12.7123072 L21.5973115,12.8022837 L21.5485047,12.8363127 L21.4820037,12.8764675 L21.3719298,12.9284489 L21.2632568,12.9647978 L21.1707877,12.9853388 L21.0738118,12.9973172 L21,13 L20.9242476,12.9971738 L20.8187776,12.983638 L20.7101755,12.9573572 L20.5764,12.9061036 L20.4699153,12.8480558 L20.4162278,12.8120017 L15.9568693,9.24611709 L4.75590958,17 L9,17 C9.55228475,17 10,17.4477153 10,18 C10,18.5522847 9.55228475,19 9,19 L4.1,19 C3.03866901,19 2.16122938,18.2126675 2.01989003,17.1902114 C2.00344488,17.1150267 1.99772814,17.0384213 2.000649,16.9623809 L1.99991803,5.1 C1.99991803,3.94020203 2.94020203,3 4.1,3 L19.9,3 C21.059798,3 22,3.94020203 22,5.1 L22,12 L21.9965971,12.0836684 Z M7.125,7.125 C7.95342712,7.125 8.625,7.79657288 8.625,8.625 C8.625,9.45342712 7.95342712,10.125 7.125,10.125 C6.29657288,10.125 5.625,9.45342712 5.625,8.625 C5.625,7.79657288 6.29657288,7.125 7.125,7.125 Z" />,
	{
		size: 24,
		viewBox: '0 0 24 24',
	},
)

export default AddImage
