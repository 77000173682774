import { useRouter } from 'next/router'
import React from 'react'

export const useScrollTopOnce = (contentRef: React.RefObject<HTMLElement>) => {
	const { route } = useRouter()

	React.useEffect(() => {
		if (!contentRef?.current) {
			return
		}

		return contentRef.current.scrollTo({ top: 0 })
	}, [route, contentRef])
}
